import { UserApiResponse } from '../../Model/User/UserApiResponse';
import ApiService from '../../services/ApiService';

class UserRepository {
    private apiService: ApiService;

    constructor(private token: string, apiService: ApiService) {
        this.apiService = apiService;
    }

    async fetchUser(): Promise<UserApiResponse> {
        try {
            const userData = await this.apiService.get(`user/`);
            return userData;
        } catch (error) {
            console.error('Error loading user profile', error);
            throw new Error('Error loading user profile');
        }
    }

    /**
   * Create the user data and send it to the backend API.
   * @param token - The author token.
   * @param data - The created data for the new user.
   */
    async createUser(token: string, data: any): Promise<void> {
        try {
            // Create new in the backend API
            await this.apiService.post(`user/create-user-request/`, data);
        } catch (error) {
            console.error('Error creating new user', error);
            throw new Error('Error creating new user');
        }
    }

    /**
   * Updates the user profile data locally and sends the update to the backend API.
   * @param updatedData - The updated data for the user profile.
   */
    async updateUser(userId: string, updatedData: any): Promise<void> {
        try {
            // Update the backend API
            await this.apiService.put(`user/update-user-request/${userId}`, updatedData);
        } catch (error) {
            console.error('Error updating user profile', error);
            throw new Error('Error updating user profile');
        }
    }

     /**
   * Reset user password locally and sends the update to the backend API.
   */
     async reseteUserPassword(userId: string): Promise<void> {
        try {
            // Update the backend API
            await this.apiService.put(`user/reinit-user-account/${userId}`, {});
        } catch (error) {
            console.error('Error updating user password', error);
            throw new Error('Error updating user password');
        }
    }

    /**
    * Delete the user data from backend API.
    * @param userId - The updated data for the user.
    * @returns A Promise that resolves when the user is successfully deleted.
    * @throws An error if the API request fails.
    */
    async deleteUser(userId: string): Promise<void> {
        try {
            // Call the deleteUser method
            await this.apiService.delete(`user/delete-user-request/${userId}`);
        } catch (error) {
            console.error('Error when trying to delete user', error);
            throw new Error('Error when trying to delete user');
        }
    }
}

export default UserRepository;