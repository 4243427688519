
import React, { useState } from 'react';
import strings from '../../assets/strings';
// Icons imports
import appLogo from './../../images/app_logo.png';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface HeaderProps {
    onHeaderClick: (id: string) => void;
}

export const Header: React.FC<HeaderProps> = ({ onHeaderClick }) => {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const menuClasses = `transition-all duration-300 ease-out ${navbarOpen ? 'opacity-100 max-h-screen pb-4 lg:pb-0 xl:pb-0 2xl:pb-0' : 'opacity-0 max-h-0 lg:opacity-100 lg:max-h-full'} lg:opacity-100`;

    return (
        <div className="fixed top-0 w-full z-30 clearNav lg:bg-opacity-90 transition duration-300 ease-in-out backdrop-blur-md bg-white/30">
            <div className="flex flex-col w-full px-4 mx-auto lg:items-center lg:justify-between lg:flex-row md:px-6 lg:px-8">
                <div className="flex flex-row items-center justify-between p-4">
                    <a
                        href="/"
                        className="text-lg font-semibold rounded-lg tracking-widest focus:outline-none focus:shadow-outline"
                    >
                        <img
                            src={appLogo}
                            alt="Logo de l'application"
                            className="h-8 rounded-md"
                        />
                    </a>
                    <button
                        className="cursor-pointer leading-none px-3 py-1 lg:hidden outline-none focus:outline-none text-[#1E6091]"
                        type="button"
                        aria-label="button"
                        onClick={() => setNavbarOpen(!navbarOpen)}
                    >
                        {navbarOpen ? (
                            <CloseRoundedIcon fontSize="large" />
                        ) : (
                            <MenuRoundedIcon fontSize="large" />
                        )}
                    </button>
                </div>
                <div className={`${menuClasses} overflow-hidden`}>

                    <nav className="flex-col flex-grow">
                        <ul className="flex flex-col lg:flex-row flex-grow justify-end flex-wrap items-center text-base font-semibold">
                            <li>
                                <a
                                    onClick={() => {
                                        onHeaderClick('home-doctraitant');
                                        setNavbarOpen(false);
                                    }}
                                    className="text-[#1E6091] px-5 py-3 flex items-center transition duration-150 ease-in-out cursor-pointer"
                                >
                                    {strings.welcome_label}
                                </a>
                            </li>
                            {/* <li>
                                <a
                                    onClick={() => {
                                        onHeaderClick('explore-doctraitant');
                                        setNavbarOpen(false);
                                    }}
                                    className="text-[#1E6091] px-5 py-3 flex items-center transition duration-150 ease-in-out cursor-pointer"
                                >
                                    {strings.about_label}
                                </a>
                            </li> */}
                            <li>
                                <a
                                    onClick={() => {
                                        onHeaderClick('why-doctraitant');
                                        setNavbarOpen(false);
                                    }}
                                    className="text-[#1E6091] px-5 py-3 flex items-center transition duration-150 ease-in-out cursor-pointer"
                                >
                                    {strings.why_doctor_label}
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={() => {
                                        onHeaderClick('feature-doctraitant');
                                        setNavbarOpen(false);
                                    }}
                                    className="text-[#1E6091] px-5 py-3 flex items-center transition duration-150 ease-in-out cursor-pointer"
                                >
                                    {strings.feature_label}
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={() => {
                                        onHeaderClick('how-work-doctraitant');
                                        setNavbarOpen(false);
                                    }}
                                    className="text-[#1E6091] px-5 py-3 flex items-center transition duration-150 ease-in-out cursor-pointer"
                                >
                                    {strings.how_it_work_label}
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={() => {
                                        onHeaderClick('help');
                                        setNavbarOpen(false);
                                    }}
                                    className="text-[#1E6091] px-5 py-3 flex items-center transition duration-150 ease-in-out cursor-pointer"
                                >
                                    {strings.help_label}
                                    <svg className="ml-2 w-6 h-6" viewBox="0 0 24 24" fill="#1E6091" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 9C11.7015 9 11.4344 9.12956 11.2497 9.33882C10.8843 9.75289 10.2523 9.79229 9.83827 9.42683C9.4242 9.06136 9.3848 8.42942 9.75026 8.01535C10.2985 7.3942 11.1038 7 12 7C13.6569 7 15 8.34315 15 10C15 11.3072 14.1647 12.4171 13 12.829V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V12.5C11 11.6284 11.6873 11.112 12.2482 10.9692C12.681 10.859 13 10.4655 13 10C13 9.44772 12.5523 9 12 9ZM12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17H12.01C12.5623 17 13.01 16.5523 13.01 16C13.01 15.4477 12.5623 15 12.01 15H12Z" fill="#1E6091"></path> </g></svg>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://doctraitant.fr/getAccess"
                                    className="bg-[#1E6091] text-white px-4 py-3 flex items-center transition duration-150 ease-in-out rounded-md"
                                >
                                    {strings.get_my_doctor_label}
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div >
    );
};