// AppointementViewModel.ts

import ApiService from '../../services/ApiService';
import { AppointementResponse } from '../../types/AppointementResponse';
import { GeneralAppointementResponse } from '../../types/GeneralAppointementResponse';
import AppointementModel from './AppointementModel';

class AppointementViewModel {
    public appointementModel: AppointementModel;
    private apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
        this.appointementModel = new AppointementModel(apiService);
    }

    async loadAppointement(id: any, token: any): Promise<AppointementResponse> {
        try {
            const response = await this.appointementModel.fetchAppointement(id, token);
            return response;
        } catch (error) {
            console.error('Error loading user profile', error);
            throw new Error('Error loading user profile');
        }
    }

    async assignAppointementsRequests(dataToSend: any): Promise<GeneralAppointementResponse> {
        try {
            const response = await this.appointementModel.AssignAppointementsRequests(dataToSend);
            return response;
        } catch (error) {
            console.error('Error assign request', error);
            throw new Error('Error assign request');
        }
    }

    /**
     * Updates the user profile data both locally and on the backend API.
     * @param updatedData - The updated data for the user profile.
     * @returns A Promise that resolves when the user profile is successfully updated.
     * @throws An error if the API request fails.
     */
    async updateAppointement(appointementId: string, updatedData: any): Promise<GeneralAppointementResponse> {
        try {
            // Call the updateUserProfile method in UserProfileModel
            const response = await this.appointementModel.updateAppointementsRequest(appointementId, updatedData);
            return response;
        } catch (error) {
            console.error('Error updating user profile', error);
            throw new Error('Error updating user profile');
        }
    }

     /**
     * Delete the appointement data both locally and on the backend API.
     * @returns A Promise that resolves when the user profile is successfully deleted.
     * @throws An error if the API request fails.
     */
     async deleteAppointement(appointementId: string): Promise<GeneralAppointementResponse> {
        try {
            // Call the deleteAppointement method
            const response = await this.appointementModel.deleteAppointementsRequest(appointementId);
            return response;
        } catch (error) {
            console.error('Error deleting appointement', error);
            throw new Error('Error deleting appointement');
        }
    }
}

export default AppointementViewModel;