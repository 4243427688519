
import React from 'react';

/*
* Get the right appointement request type
*/
export const getTheRightRequestType = (requestType: string) => {
    switch (requestType) {
        case "Consult":
            return "Consultation";
        case "MT":
            return "Médecine traitante";
        default:
            return "N/A";
    }
}