import React, { useState } from 'react';

type Role = {
    id: string;
    displayName: string;
    SvgIcon: React.ReactElement; // Accepting a React element for the SVG
    onSelected: () => void; // Callback function to be executed when this role is selected
};

// Props type for the RoleSwitcher component
type ThemeSwitcherProps = {
    roles: Role[];
    initialRole: string;
};

const ThemeSwitcher: React.FC<ThemeSwitcherProps> = ({ roles, initialRole }) => {
    const [currentRole, setCurrentRole] = useState(initialRole);

    const handleRoleChange = (selectedRole: Role) => {
        setCurrentRole(selectedRole.id);
        selectedRole.onSelected(); // Invoke the action associated with the selected role
    };

    return (
        <label className='themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1'>
            <input
                type='checkbox'
                className='sr-only'
            />
            {roles.map((role) => (
                <span
                    key={role.id}
                    className={`flex items-center space-x-2 rounded-md py-2 px-3 text-xs font-medium ${currentRole === role.id ? 'text-primary bg-[#bfdbfe]/40' : 'text-body-color'
                        }`}
                    onClick={() => handleRoleChange(role)}
                >
                    {role.SvgIcon}
                    <span className="hidden sm:inline">{role.displayName}</span>
                </span>
            ))}
        </label>
    );
};

export default ThemeSwitcher;
