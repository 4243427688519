import { LandingPage } from "../pages/LandingPage"
import { Signin } from "../pages/Signin"
import { Register } from "../pages/Register"
import { ForgotPassword } from "../pages/ForgotPassword"
import { MyDashboard } from "../pages/MyDashboard"
import { Assignment } from "../pages/Assignment"
import { EasyAccessView } from "../pages/EasyAccessView"
import { NotFound } from "../pages/NotFound"

export const nav = [
     { path:     "/",         name: "Home",        element: <LandingPage />,       isMenu: false,     isPrivate: false  },
     { path:     "/signin",    name: "Signin",       element: <Signin />,      isMenu: false,    isPrivate: false  },
     { path:     "/register",    name: "Register",       element: <Register />,      isMenu: false,    isPrivate: false  },
     { path:     "/forgotPassword",    name: "ForgotPassword",       element: <ForgotPassword />,      isMenu: false,    isPrivate: false  },
     { path:     "/mydashboard",    name: "MyDashboard",       element: <MyDashboard />,      isMenu: false,    isPrivate: true  },
     { path:     "/assignment-requests/:id/:uuidQuery",    name: "Assignment",       element: <Assignment />,      isMenu: false,    isPrivate: true  },
     { path:     "/getAccess",    name: "EasyAccessView",       element: <EasyAccessView />,      isMenu: false,    isPrivate: false  },
     { path:     "/notfound",    name: "NotFound",       element: <NotFound />,      isMenu: false,    isPrivate: true  }
]
