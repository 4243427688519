import { jwtDecode } from 'jwt-decode';

export const decodeJwtToken = (token: any) => {
    try {
        const decoded = jwtDecode(token); 
        const date = decoded.exp ?? 0;
        const expirationDate = new Date(date * 1000);
        const isExpired = expirationDate.getTime() < Date.now();

        return { expired: isExpired, data: decoded };
    } catch (error) {
        console.error("Failed to decode token:", error);
        return { expired: true, error };
    }
};