import React, { useState, useEffect } from 'react';
import { BrowserRouter, useNavigate } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper"
import FloatingLabelInput from "./FloatingLabelInput"
import FloatingLabelTextarea from "./FloatingLabelTextarea"
import strings from '../../assets/strings';
import Header from "../layouts/Header";
import Modal from "../layouts/Modal";
import { decodeJwtToken } from "../../auth/AuthService";

// Icons imports
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AppointmentRepository from '../../Domain/Appointments/AppointmentRepository';
import ApiService from '../../services/ApiService';
import { GeneralAppointementResponse } from "@/src/types/GeneralAppointementResponse";
import { DocRequestData } from "@/src/types/docRequestModel";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import attachedDocumentIcon from './../../images/attached_document_icon.png';
import LayoutHeader from '../Headers/LayoutHeader';

const PatientRequestLayout: React.FC = () => {
    const [supportTitleRequest, setSupportTitleRequest] = useState<string>('');
    const [isActionsDisabled, setIsActionsDisabled] = useState(false);
    const apiUrl = "https://api.doctraitant.fr/api";
    const apiService = new ApiService(apiUrl);
    const appointmentRepository = new AppointmentRepository(localStorage.getItem("userId") ?? "", apiService);

    const navigate = useNavigate();
    const { user, logout } = AuthData();
    const [error, setError] = useState<string | null>(null);
    const [toggle, setToggle] = useState(true);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<DocRequestData[]>([]);
    const [status, setStatus] = useState<string | null>(null);
    const [isDataLoading, setIsDataLoading] = useState(false);

    const [modalAction, setModalAction] = useState<"logout" | "delete" | null>(null);

    // Modal for logout
    const openModalForLogout = () => {
        setModalAction("logout");
        setOpen(true);
    };

    // Modal for request delete
    const openModalForDelete = () => {
        setModalAction("delete");
        setOpen(true);
    };

    const handleModalConfirm = () => {
        console.log("handleModalConfirm ...")
        console.log(modalAction)
        if (modalAction === "logout") {
            console.log("logout ...")
            doLogout();
        } else if (modalAction === "delete") {
            console.log("delete ...")
            deleteUserRequests(localStorage.getItem("userId") ?? "");
        }
        // Close modal and reset action
        setOpen(false);
        setModalAction(null);
    };

    const doLogout = async () => {
        try {
            await logout()
            localStorage.clear()
            navigate("/connectme")
        } catch {
            console.log("logout from account")
        }
    }

    const modalLogoutStateChanger = () => {
        setOpen(true);
    };

    const toggleAccountView = () => {
        setToggle(!toggle);
    };

    const fetchFilteredUserRequests = async (userId: string) => {
        if (!userId) return;
        setIsDataLoading(true);
        setError(null);
        try {
            const response: GeneralAppointementResponse = await appointmentRepository.fetchFilteredUserRequests(
                userId,
                localStorage.getItem("token") ?? ""
            );
            setData(response.results.data);
            if (response.results.data.length > 0) {
                setStatus(response.results.data[0].requestState);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
            setError("Impossible de charger les données");
        } finally {
            setIsDataLoading(false);
        }
    };

    const deleteUserRequests = async (userId: string) => {
        console.log("deleteUserRequests")
        if (!userId) return;
        setIsDataLoading(true);
        setError(null);

        try {
            await appointmentRepository.deleteAppointment(data[0]._id);
            // Après la suppression, on déconnecte
            doLogout();
        } catch (error) {
            console.error("Erreur lors de la suppression :", error);
            setError("Impossible de supprimer la demande");
        } finally {
            setIsDataLoading(false);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token && decodeJwtToken(token).expired) {
            doLogout();
        }

        //This is the methode will render
        fetchFilteredUserRequests(localStorage.getItem("userId") ?? "");
    }, []);

    const handleSupportTitleRequestChange = (value: string) => {
        setSupportTitleRequest(value);
    };

    useEffect(() => {
        document.title = strings.patient_request_title_tab;
    }, []);

    return (
        <>
            <LayoutHeader
                icon={
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-10 h-10 text-[#22a6b3]"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122"
                        />
                    </svg>
                }
                title="Mes demandes"
                subtitle="Suivez l'avancement de vos demandes de médecin traitant"
            />

            <div>
                {isDataLoading && <p className="text-[#1E6091] mt-6">Chargement des données...</p>}
                {error && <p className="text-red-500 mt-6">{error}</p>}

                {!isDataLoading && !error && data.length === 0 && (
                    <p className="text-[#1E6091] mt-6">Aucune demande trouvée.</p>
                )}



                <div className="flex flex-col w-full min-w-md pt-6 pb-6 px-3C">
                    {!isDataLoading && !error && data.length > 0 && data.map((item) => (
                        <div key={item._id} className="flex flex-col w-full">
                            <div className="flex flex-col md:p-0 text-left w-full">
                                <p className="text-lg font-bold text-[#22a6b3]">Demande de medecin traitant</p>
                                <p className="text-sm text-[#1E6091]">Transmise, le {new Date(item.createdDateTime).toLocaleDateString()}</p>
                            </div>

                            <div className="flex flex-col w-full md:p-0 mt-6">
                                <div className="flex flex-row justify-between items-center w-full text-left">
                                    <div className="flex items-center gap-2 text-[#1E6091]">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                        </svg>
                                        <span className="text-base font-medium">{item._id.slice(-6)}</span>
                                    </div>
                                    <div
                                        onClick={openModalForDelete}
                                        className="block sm:hidden cursor-pointer bg-red-500 rounded-lg p-2"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-5 h-5 text-white"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m6.75 12H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                                            />
                                        </svg>
                                    </div>

                                    <button
                                        type="button"
                                        className="py-1 px-2 inline-flex items-center gap-x-2 mr-2 text-sm font-semibold rounded-full border border-transparent text-red-800 disabled:opacity-50 disabled:pointer-events-none hidden sm:block"
                                        onClick={openModalForDelete}
                                    >
                                        <p className="font-regular flex items-center text-sm text-red-500">
                                            Annuler la demande
                                        </p>
                                    </button>
                                </div>
                                <div className="mt-1 ml-1 text-left text-[#1E6091] font-semibold">
                                    <div className="flex items-center gap-2">
                                        <p>{item.firstname} {item.lastname}</p>
                                    </div>
                                </div>
                            </div>


                            <br />
                            <div className="flex items-center justify-center w-full py-4 px-1">

                                <div className="flex flex-col items-center text-center min-w-[80px]">
                                    {
                                        status === "En cours" ? (
                                            <div className="w-8 h-8 ring-2 ring-[#1E6091] rounded-full flex items-center justify-center">
                                                <div className="w-6 h-6 bg-[#1E6091] rounded-full" />
                                            </div>
                                        ) : (
                                            <div className="w-8 h-8 bg-[#1DD1A1] rounded-full flex items-center justify-center">
                                                <svg
                                                    className="w-4 h-4 text-white"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    viewBox="0 0 24 24"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    <path d="M5 13l4 4L19 7" />
                                                </svg>
                                            </div>
                                        )
                                    }

                                    <p className="mt-2 text-xs text-[#1E6091] font-bold">Validation</p>
                                    <p className={`text-xs font-bold ${status == "En cours" ? 'text-[#1E6091]' : 'text-[#1DD1A1]'}`}>{status == "En cours" ? "En cours" : "Terminée"}</p>
                                </div>

                                {/* -- Barre de progression -- */}
                                <div className="flex-1 mb-7 h-1.5 bg-[#ABCEFA] rounded-full relative">
                                    {/* Remplissage de la barre en bleu (par exemple) */}
                                    <div
                                        className={`absolute left-0 top-0 h-1.5 rounded-full ${status == "En cours" ? ' bg-[#1E6091]' : ' bg-[#1DD1A1]'}`}
                                        style={{
                                            width: `${status === "En cours" ? "60%" : "100%"}`
                                        }}
                                    />
                                </div>

                                {/* -- Bloc Étape 2 -- */}
                                <div className="flex flex-col items-center text-center min-w-[80px]">
                                    {/* Cercle vert avec icône de check */}
                                    {
                                        status === "En cours" ? (
                                            <div className="w-8 h-8 bg-blue-300 rounded-full" />
                                        ) : (
                                            <div className="w-8 h-8 bg-[#1DD1A1] rounded-full flex items-center justify-center">
                                                <svg
                                                    className="w-4 h-4 text-white"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    viewBox="0 0 24 24"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    <path d="M5 13l4 4L19 7" />
                                                </svg>
                                            </div>
                                        )
                                    }

                                    {/* Texte Étape */}
                                    <p className="mt-2 text-xs text-[#1E6091] font-bold">Prise en charge</p>
                                    <p className={`text-xs font-bold ${status == "En cours" ? 'text-slate-400' : 'text-[#1DD1A1]'}`}>{status == "En cours" ? "En attente" : "Terminée"}</p>
                                </div>
                            </div>
                            <br />

                            {/* Meeting Block */}
                            {status != "En cours" && item.meeting && (
                                <div className="flex flex-col overflow-hidden justify-center text-left ml-1">
                                    <span className="flex items-center text-base font-bold text-[#22a6b3]">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 mr-2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12" />
                                        </svg>
                                        Rendez-vous
                                    </span>

                                    <span className="inline-flex items-center text-sm font-bold md:text-md underline mt-3 ml-1 text-[#1E6091]">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 mr-2">
                                            <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
                                            <path fillRule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clipRule="evenodd" />
                                        </svg>
                                        {(() => {
                                            const date = new Date(item.meeting.meetingDateTime as string);
                                            const formattedDate = date.toLocaleDateString("fr-FR", {
                                                weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
                                            });
                                            const capitalizedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

                                            return `${capitalizedDate} à ${date.toLocaleTimeString("fr-FR", { hour: '2-digit', minute: '2-digit' })}`;
                                        })()}
                                    </span>

                                    <span className="text-sm font-bold md:text-md my-2 ml-1 text-[#1E6091]">
                                        Docteur {item.meeting.doctorFirstname} {item.meeting.doctorLastname}
                                    </span>

                                    <div className='text-sm flex flex-col md:flex-row gap-4 ml-1 text-[#1E6091]'>
                                        <div className='flex flex-row'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 mr-2">
                                                <path fillRule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
                                            </svg>
                                            {item.meeting.doctorAddress}<br />
                                            {item.meeting.doctorAddressCity}<br />
                                            {item.meeting.doctorAddressZipcode}
                                        </div>
                                        <div className='flex flex-row text-[#1E6091]'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 mr-2">
                                                <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
                                            </svg>
                                            {item.meeting.doctorPhone}
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            )}


                            {/* Instruction Block */}
                            <div className="flex flex-col overflow-hidden justify-center text-left ml-1">
                                <span className="flex items-center text-base font-bold text-[#22a6b3]">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-5 mr-2"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                                        />
                                    </svg>
                                    Instructions
                                </span>
                                {
                                    status === "En cours" ? (
                                        <span className="text-sm text-[#1E6091] mt-3 ml-1">
                                            Votre demande est en cours de traitement. <br />
                                            Nous y travaillons activement et vous serez notifié(e) dès que possible. <br />
                                            Merci pour votre patience !
                                        </span>
                                    ) : (
                                        <span className="text-sm text-[#1E6091] mt-3 ml-1">
                                            En cas d&apos;empêchement, nous vous prions de contacter
                                            impérativement le secrétariat du médecin dans les plus brefs délais. <br /> Merci de votre compréhension !
                                        </span>
                                    )
                                }
                            </div>
                        </div>
                    ))}
                </div>


            </div>

            {/* Logout Modal */}
            <Modal
                open={open}
                title=""
                onClose={() => setOpen(false)} data-modal-backdrop="static">
                <div className="w-full shadow-lg rounded-xl bg-white p-2 pt-4 pl-4 pr-4">
                    <div className="flex justify-between items-center">
                        <h3 className="flex items-center gap-x-2 font-bold text-[#12CBC4]">
                            {/* <LogoutRoundedIcon fontSize="medium" />
                Déconnexion */}
                            {modalAction === "logout" ? (
                                <>
                                    <LogoutRoundedIcon fontSize="medium" />
                                    Déconnexion
                                </>
                            ) : (
                                <>
                                    <CancelRoundedIcon fontSize="medium" />
                                    Annuler la demande
                                </>
                            )}
                        </h3>
                        <button onClick={() => setOpen(false)} type="button" className="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" data-hs-overlay="#hs-basic-modal">
                            <span className="sr-only">Close</span>
                            <CancelRoundedIcon htmlColor="#a5b1c2" fontSize="medium" />
                        </button>
                    </div>

                    <div className="bg-white grid grid-cols-1 gap-4 lg:col-span-3 ">
                        <div className="p-2">
                            <div className="relative">
                                <div className="mx-auto mt-4 w-48">
                                    <p className="text-sm font-normal text-gray-700">
                                        {modalAction === "logout" ? (
                                            <>
                                                Êtes-vous sûr(e) de vouloir vous déconnecter ?
                                            </>
                                        ) : (
                                            <>
                                                Êtes-vous sûr(e) de vouloir annuler la demande ?
                                            </>
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 mb-4 flex justify-end gap-x-2">
                        <p onClick={() => {
                            setOpen(false)
                            setModalAction(null)
                        }} className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-lg border border-[#ff4757]/50 font-medium bg-white text-[#ff4757]/50 shadow-sm align-middle transition-all text-sm cursor-pointer">
                            <CloseRoundedIcon fontSize="medium" />
                            Annuler
                        </p>
                        <p onClick={handleModalConfirm} className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg bg-[#ff4757] border border-transparent text-white disabled:opacity-50 disabled:pointer-events-none cursor-pointer">
                            <LogoutRoundedIcon fontSize="medium" />
                            Confirmer
                        </p>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default PatientRequestLayout;





