import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Toast from './../layouts/Toast';
import strings from '../../assets/strings';
// Icons Imports
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import passwordIcon from './../../images/data_security.png';
import ApiService from "../../services/ApiService";
import AuthenticationViewModel from "../authentication/AuthenticationViewModel";

export const ForgotPassword = () => {
    const apiUrl = "https://api.doctraitant.fr/api";
    const apiService = new ApiService(apiUrl);
    // ViewModels
    const authenticationViewModel = new AuthenticationViewModel(apiService);

    const navigate = useNavigate();
    const [isActionsDisabled, setIsActionsDisabled] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const [recoveryForm, setRecoveryForm] = useState(0);
    const [emailValue, setEmailValue] = useState<string>('');
    const [pinAuthValue, setPinAuthValue] = useState<string>('');
    const [pinAuthConfirmationValue, setPinAuthConfirmationValue] = useState<string>('');
    const isValidPinAuth = /^\d{6,}$/.test(pinAuthValue);

    const [values, setValues] = useState(Array(6).fill(''));

    // Gère la mise à jour de l'état spécifique en fonction de l'index du champ de saisie
    const handleChange = (index: number, value: string) => {
        const newValues = [...values];
        newValues[index] = value;
        setValues(newValues);
    };

    // Combine les valeurs de tous les champs en une seule chaîne
    const getCombinedValue = () => values.join('');

    const handleEmailChange = (value: string) => {
        setEmailValue(value);
    };

    function handleFormNavigation() {
        // Handle navigation in recovery form
        if (recoveryForm == 0) {
            requestToResetPassword();
        }
        if (recoveryForm == 1) {
            requestToCheckOTP();
        }
        if (recoveryForm == 2) {
            resetPasswordQuery();
        }
    }

    const handlePinAuthChange = (value: string) => {
        setPinAuthValue(value);
    };

    const handlePinAuthConfirmationChange = (value: string) => {
        setPinAuthConfirmationValue(value);
    };

    const requestToResetPassword = async () => {
        setIsActionsDisabled(true);
        try {
            const response = await authenticationViewModel.forgotPasswordQuery({ email: emailValue });
            setIsActionsDisabled(false);
            setRecoveryForm(recoveryForm => recoveryForm + 1);
        } catch (error) {
            setIsActionsDisabled(false);
            setRecoveryForm(recoveryForm => recoveryForm + 1);
        }
    };

    const requestToCheckOTP = async () => {
        setIsActionsDisabled(true);
        try {
            const response = await authenticationViewModel.otpCheckerQuery({ email: emailValue, otp: getCombinedValue() });
            setIsActionsDisabled(false);
            setRecoveryForm(recoveryForm => recoveryForm + 1);
        } catch (error) {
            setIsActionsDisabled(false);
            handleShowToast("error", "Échec de l'opération");
        }
    };

    const resetPasswordQuery = async () => {
        setIsActionsDisabled(true);
        try {
            const response = await authenticationViewModel.passwordResetQuery({ email: emailValue, otp: getCombinedValue(), newPassword: pinAuthValue });
            setIsActionsDisabled(false);
            setRecoveryForm(recoveryForm => recoveryForm + 1);
        } catch (error) {
            setIsActionsDisabled(false);
            handleShowToast("error", "Échec de l'opération");
        }
    };

    // Toast
    const [toastType, setToastType] = useState<string>('');
    const [toastMessage, setToastMessage] = useState<string>('');
    const [showToast, setShowToast] = useState(false);

    const handleShowToast = (type: 'success' | 'warning' | 'error', message: any) => {
        setIsActionsDisabled(false);
        setToastType(type);
        setToastMessage(message);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
            setToastType('');
            setToastMessage('');
        }, 2000);
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible((prevVisible) => !prevVisible);
    };

    function handleNavigationToSignin() {
        // Handle navigation to signin from recover page
        // Then trigger navigation.
        navigate("/signin");
    }

    useEffect(() => {
        document.title = strings.reset_account_title_tab;
    }, []);

    return (
        <div>
            <div className="lg:flex">
                <div className="lg:w-1/2 xl:max-w-screen-sm">
                    <div className="py-12 bg-white flex justify-center lg:justify-start lg:px-12">
                        <div className="cursor-pointer flex items-center">
                            <div className="text-2xl tracking-wide ml-2 font-semibold">
                                <h2 className="text-center text-2xl text-[#12CBC4] font-display font-semibold lg:text-left xl:text-5xl
                xl:text-bold">{strings.forgot_password_account_label}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white flex justify-center lg:justify-start lg:px-12">
                        <div className="cursor-pointer flex items-center">
                            <div className="tracking-wide ml-2 font-semibold">
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="mt-14 px-4 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
                        <div className="mt-24">
                            <form>
                                {(() => {
                                    switch (recoveryForm) {
                                        case 0:
                                            return <div className="mt-8">
                                                <div className="flex justify-between items-center">
                                                    <div className="text-sm font-bold text-gray-700 tracking-wide">
                                                        {strings.username_or_email_label}
                                                    </div>
                                                </div>

                                                <input
                                                    className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                                                    placeholder={strings.username_or_email_placeholder_label}
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={emailValue}
                                                    onChange={(e) => setEmailValue(e.target.value)}
                                                />
                                            </div>;
                                        case 1:
                                            return <div className="space-y-16">
                                                <div className="flex justify-between items-center">
                                                    <div className="text-sm font-bold text-gray-700 tracking-wide">
                                                        {strings.otp_label}
                                                    </div>
                                                </div>
                                                <div className="h-full">
                                                    {values.map((value, index) => (
                                                        <input
                                                            key={index}
                                                            className="w-10 sm:w-16 md:w-16 lg:w-16 xl:w-16 h-12 sm:h-16 md:h-16 lg:h-16 xl:h-16 text-lg text-center border border-gray-200 rounded-xl outline-none bg-white focus:ring-1 ring-[#1E6091] mr-1"
                                                            type="text"
                                                            value={value}
                                                            onChange={(e) => handleChange(index, e.target.value)}
                                                        />
                                                    ))}
                                                </div>
                                            </div>;
                                        case 2:
                                            return <div className="mt-8">
                                                <div className="flex justify-between items-center">
                                                    <div className="text-sm font-bold text-gray-700 tracking-wide">
                                                        {strings.password_reset_label}
                                                    </div>
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500 pr-10"
                                                        placeholder={strings.password_reset_placeholder_label}
                                                        type={passwordVisible ? 'text' : 'password'}
                                                        id="new-password"
                                                        name="new-password"
                                                        value={pinAuthValue}
                                                        onChange={(e) => setPinAuthValue(e.target.value)}
                                                        autoComplete="new-password"
                                                    />
                                                    <div
                                                        className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer text-[#12CBC4]/70"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {passwordVisible ? <VisibilityOffRoundedIcon fontSize="small" /> : <VisibilityRoundedIcon fontSize="small" />}
                                                    </div>
                                                </div>
                                                <br />
                                                <br />
                                                <div className="flex justify-between items-center">
                                                    <div className="text-sm font-bold text-gray-700 tracking-wide">
                                                        {strings.password_confirmation_reset_label}
                                                    </div>
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500 pr-10"
                                                        placeholder={strings.password_reset_placeholder_label}
                                                        type={passwordVisible ? 'text' : 'password'}
                                                        id="new-password-confirmation"
                                                        name="new-password-confirmation"
                                                        value={pinAuthConfirmationValue}
                                                        onChange={(e) => setPinAuthConfirmationValue(e.target.value)}
                                                        autoComplete="new-password"
                                                    />
                                                    <div
                                                        className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer text-[#12CBC4]/70"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {passwordVisible ? <VisibilityOffRoundedIcon fontSize="small" /> : <VisibilityRoundedIcon fontSize="small" />}
                                                    </div>
                                                </div>
                                            </div>;
                                        case 3:
                                            return <div className="mt-2">
                                                <div className="flex justify-center items-center">
                                                    <div className="text-sm font-display font-semibold text-gray-500 text-center">
                                                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-32 h-32"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="#12CBC4"></path> </g></svg>
                                                    </div>
                                                </div>

                                                <div className="text-base font-display font-semibold text-gray-500 text-center mt-2">
                                                    <p className="text-[#12CBC4]">{strings.forgot_password_account_success_label}</p>
                                                </div>
                                                <div className="text-sm font-display font-medium text-gray-500 text-center mt-4">
                                                    <p className="text-[#12CBC4]">{strings.forgot_password_account_success_description_label}</p>
                                                </div>
                                            </div>;
                                        default:
                                            return <div>Erreur</div>;
                                    }
                                })()}
                                <br />
                                <div className="mt-10">
                                    <button
                                        type="button"
                                        className={`flex items-center justify-center flex-1 bg-[#12CBC4]/90 text-gray-100 p-4 w-full rounded-lg tracking-wide
      font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-[#12CBC4]
      shadow-lg ${isActionsDisabled ? 'disabled:opacity-50 disabled:pointer-events-none' : ''}`}
                                        disabled={isActionsDisabled || (recoveryForm === 2 && (!isValidPinAuth || pinAuthValue !== pinAuthConfirmationValue || (pinAuthValue === '' && pinAuthConfirmationValue === '')))}
                                        onClick={() => {
                                            if (recoveryForm <= 2) {
                                                handleFormNavigation();
                                            } else {
                                                handleNavigationToSignin();
                                            }
                                        }}
                                    >
                                        {isActionsDisabled ? (
                                            <div className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-white rounded-full mr-2" role="status" aria-label="loading">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            <span>{recoveryForm <= 2 ? strings.confirm_label : strings.please_connect_label}</span>
                                        )}
                                    </button>

                                </div>
                            </form>
                            {(() => {
                                if (recoveryForm <= 2) {
                                    return <div className="mt-12 text-sm font-display font-semibold text-gray-500 text-center">
                                        <a className={`cursor-pointer text-[#12CBC4] ${isActionsDisabled ? 'pointer-events-none opacity-50' : ''}`} onClick={isActionsDisabled ? undefined : handleNavigationToSignin}>{strings.forgot_password_account_back_to_login_label}</a>
                                    </div>;
                                }
                            })()}
                        </div>
                    </div>
                </div>
                <div className="hidden lg:flex items-center justify-center bg-[#12CBC4]/20 flex-1 h-screen">
                    <div className="max-w-xs transform duration-200 hover:scale-110 cursor-pointer">
                        <img
                            src={passwordIcon}
                            alt="Logo de l'application"
                            className="w-11/12 mx-auto"
                        />
                    </div>
                </div>
            </div>
            {showToast && (
                <Toast type={toastType} message={toastMessage} />
            )}
        </div>
    )
}