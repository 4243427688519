import React, { useState, useEffect } from 'react';
import PriorityTag from './PriorityTag';
import { DocRequestData } from './../../types/docRequestModel';
// Utils imports
import { getFormalDateWithDayAsNumberShortcutMonthAndYear, calculateAge, convertToISO8601Date } from './../../utils/IAHelperForDate';
// Icons imports
import AccessibleRoundedIcon from '@mui/icons-material/AccessibleRounded';
import PregnantWomanRoundedIcon from '@mui/icons-material/PregnantWomanRounded';
import DirectionsWalkRoundedIcon from '@mui/icons-material/DirectionsWalkRounded';
import { data } from 'autoprefixer';

interface RequestItemProps {
    data: DocRequestData;
    onValdiationPress: () => void;
    onShowDetailsPress: () => void;
}

const RequestItem: React.FC<RequestItemProps> = ({ data, onValdiationPress, onShowDetailsPress }) => {

    return (
        <div className="flex flex-col bg-white shadow-sm rounded border-l-4 border-l-teal-400/80 pt-2 pb-2">
            <div className="pl-4 pr-4 pb-1">
                <div className="flex justify-between">
                    <div>
                        <p className="text-start flex items-center text-xs md:text-sm sm:text-sm lg:text-sm gap-x-1 font-medium text-gray-600/90">
                            <span className="text-blue-950/70">{data.lastname}</span>
                        </p>
                    </div>
                    <div>
                        <p className="text-start flex items-center text-xs md:text-base sm:text-base lg:text-base gap-x-1 font-normal text-gray-600/90 cursor-pointer">
                            <PriorityTag useCase={""} criticity={data.priority} />
                        </p>
                    </div>
                </div>
                <div className="flex justify-between">
                    <div>
                        <p className="text-start flex items-center text-xs md:text-sm sm:text-sm font-normal italic text-gray-600/90">
                            <span className="text-blue-950/60">{calculateAge(convertToISO8601Date(data.birthdate))} an(s)</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="border-b border-gray-200/70"></div>
            <div className="pt-2 pl-3 pr-4 pb-1">
                <div className="flex justify-between">
                    <div>
                        <p className="text-start flex items-center text-xs md:text-sm sm:text-sm font-normal text-[#5352ed] bg-[#70a1ff]/15 rounded-md p-1 pb-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                            </svg>
                            <span className="ml-1">{data.zipcode}</span>
                        </p>
                    </div>
                    <div>
                        <p className="text-start flex items-center text-xs md:text-xs sm:text-xs font-normal text-[#5352ed] bg-[#70a1ff]/15 rounded-md p-1 pr-1 pb-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <span className="ml-1">{getFormalDateWithDayAsNumberShortcutMonthAndYear(data.createdDateTime.toLocaleString())}</span>
                        </p>
                    </div>
                </div>
                <div className="flex justify-between mt-2">
                    <div>
                        <p className="text-start flex items-center text-xs md:text-sm sm:text-sm font-normal text-blue-800 -ml-1">
                            {data.isPregnant && (
                                <span className="inline-flex items-center text-base font-medium ml-1">
                                    <PregnantWomanRoundedIcon fontSize="small" />
                                </span>
                            )}
                            {data.isHandicapped && (
                                <span className="inline-flex items-center text-base font-medium ml-1">
                                    <AccessibleRoundedIcon fontSize="small" />
                                </span>
                            )}
                            {data.isMobile && (
                                <span className="inline-flex items-center text-base font-medium ml-1">
                                    <DirectionsWalkRoundedIcon fontSize="small" />
                                </span>
                            )}
                            {data.isALD && (
                                <span className="inline-flex items-center text-base font-semibold ml-1">
                                    ALD
                                </span>
                            )}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <div onClick={onValdiationPress} className="text-start flex items-center text-xs md:text-sm sm:text-sm font-semibold text-green-600 space-x-1 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                            </svg>
                            <span>Accepter</span>
                        </div>
                        <div onClick={onShowDetailsPress} className="text-start flex items-center text-xs md:text-sm sm:text-sm font-semibold text-blue-800 space-x-1 ml-6 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 0 0-1.883 2.542l.857 6a2.25 2.25 0 0 0 2.227 1.932H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-1.883-2.542m-16.5 0V6A2.25 2.25 0 0 1 6 3.75h3.879a1.5 1.5 0 0 1 1.06.44l2.122 2.12a1.5 1.5 0 0 0 1.06.44H18A2.25 2.25 0 0 1 20.25 9v.776" />
                            </svg>
                            <span>Afficher</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestItem;