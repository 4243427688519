import React, { useState, useEffect, useRef } from 'react';
import SimpleCalendar from './../layouts/SimpleCalendar';
import EventItem from './../layouts/EventItem';
import io from 'socket.io-client';
import { DocRequestData } from './../../types/docRequestModel';
import { GeneralAppointementResponse } from './../../types/GeneralAppointementResponse';
import PriorityTag from './PriorityTag';
import BottomDialogView from "./BottomDialogView";
import CustomPatientModalWithScroll, { CustomPatientModalWithScrollRef } from "./CustomPatientModalWithScroll";
import Modal from "./Modal";
import strings from '../../assets/strings';
// Utils imports
import { getTheRightRequestType } from './../../utils/AppointementRequestDecode';
import { getTheClosestAvailableDates, convertToISO8601Date, getFormalDateWithDayAsNumberShortcutMonthAndYear, calculateAge } from './../../utils/IAHelperForDate';// Icons imports
// icons imports
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SnippetFolderRoundedIcon from '@mui/icons-material/SnippetFolderRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded';
import CancelScheduleSendRoundedIcon from '@mui/icons-material/CancelScheduleSendRounded';
import emptySearch from './../../images/empty_search.png';
import { ModalTypeEnum } from '../../types/ModalTypeEnum';
import ErrorView from './ErrorView';
import AcceptAppointementView from './AcceptAppointementView';
import CancelAppointementView from './CancelAppointementView';
import ApiService from '../../services/ApiService';
import AppointmentRepository from '../../Domain/Appointments/AppointmentRepository';
import LayoutHeader from '../Headers/LayoutHeader';

const AppointementsLayout: React.FC = () => {
    const socket = io("https://api.doctraitant.fr");
    const apiUrl = "https://api.doctraitant.fr/api";
    const apiService = new ApiService(apiUrl);
    const appointmentRepository = new AppointmentRepository(localStorage.getItem("userId") ?? "", apiService);

    const [data, setData] = useState<DocRequestData[]>([]);
    const [triggedData, setTriggedData] = useState<DocRequestData | undefined>(undefined);
    const [cancelModal, setCancelModal] = useState<boolean>(false)
    const [isActionsDisabled, setIsActionsDisabled] = useState(false);
    const [filtredDataByDate, setfiltredDataByDate] = useState<DocRequestData[]>([]);
    const [schedulingForm, setSchedulingForm] = useState<number>(0)
    const [isDataLoading, setIsDataLoading] = useState(false);

    // Asking for actions modal
    const [actionModalOpened, setActionModalOpened] = useState(false);
    const [modalType, setModalType] = useState<ModalTypeEnum>(ModalTypeEnum.Nothing);

    const [initModal, setInitModal] = useState(false);

    // Suggested date and time for appointement
    const [datesSuggestions, setDatesSuggestions] = useState<Date[]>([]);

    //FullScren Dialog sheet
    // Manage fullscreen bottom dialog sheet
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    //Patient Management
    const [selectedPatient, setSelectedPatient] = useState<DocRequestData | undefined>(undefined);

    const [meetingToggle, setMettingToggle] = useState(false);

    const [selectedCalendarDateValue, setSelectedCalendarDateValue] = useState<Date>(new Date());

    const [dateTimeValue, setDateTimeValue] = useState<string>('');

    const [emailValue, setEmailValue] = useState<string>(localStorage.getItem("email") ?? "N/A");
    const [phoneValue, setPhoneValue] = useState<string>(localStorage.getItem("phone") ?? "N/A");
    const [adresseValue, setAdresseValue] = useState<string>(localStorage.getItem("address") ?? "N/A");
    const [cityValue, setCityValue] = useState<string>(localStorage.getItem("city") ?? "N/A");
    const [communeValue, setCommuneValue] = useState<string>(localStorage.getItem("township") ?? "N/A");
    const [zipcodeValue, setZipcodeValue] = useState<string>(localStorage.getItem("zipcode") ?? "N/A");

    const [dataLoading, setDataLoading] = useState<boolean>(false);

    const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailValue(event.target.value);
    };
    const handlePhoneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneValue(event.target.value);
    };
    const handleCommuneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCommuneValue(event.target.value);
    };

    const handleZipcodeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setZipcodeValue(event.target.value);
    };

    const handleCityInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCityValue(event.target.value);
    };

    const handleAdresseInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdresseValue(event.target.value);
    };

    const [isPatientModalOpen, setIsPatientModalOpen] = useState(false);

    const handleCalendarDayTrigged = (value: Date) => {
        setSelectedCalendarDateValue(value)
    };

    const handleAppointementScheduling = () => {
        getTheClosestAvailableDates()
            .then(function (response) { return response; })
            .then(function (data) {
                const items = data;
                setDatesSuggestions(items ?? [])
            });
        setModalType(ModalTypeEnum.Accept);
        setActionModalOpened(true);
    };

    const closeSchedulingModal = () => {
        setModalType(ModalTypeEnum.Nothing);
        setActionModalOpened(false);
        setSchedulingForm(0);
    }

    const getNumberOfAppointements = () => {
        return data.length;
    }

    const handleModal = () => {
        // setMyModalOpen(true)
    };

    const handleDateTimeChange = (value: string) => {
        setDateTimeValue(value);
    };
    const handleDateTimeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDateTimeValue(event.target.value);
    };

    const cancelAppointement = async () => {
        setIsActionsDisabled(true)
        try {
            const requestBody = {
                meetingDateTime: null,
                meeting: null,
                requestState: "En cours"
            };

            const response = await fetch(
                `https://api.doctraitant.fr/api/appointment/update-appointment-request/${selectedPatient?._id}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                }
            );
            if (response.ok) {
                console.log('Mise à jour de la demande réussie');
                setCancelModal(false);
                setIsPatientModalOpen(false)
                setIsActionsDisabled(false)
                setActionModalOpened(false);
                setModalType(ModalTypeEnum.Nothing);
            } else {
                console.error('Échec de la mise à jour de la demande');
                setIsActionsDisabled(false)
                setActionModalOpened(false);
                setModalType(ModalTypeEnum.Error);
                setActionModalOpened(true);
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la demande :', error);
            setIsActionsDisabled(false)
            setActionModalOpened(false);
            setModalType(ModalTypeEnum.Error);
            setActionModalOpened(true);
            throw error;
        }
    };

    const meetingToggleBoolean = (item: DocRequestData) => {
        // nothing
    };

    const selectSuggestedDate = (selectedItem: Date) => {
        setDateTimeValue(convertToISO8601Date(selectedItem))
    }

    const schedulePreviousForm = () => {
        setSchedulingForm(prevSchedulingForm => prevSchedulingForm - 1);
    }

    const scheduleAppointement = async () => {
        const incrementForm = () => {
            setSchedulingForm(prevSchedulingForm => prevSchedulingForm + 1);
        };

        if (schedulingForm < 2) {
            incrementForm();
            return;
        }

        if (schedulingForm === 2) {
            setIsDataLoading(true);
            setDataLoading(true);
            try {
                const response = await appointmentRepository.updateAppointment(selectedPatient?._id ?? "", {
                    meetingDateTime: dateTimeValue,
                    requestState: "Acceptée",
                    meeting: {
                        doctorID: localStorage.getItem("userId"),
                        doctorUUID: localStorage.getItem("userUUID"),
                        doctorFirstname: localStorage.getItem("firstname"),
                        doctorLastname: localStorage.getItem("lastname"),
                        doctorAddress: adresseValue,
                        doctorAddressCity: cityValue,
                        doctorAddressZipcode: zipcodeValue,
                        doctorEmail: emailValue,
                        doctorPhone: phoneValue,
                        meetingDateTime: dateTimeValue,
                    },
                })
                console.log('Mise à jour de la demande réussie');
                setSchedulingForm(3);
            } catch (error) {
                setIsDataLoading(false);
                setModalType(ModalTypeEnum.Error);
                setDataLoading(false);
                setActionModalOpened(true);
                console.error('Erreur lors de la mise à jour de la demande :', error);
            }
        }
    };

    const handleTriggedData = (data: DocRequestData) => {
        setTriggedData(data)
    };

    function formaterDateAvecJour(dateString: string) {
        const date = new Date(dateString);
        const joursSemaine = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
        const mois = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];

        const jourSemaine = joursSemaine[date.getDay()];
        const jour = date.getDate();
        const moisAnnee = mois[date.getMonth()];
        const annee = date.getFullYear();
        const heures = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);

        return `${jourSemaine} ${jour} ${moisAnnee} ${annee}`;
    }

    const fetchPlannedAppointements = async (userId: string, data: any) => {
        setIsDataLoading(true)
        try {
            const response: GeneralAppointementResponse = await appointmentRepository.fetchPlannedAppointments(userId, data);
            setData(response.results.data);
            setIsDataLoading(false)
        } catch (error) {
            console.error('Erreur lors de la récupération des données :', error);
            setIsDataLoading(false)
        }
    };

    const handleDayClick = (day: Date) => {
        day.setHours(0, 0, 0, 0);

        const year = day.getFullYear();
        const month = (day.getMonth() + 1).toString().padStart(2, '0');
        const doo = day.getDate().toString().padStart(2, '0');

        const targetDate = `${year}-${month}-${doo}`;

        const filteredData = data.filter((item) => {
            if (item.meetingDateTime) {
                const meetingDate = new Date(item.meetingDateTime);
                const formattedDate = `${meetingDate.getFullYear()}-${(meetingDate.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}-${meetingDate.getDate().toString().padStart(2, "0")}`;
                return formattedDate === targetDate;
            }
            return false;
        });

        const sortedFiltredData = [...filteredData].sort((a, b) => {
            const dateA = new Date(a.meetingDateTime ?? "");
            const dateB = new Date(b.meetingDateTime ?? "");

            return dateA.getTime() - dateB.getTime();
        });

        setfiltredDataByDate(sortedFiltredData);
    };

    useEffect(() => {
        document.title = strings.planning_title_tab;
        fetchPlannedAppointements("", {
            uuid: localStorage.getItem("userUUID"),
            userID: localStorage.getItem("userId"),
        });

        socket.on("appointmentRequestCreated", (newAppointment) => {
            if (newAppointment.meetingDateTime == null) {
                setData((prevAppointments) => (Array.isArray(prevAppointments) ? [...prevAppointments, newAppointment] : [newAppointment]));
            }
        });

        socket.on("appointmentRequestUpdated", (updatedTask) => {
            fetchPlannedAppointements("", {
                uuid: localStorage.getItem("userUUID"),
                userID: localStorage.getItem("userId"),
            });
        });

        return () => {
            socket.disconnect();
        };
    }, [])

    useEffect(() => {
        handleDayClick(selectedCalendarDateValue)
    }, [selectedCalendarDateValue])

    const modalRef = useRef<CustomPatientModalWithScrollRef>(null);
  
    const resetTab = () => {
      if (modalRef.current) {
        modalRef.current.resetActiveTab();
      }
    };

    return (
        <>
            <LayoutHeader
                icon={
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 text-[#22a6b3]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                    </svg>
                }
                title="Mes Rendez-vous"
                subtitle="Gérer votre planning médical"
            />

            {/* <!-- Features --> */}
            <div className="w-full">
                <div>
                    <div className="flex justify-between">
                        <div>
                            <div className="text-start flex items-center text-xs md:text-base sm:text-base lg:text-base gap-x-1 font-medium pt-3 pr-3 pl-3 text-gray-600/90">
                                <div className="inline bg-emerald-400 w-4 h-4 rounded-full border-4 border-emerald-200"></div>
                                <span className="ml-1 text-sky-700/70">Rendez-vous à venir</span>
                            </div>
                        </div>
                        <div>
                            {getNumberOfAppointements() > 0 && (
                                <p onClick={toggleFullScreen} className="text-start flex items-center text-xs md:text-sm sm:text-sm lg:text-sm gap-x-1 font-normal pt-3 pr-3 pl-3 text-sky-700/70 cursor-pointer">
                                    <span>Afficher tout</span>
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div>
                            <p className="text-start flex items-center text-xs md:text-base sm:text-base gap-x-1 font-normal p-3 text-sky-600/70">
                                <span className="ml-1">{formaterDateAvecJour(selectedCalendarDateValue.toDateString())}</span>
                            </p>
                        </div>
                    </div>
                </div>

                {
                    isDataLoading ? (
                        <>
                            <div
                                className="flex hide-scroll-bar"
                            >
                                <div
                                    className="w-full flex pl-2"
                                >
                                    <div className="w-full flex items-center text-center overflow-y-none">
                                        <div className="w-full shadow-sm rounded p-2">
                                            <div className="w-full animate-pulse flex space-x-4">
                                                <div className="flex-1 space-y-4 py-1">
                                                    <div className="h-6 bg-slate-300/80 rounded"></div>
                                                    <div className="space-y-3">
                                                        <div className="h-5 bg-slate-300/80 rounded"></div>
                                                        <div className="grid grid-cols-3 gap-4">
                                                            <div className="h-5 bg-slate-300/80 rounded col-span-2"></div>
                                                            <div className="grid grid-cols-2 gap-4">
                                                                <div className="h-5 bg-slate-300/80 rounded col-span-2"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {Array.isArray(filtredDataByDate) && filtredDataByDate.length > 0 ? (
                                // Calendar events horizontal list 
                                <div
                                    className="flex overflow-x-scroll pb-2 hide-scroll-bar"
                                >
                                    <div
                                        className="flex pl-2"
                                    >
                                        <div className="w-full flex items-center mt-2 text-center overflow-y-auto">
                                            {
                                                filtredDataByDate.map((item, index) => (
                                                    <div
                                                        key={item._id}
                                                        onClick={() => {
                                                            setSelectedPatient(item)
                                                            setAdresseValue(() => item.meeting?.doctorAddress.toString() ?? "N/A")
                                                            setZipcodeValue(() => item.meeting?.doctorAddressZipcode.toString() ?? "N/A")
                                                            setCityValue(() => item.meeting?.doctorAddressCity.toString() ?? "N/A")
                                                            setPhoneValue(() => item.meeting?.doctorPhone.toString() ?? "N/A")
                                                            setEmailValue(() => item.meeting?.doctorEmail.toString() ?? "N/A")
                                                            setIsPatientModalOpen(true)
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <EventItem data={item} itemToogle={meetingToggle} itemToggleBoolean={meetingToggleBoolean} modalTrigger={handleModal} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                // No event in the calendar 
                                <div className="flex justify-center items-center p-2">
                                    <div className="h-20">
                                        <span className="mb-2 inline-flex justify-center items-center">
                                            <img
                                                src={emptySearch}
                                                alt="Logo de l'application"
                                                className="h-8 rounded-md"
                                            />
                                        </span>

                                        <h3 className="text-xs font-regular italic text-gray-400">
                                            Aucun rendez-vous n'est prévu ce jour
                                        </h3>
                                    </div>
                                </div>
                            )}
                        </>
                    )
                }

                <div className="w-full flex justify-center items-center">
                    <div className="border-t border-gray-300/80 w-full mt-3 ml-4 mr-4">
                    </div>
                </div>

                <SimpleCalendar data={data} modalTrigger={handleModal} onDataTrigged={handleTriggedData} onDateTrigged={handleCalendarDayTrigged} />

            </div>

            <>
                {/* Appointement Data Displaying Modal  */}
                <CustomPatientModalWithScroll
                    ref={modalRef}
                    open={isPatientModalOpen}
                    initModal={initModal}
                    title="Filtres"
                    onClose={() => (false)}
                    header={
                        <div className="p-4 flex justify-between items-center">
                            <h3 className="flex items-center gap-x-2 font-bold text-white">
                                <SnippetFolderRoundedIcon fontSize="medium" />
                                Dossier du patient
                            </h3>
                            <button onClick={() => {
                                    setIsPatientModalOpen(false)
                                    setSelectedPatient(undefined);
                                    resetTab();
                                }
                            } type="button" className="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-white disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:focus:outline-none" data-hs-overlay="#hs-basic-modal">
                                <span className="sr-only">Close</span>
                                <CancelRoundedIcon fontSize="medium" />
                            </button>
                        </div>
                    }
                    subHeader={
                        <div className="mx-auto flex justify-center items-center pl-4">
                            <PriorityTag useCase={""} criticity={selectedPatient?.priority ?? "N/A"} />
                        </div>
                    }
                    informations={
                        <div className="space-y-4">
                            <div className="flex justify-start space-x-4">
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Nom et Prénom</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {`${selectedPatient?.firstname ?? "N/A"} ${selectedPatient?.lastname ?? "N/A"}`}
                                    </p>
                                </div>
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Date de naissance</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedPatient && `${getFormalDateWithDayAsNumberShortcutMonthAndYear(selectedPatient.birthdate.toLocaleString()) ?? "N/A"} - ${calculateAge(convertToISO8601Date(selectedPatient.birthdate ?? "N/A"))} an(s)`}
                                    </p>
                                </div>
                            </div>

                            <div className="flex justify-start space-x-4">
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Nature de la demande</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {`${getTheRightRequestType(selectedPatient?.requestType ?? "")}`}
                                    </p>
                                </div>
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Classification</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedPatient?.priority ?? "N/A"}
                                    </p>
                                </div>
                            </div>

                            <div className="flex justify-start space-x-4">
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Informations complémentaires</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedPatient?.requestDescription ?? "N/A"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    contact={
                        <div className="space-y-4">
                            <div className="flex justify-start space-x-4">
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Adresse</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedPatient?.city ?? "N/A"}
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-start space-x-4">
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Commune</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedPatient?.city ?? "N/A"}
                                    </p>
                                </div>
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Code postal</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedPatient?.zipcode ?? "N/A"}
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-start space-x-4">
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Adresse e-mail</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedPatient?.email ?? "N/A"}
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-start space-x-4">
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Téléphone</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedPatient?.phone ?? "N/A"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    history={
                        <div className="p-4 sm:p-6 text-center overflow-y-auto">
                            <span className="mb-4 inline-flex justify-center items-center w-[62px] h-[62px] rounded-full border-4 border-yellow-50 bg-yellow-100 text-yellow-500 dark:bg-yellow-700 dark:border-yellow-600 dark:text-yellow-100">
                                <svg className="flex-shrink-0 w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </svg>
                            </span>
                            <h3 className="mb-2 text-xl font-semibold text-[#ff4757]/90">
                                Indisponible
                            </h3>
                            <p className="text-[#2f3542]/80 font-normal">
                                Aperçu temporairement indisponible
                            </p>
                        </div>
                    }
                    actions={
                        <div className="space-y-3">
                            <div className="flex justify-start space-x-4">
                                <div className="w-full pt-3 pr-3 pl-3">
                                    <h3 className="text-base font-medium text-[#192a56]/90 dark:text-white text-left">Gérer la prise en charge du patient</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        Vous avez la possibilité de planifier et de coordonner le rendez-vous avec le patient.
                                    </p>
                                </div>
                            </div>

                            <div className="flex justify-start space-x-4">
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#2f3542]/90 dark:text-white text-left mb-2 flex items-center">
                                        <CalendarMonthRoundedIcon fontSize="small" />
                                        <span className="ml-1">Date du rendez-vous</span>
                                    </h3>
                                    <div className="mt-1 text-gray-600 font-normal flex text-left text-xs justify-between items-center">
                                        <h3 className="flex items-center gap-x-2 font-semibold text-xs text-black">
                                            <u>{selectedPatient?.meetingDateTime ? formaterDateAvecJour(selectedPatient.meetingDateTime.toLocaleString()) : "N/D"}</u>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="mx-auto space-y-2 divide-y divide-gray-200 dark:divide-gray-700">
                                <div className="w-full rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#2f3542]/90 dark:text-white text-left flex items-center">
                                        <RestoreRoundedIcon fontSize="small" />
                                        <span className="ml-1">Modifier ce rendez-vous</span>
                                    </h3>
                                    <div className="mt-1 text-gray-600 font-normal flex text-left text-xs justify-between items-center">
                                        <h3 className="flex items-center gap-x-2 font-light text-xs text-black">
                                            Déplacer le rendez-vous à une autre heure et/ou une autre date.
                                        </h3>
                                        <div className="inline-flex gap-x-2">
                                            <p
                                                onClick={handleAppointementScheduling}
                                                className="py-2 px-2 inline-flex items-center text-sm font-medium text-sky-700 cursor-pointer">
                                                Modifier
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#2f3542]/90 dark:text-white text-left flex items-center">
                                        <CancelScheduleSendRoundedIcon fontSize="small" />
                                        <span className="ml-1">Annuler ce rendez-vous</span>
                                    </h3>
                                    <div className="mt-1 text-gray-600 font-normal flex text-left text-xs justify-between items-center">
                                        <h3 className="flex items-center gap-x-2 font-light text-xs text-black">
                                            Annuler le rendez-vous pris, il sera à nouveau en attente d'une prise en charge médicale.
                                        </h3>
                                        <div className="inline-flex gap-x-2">
                                            <p
                                                onClick={() => {
                                                    setModalType(ModalTypeEnum.Cancel)
                                                    setActionModalOpened(true)
                                                }}
                                                className="py-2 px-2 inline-flex items-center text-sm font-medium text-[#FC5C65] cursor-pointer">
                                                Annuler
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    footer={
                        <div className="p-4 flex justify-end gap-x-2">
                            <p 
                                onClick={() => {
                                    setIsPatientModalOpen(false)
                                    setSelectedPatient(undefined);
                                    resetTab();
                                }}
                                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold cursor-pointer rounded-lg bg-[#12CBC4] border border-transparent text-white disabled:opacity-50 disabled:pointer-events-none">
                                <CloseRoundedIcon fontSize="medium" />
                                Fermer
                            </p>
                        </div>
                    }
                >
                </CustomPatientModalWithScroll>

                {/* Ask user for action with modal */}
                <Modal
                    open={actionModalOpened}
                    title=""
                    onClose={() => setActionModalOpened(false)}>

                    {modalType === ModalTypeEnum.Error &&
                        <ErrorView
                            onCloseClick={() => {
                                setModalType(ModalTypeEnum.Nothing)
                                setActionModalOpened(false)
                            }} />
                    }
                    {modalType === ModalTypeEnum.Cancel &&
                        <CancelAppointementView
                            onCloseClick={() => {
                                setModalType(ModalTypeEnum.Nothing)
                                setActionModalOpened(false)
                            }}
                            onConfirmClick={cancelAppointement} />
                    }
                    {modalType === ModalTypeEnum.Accept &&
                        <AcceptAppointementView
                            onCloseClick={() => closeSchedulingModal()}
                            schedulePreviousForm={schedulePreviousForm}
                            scheduleAppointement={scheduleAppointement}
                            setDateTimeValue={handleDateTimeChange}
                            handleAdresseInputChange={handleAdresseInputChange}
                            handleCityInputChange={handleCityInputChange}
                            handleCommuneInputChange={handleCommuneInputChange}
                            handleZipcodeInputChange={handleZipcodeInputChange}
                            selectSuggestedDate={selectSuggestedDate}
                            handlePhoneInputChange={handlePhoneInputChange}
                            handleEmailInputChange={handleEmailInputChange}
                            dateTimeValue={dateTimeValue}
                            datesSuggestions={datesSuggestions}
                            isActionsDisabled={isActionsDisabled}
                            schedulingForm={schedulingForm}
                            phoneValue={phoneValue}
                            emailValue={emailValue}
                            cityValue={cityValue}
                            communeValue={communeValue}
                            zipcodeValue={zipcodeValue}
                            adresseValue={adresseValue}
                            dataLoading={dataLoading} />
                    }
                </Modal>
            </>

            <BottomDialogView data={filtredDataByDate} toggle={isFullScreen} toggleBoolean={toggleFullScreen} selectedDay={selectedCalendarDateValue} />
        </>
    )
}

export default AppointementsLayout;