import { UserApiResponse } from '../../Model/User/UserApiResponse';
import ApiService from '../../services/ApiService';
import { GeneralAppointementResponse } from '../../types/GeneralAppointementResponse';

class AppointmentRepository {
    private apiService: ApiService;

    constructor(private token: string, apiService: ApiService) {
        this.apiService = apiService;
    }

    async fetchUser(): Promise<UserApiResponse> {
        try {
            const userData = await this.apiService.get(`user/`);
            return userData;
        } catch (error) {
            console.error('Error loading user profile', error);
            throw new Error('Error loading user profile');
        }
    }

    /**
  * Fetch the planned appointments from the backend API.
  * @param data - The data for the user.
  */
    async fetchPlannedAppointments(userId: string, data: any): Promise<GeneralAppointementResponse> {
        try {
            // Fetch from the backend API
            const fetchedData = await this.apiService.post(`appointment/planned-appointments/`, data);
            return fetchedData;
        } catch (error) {
            console.error('Error fetching planned appointments', error);
            throw new Error('Error fetching planned appointments');
        }
    }

    /**
* Fetch the pending appointments from the backend API.
*/
    async fetchPendingAppointments(token: string, data: any): Promise<GeneralAppointementResponse> {
        try {
            // Fetch from the backend API
            const fetchedData = await this.apiService.post(`appointment/pending-appointments/`, data);
            return fetchedData;
        } catch (error) {
            console.error('Error fetching pending appointments', error);
            throw new Error('Error fetching pending appointments');
        }
    }

    /**
   * Create the appointment data and send it to the backend API.
   * @param token - The author token.
   * @param data - The created data for the new appointment.
   */
    async createAppointment(token: string, data: any): Promise<void> {
        try {
            // Create new appointment in the backend API
            await this.apiService.post(`appointment/create-appointment-request/`, data);
        } catch (error) {
            console.error('Error creating new appointment', error);
            throw new Error('Error creating new appointment');
        }
    }

    /**
   * Create online appointment data and send it to the backend API.
   * @param data - The created data for the new appointment.
   */
    async createOnlineAppointmentWithoutToken(data: any): Promise<void> {
        try {
            // Create new appointment in the backend API
            await this.apiService.post(`appointment/ask-online-appointment-request/`, data);
        } catch (error) {
            console.error('Error creating new online appointment', error);
            throw new Error('Error creating new online appointment');
        }
    }

    /**
   * Updates the appointment data and sends the update to the backend API.
   * @param appointmentId - The appointmentId.
   * @param updatedData - The updated data for the appointment.
   */
    async updateAppointment(appointmentId: string, updatedData: any): Promise<any> {
        try {
            // Update the backend API
            await this.apiService.put(`appointment/update-appointment-request/${appointmentId}`, updatedData);
        } catch (error) {
            console.error('Error updating appointment', error);
            throw new Error('Error updating appointment');
        }
    }

    /**
    * Delete the appointment data from backend API.
    * @param appointmentId - The appointmentId for the appointment.
    * @returns A Promise that resolves when the appointment is successfully deleted.
    * @throws An error if the API request fails.
    */
    async deleteAppointment(appointmentId: string): Promise<void> {
        try {
            // Call the delete method
            await this.apiService.delete(`appointment/delete-appointment-request/${appointmentId}`);
        } catch (error) {
            console.error('Error when trying to delete appointment', error);
            throw new Error('Error when trying to delete appointment');
        }
    }
}

export default AppointmentRepository;