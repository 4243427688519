import React, { useEffect } from 'react';

// Props type for the UnderMaintenance component
type UnderMaintenanceProps = {
    title: string;
    message: string;
    image: string;
    sectionTabName: string;
};

const UnderMaintenance: React.FC<UnderMaintenanceProps> = ({ title, message, image, sectionTabName }) => {
    
    useEffect(() => {
        document.title = sectionTabName;
    }, []);

    return (
        <div className="flex flex-col justify-center items-center">
            <img src={image} alt="Logo" className="mb-8 h-40" />
            <h1 className="text-2xl md:text-2xl lg:text-2xl font-bold text-center text-gray-700 dark:text-white mb-4">{title}</h1>
            <p className="text-center text-gray-500 dark:text-gray-300 text-base md:text-base lg:text-base mb-8">{message}</p>
        </div>
    );
};

export default UnderMaintenance;