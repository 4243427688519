import React from 'react';
import strings from '../../assets/strings';

interface ErrorViewProps {
    onCloseClick: () => void;
}

const ErrorView: React.FC<ErrorViewProps> = ({ onCloseClick }) => {
    return (        
        <div className="relative flex flex-col bg-white shadow-lg rounded-xl dark:bg-gray-800">
            <div className="aspect-w-16 aspect-h-8 flex justify-center items-center">
                <div className="w-full h-24 flex justify-center items-center rounded-t-xl bg-[#FC5C65] text-white">
                    <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                </div>
            </div>
            <div className="p-4 sm:p-10 text-center overflow-y-auto">
                <h3 className="mb-2 text-xl font-bold text-gray-800">
                    {strings.error_modal_label}
                </h3>
                <p className="text-gray-500">
                    {strings.error_modal_description_label}
                </p>
                <div className="mt-4 flex justify-center gap-x-4">
                    <button
                        type="button"
                        className="mt-4 bg-[#12CBC4] text-gray-100 p-4 w-full rounded-lg tracking-wide
                                                        font-semibold font-display focus:outline-none focus:shadow-outline
                                                        shadow-lg disabled:opacity-50 disabled:pointer-events-none"
                        onClick={() => onCloseClick()}
                    >
                        <p className="text-white">
                            {strings.close_label}
                        </p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ErrorView;