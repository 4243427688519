import React from 'react';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

interface DeleteAppointementViewProps {
    onCloseClick: () => void;
    onConfirmClick: () => Promise<void>;
}

const DeleteAppointementView: React.FC<DeleteAppointementViewProps> = ({ onCloseClick, onConfirmClick }) => {
    return (
        <div className="w-full shadow-lg rounded-xl bg-white p-2 pt-4 pl-4 pr-4">
            <div className="flex justify-between items-center">
                <h3 className="flex items-center gap-x-2 font-bold text-[#12CBC4]">
                    <FolderDeleteIcon fontSize="medium" />
                    Suppression
                </h3>
                <button onClick={() => onCloseClick()} type="button" className="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" data-hs-overlay="#hs-basic-modal">
                    <span className="sr-only">Close</span>
                    <CancelRoundedIcon htmlColor="#a5b1c2" fontSize="medium" />
                </button>
            </div>

            <div className="bg-white grid grid-cols-1 gap-4 lg:col-span-3 ">
                <div className="p-2">
                    <div className="relative">
                        <div className="mx-auto mt-4 w-48">
                            <p className="text-sm font-normal text-gray-700">
                                Êtes-vous sûr(e) de vouloir supprimer cette demande ?
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-6 mb-4 flex justify-end gap-x-2">
                <p onClick={() => onCloseClick()} className="py-2 px-3 inline-flex justify-center items-center gap-2 cursor-pointer rounded-lg border border-[#ff4757]/50 font-medium bg-white text-[#ff4757]/50 shadow-sm align-middle transition-all text-sm">
                    <CloseRoundedIcon fontSize="medium" />
                    Annuler
                </p>
                <p onClick={() => onConfirmClick()} className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold cursor-pointer rounded-lg bg-[#ff4757] border border-transparent text-white disabled:opacity-50 disabled:pointer-events-none">
                    <CheckRoundedIcon fontSize="medium" />
                    Confirmer
                </p>
            </div>
        </div>
    );
};

export default DeleteAppointementView;

