import React, { useEffect } from 'react';

interface FloatingLabelTextareaProps {
    id: string;
    placeholder: string;
    name: string;
    label: string;
}
  
const FloatingLabelTextarea: React.FC<FloatingLabelTextareaProps> = ({ id, name, placeholder, label }: FloatingLabelTextareaProps) => {

  useEffect(() => {
    const textarea = document.getElementById(id);

    const textareaAutoHeight = (el: HTMLElement | null, offsetTop = 0) => {
      el!.style.height = 'auto';
      el!.style.height = `${el!.scrollHeight + offsetTop}px`;
    };

    textareaAutoHeight(textarea, 3);

    const handleInput = () => {
      textareaAutoHeight(textarea, 3);
    };

    textarea!.addEventListener('input', handleInput);

    return () => {
      textarea!.removeEventListener('input', handleInput);
    };
  }, [id]);

  return (
    <div className="relative">
      <textarea
        id={id}
        name={name}
        className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent disabled:opacity-50 disabled:pointer-events-none
        focus:pt-6
        focus:pb-2
        [&:not(:placeholder-shown)]:pt-6
        [&:not(:placeholder-shown)]:pb-2
        autofill:pt-6
        autofill:pb-2"
        placeholder={placeholder}
      ></textarea>
      <label
        htmlFor={id}
        className="text-gray-600 absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-50 peer-disabled:pointer-events-none
        peer-focus:text-xs
        peer-focus:-translate-y-1.5
        peer-focus:text-gray-500
        peer-[:not(:placeholder-shown)]:text-xs
        peer-[:not(:placeholder-shown)]:-translate-y-1.5
        peer-[:not(:placeholder-shown)]:text-gray-500"
      >
        {label}
      </label>
    </div>
  );
};

export default FloatingLabelTextarea;