import React from 'react';

type StatusBadgeProps = {
  isSuccess: boolean;
};

const AccountStatusBadge: React.FC<StatusBadgeProps> = ({ isSuccess }) => {
  const statusStyles = isSuccess ? {
    text: 'Actif',
    bgColor: 'bg-teal-100',
    textColor: 'text-teal-800',
    darkBgColor: 'dark:bg-teal-500/10',
    darkTextColor: 'dark:text-teal-500',
    svgPath: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
  } : {
    text: 'Inactif',
    bgColor: 'bg-red-100',
    textColor: 'text-red-800',
    darkBgColor: 'dark:bg-red-100',
    darkTextColor: 'dark:text-red-800',
    svgPath: "M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
  };

  return (
    <div className="px-6 py-3 text-start">
      <span className={`inline-flex items-center gap-x-1 py-1.5 px-2 text-xs font-medium rounded-full ${statusStyles.bgColor} ${statusStyles.textColor} ${statusStyles.darkBgColor} ${statusStyles.darkTextColor}`}>
        <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor">
          <path d={statusStyles.svgPath} />
        </svg>
        {statusStyles.text}
      </span>
    </div>
  );
};

export default AccountStatusBadge;