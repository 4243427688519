// UserProfileViewModel.ts

import ApiService from '../../services/ApiService';
import { ApiResponseModel } from '../../types/ApiResponseModel';
import UserProfileModel from './UserProfileModel';

class UserProfileViewModel {
    private userId: string;
    public userProfileModel: UserProfileModel;
    private apiService: ApiService;

    constructor(userId: string, apiService: ApiService) {
        this.userId = userId;
        this.apiService = apiService;
        this.userProfileModel = new UserProfileModel(userId, apiService);
    }

    async loadUserProfile(): Promise<void> {
        try {
            await this.userProfileModel.fetchUserProfile();
        } catch (error) {
            console.error('Error loading user profile', error);
            throw new Error('Error loading user profile');
        }
    }

    /**
     * Updates the user profile data both locally and on the backend API.
     * @param updatedData - The updated data for the user profile.
     * @returns A Promise that resolves when the user profile is successfully updated.
     * @throws An error if the API request fails.
     */
    async updateUserProfile(updatedData: any): Promise<void> {
        try {
            // Call the updateUserProfile method in UserProfileModel
            await this.userProfileModel.updateUserProfile(updatedData);
        } catch (error) {
            console.error('Error updating user profile', error);
            throw new Error('Error updating user profile');
        }
    }

    /**
     * Updates the user profile data both locally and on the backend API.
     * @param updatedData - The updated data for the user profile.
     * @returns A Promise that resolves when the user profile is successfully updated.
     * @throws An error if the API request fails.
     */
    async updateUserPassword(updatedData: any): Promise<void> {
        try {
            // Call the updateUserPassword method in UserProfileModel
            await this.userProfileModel.updateUserPassword(updatedData);
        } catch (error) {
            console.error('Error updating user password', error);
            throw new Error('Error updating user password');
        }
    }

     /**
     * Updates the notification subscription data locally and sends the update to the backend API.
     * @param value - The value for the notification subscription.
     */
     async updateNotificationSubscription(updatedData: any): Promise<void> {
        try {
            // Call the notificationSubscription method in UserProfileModel
            await this.userProfileModel.notificationSubscription(updatedData);
        } catch (error) {
            console.error('Error updating user password', error);
            throw new Error('Error updating user password');
        }
    }
    
    /**
     * Updates the assignments supports data locally and sends the update to the backend API.
     * @param value - The value for the assignments supports.
     */
    async updateSupportsAssignments(updatedData: any): Promise<void> {
        try {
            // Call the supportsAssignments method in UserProfileModel
            await this.userProfileModel.supportsAssignments(updatedData);
        } catch (error) {
            console.error('Error updating user password', error);
            throw new Error('Error updating user password');
        }
    }

    /**
     * Delete the user profile data from backend API.
     * @param userId - The updated data for the user profile.
     * @returns A Promise that resolves when the user profile is successfully deleted.
     * @throws An error if the API request fails.
     */
    async deleteUserProfile(userId: string): Promise<ApiResponseModel> {
        try {
            // Call the deleteUserProfile method in UserProfileModel
            const response = await this.userProfileModel.deleteUserProfile(userId);
            return response;
        } catch (error) {
            console.error('Error when trying to delete user profile', error);
            throw new Error('Error when trying to delete user profile');
        }
    }    
}

export default UserProfileViewModel;
