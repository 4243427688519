
import React from 'react';

/*
* Function to check if the provided email is valid
*/
export const isValidEmail = (email: string) => {
    // Regular expression to validate an email address.    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

/*
* Function to check if two fields are identical and composed only of 6 digits
*/
export const arePinFieldsValid = (field1: string, field2: string): boolean => {
    // Check if both fields are composed only of 6 digits using regular expression
    const sixDigitRegex = /^\d{6}$/;

    return field1 === field2 && sixDigitRegex.test(field1);
};

/**
 * Checks if a given string value contains only letters (alphabets) and is not empty or null.
 *
 * @param value - The string value to be validated.
 * @returns `true` if the value contains only letters and is not empty or null, `false` otherwise.
 */
export const isLettersNotEmpty = (value: string | null | undefined): boolean => {
    // Check if the value is not null, undefined, or an empty string
    if (value == null || value === '') {
        return false;
    }

    // Check if the value contains only letters using a regular expression
    const lettersRegex = /^[a-zA-Z]+$/;

    return lettersRegex.test(value);
};