import React, { useState, useEffect, useRef } from 'react';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import { BooleanFiltersData } from './../../types/booleanFilters';

interface FilterDropBoxProps {
  filterLabel: string;
  dataOptions: BooleanFiltersData[];
  onSelectionChange: (newSelection: BooleanFiltersData[]) => void;
}

const FilterDropBox: React.FC<FilterDropBoxProps> = ({ filterLabel, dataOptions, onSelectionChange }) => {
  const [selectedOptions, setSelectedOptions] = useState<BooleanFiltersData[]>(dataOptions);

  // Method used to update boolean value
  const updateBooleanValue = (labelToUpdate: string, newValue: boolean) => {
    const updatedDataOptions = dataOptions.map(option =>
      option.label === labelToUpdate ? { ...option, value: newValue } : option
    );
    setSelectedOptions(updatedDataOptions);
  };

  useEffect(() => {
    onSelectionChange(selectedOptions);
  }, [selectedOptions, setSelectedOptions]);

  return (
    <div className="block w-full flex space-x-6 overflow-x-auto">
      <div>
        <span className="flex items-center text-sm text-gray-500">
          {filterLabel}
        </span>
        <div className="flex items-center text-sm text-gray-600 dark:text-gray-400 mt-3 mb-3">
          {dataOptions.map((option) => (
            <span 
              key={option.label} 
              className={`inline-flex items-center gap-x-1.5 py-1.5 px-3 mr-2 cursor-pointer rounded-lg text-xs font-medium ${option.value ? 'bg-blue-100 text-[#4834d4]' : 'bg-gray-100 text--[#747d8c]'}`} 
              onClick={() => updateBooleanValue(option.label, option.value = !option.value)}>
                {option.value ? <CheckCircleOutlineRoundedIcon htmlColor="#4834d4" fontSize="small" /> : <RadioButtonUncheckedRoundedIcon htmlColor="#747d8c" fontSize="small" /> }{option.label}</span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterDropBox;