import React from 'react';

interface LayoutHeaderProps {
    icon: React.ReactNode;
    title: string;
    subtitle: string;
}

const LayoutHeader: React.FC<LayoutHeaderProps> = ({ icon, title, subtitle }) => {
    return (
        <div className="bg-transparent">
            <div className="w-full mx-auto pt-7">
                <div className="grid justify-center grid-cols-1 items-center">
                    <div className="flex items-center gap-x-2">
                        {icon}
                        <div className="text-center text-start">
                            <p className="inline-flex items-center text-sm text-[#22a6b3] uppercase tracking-wider font-bold">
                                <span>{title}</span>
                            </p>
                            <p className="mt-1 text-[#22a6b3]/[.6] text-xs">
                                {subtitle}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// const LayoutHeader: React.FC = () => {
//     return (
//         <div className="bg-transparent">
//             <div className="w-full mx-auto pt-7">
//                 <div className="grid justify-center grid-cols-1 items-center">
//                     <div className="flex items-center gap-x-2">
//                         <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             fill="none"
//                             viewBox="0 0 24 24"
//                             strokeWidth={1.5}
//                             stroke="currentColor"
//                             className="w-10 h-10 text-[#22a6b3]"
//                         >
//                             <path
//                                 strokeLinecap="round"
//                                 strokeLinejoin="round"
//                                 d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122"
//                             />
//                         </svg>
//                         <div className="text-center text-start">
//                             <p className="inline-flex items-center text-sm text-[#22a6b3] uppercase tracking-wider font-bold">
//                                 <span>Vue d'ensemble</span>
//                             </p>
//                             <p className="mt-1 text-[#22a6b3]/[.6] text-xs">
//                                 Demandes en cours
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

export default LayoutHeader;
