export enum ModalTypeEnum {
    Delete = "DELETE",
    Accept = "ACCEPT",
    Cancel = "CANCEL",
    Edit = "EDIT",
    Visualize = "VISUALIZE",
    Assign = "ASSIGN",
    Forward = "FORWARD",
    FilterRequest = "FILTER_REQUEST",
    Error = "ERROR",
    Nothing = "NOTHING",
}