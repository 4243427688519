import React from 'react';

interface PriorityTagProps {
    useCase: string;
    criticity: string;
}

function getTagColor(criticity: string) {
    switch (criticity) {
        case "Urgence":
            return "bg-red-500 text-white";
        case "Moyenne":
            return "bg-amber-300 text-white";
        case "Faible":
            return "bg-green-300 text-white";
        default:
            return "bg-green-300 text-white";
    }
}

function getRightTag(useCase: string) {
    switch (useCase) {
        case "patientModal":
            return "inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium";
        default:
            return "inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium";
    }
}


const PriorityTag: React.FC<PriorityTagProps> = ({ useCase, criticity }) => {
    const tagColor = getTagColor(criticity);
    const rightTag = getRightTag(useCase);
    const classNames = `${rightTag} ${tagColor}`;
    return (
        <span className={classNames}>{criticity}</span>
    );
}

export default PriorityTag