import React, { useState, useRef, useEffect } from 'react';
import AccountStatusBadge from './AccountStatusBadge';
import { UserModelData } from '../../types/userApiModel';
// Utils imports
import { formatDate } from '../../utils/IAHelperForDate';

interface DataTableProps {
    initialRows: UserModelData[]; // Define props to accept the initial data rows
    onAccountActionClick: (userId: string, value: boolean) => void;
    onEditUserClick: (userId: string) => void;
    onShowUserClick: (userId: string) => void;
    onUserRightsClick: (userId: string) => void;
    onResetUserAccessClick: (userId: string) => void;
    onDeleteUserActionClick: (userId: string) => void;
}

const DataTable: React.FC<DataTableProps> = ({ onAccountActionClick, onEditUserClick, onShowUserClick, onUserRightsClick, onResetUserAccessClick, onDeleteUserActionClick, initialRows }) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    const [dropdownOpen, setDropdownOpen] = useState<Record<string, boolean>>({});

    // Toggle dropdown open state for a specific row
    const toggleDropdown = (rowId: string) => {
        setDropdownOpen(prev => ({
            ...prev,
            [rowId]: !prev[rowId]  // Toggle the open state
        }));
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownOpen({});  // Only close if click is outside the dropdown
            }
        };

        // Add event listener for mouse down
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                <thead className="bg-gray-50 dark:bg-neutral-900">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Prénom & Nom
                                </span>
                            </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Code postal
                                </span>
                            </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Statut
                                </span>
                            </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Création
                                </span>
                            </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-end"></th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                    {initialRows.map(row => (
                        <tr key={row._id}>
                            <td className="whitespace-nowrap">
                                <div className="px-6 py-3 text-start">
                                    <span className="text-sm text-gray-600 dark:text-neutral-400">{row.firstname} {row.lastname}</span>
                                </div>
                            </td>
                            <td className="whitespace-nowrap">
                                <div className="px-6 py-3 text-start">
                                    <span className="text-sm text-gray-600 dark:text-neutral-400">{row.zipcode}</span>
                                </div>
                            </td>
                            <td className="whitespace-nowrap">
                                <AccountStatusBadge isSuccess={row.isValidAccount} />
                            </td>
                            <td className="whitespace-nowrap">
                                <div className="px-6 py-3 text-start">
                                    <span className="text-sm text-gray-600 dark:text-neutral-400">{formatDate(row.createdDateTime.toString())}</span>
                                </div>
                            </td>
                            <td className="whitespace-nowrap">
                                <div className="px-6 py-1.5 text-end" ref={dropdownRef}>
                                    <button onClick={() => {
                                        onShowUserClick(row._id);
                                    }}
                                        type="button" className="inline-flex justify-center items-center gap-2 rounded-lg text-gray-700 align-middle disabled:opacity-50 disabled:pointer-events-none transition-all text-sm">
                                        <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5 12V6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H12M8.11111 12H12M12 12V15.8889M12 12L5 19" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                                    </button>
                                    <button onClick={() => {
                                        toggleDropdown(row._id);
                                    }} type="button" className="inline-flex justify-center items-center gap-2 rounded-lg text-gray-700 align-middle disabled:opacity-50 disabled:pointer-events-none transition-all text-sm">
                                        <svg className="flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="1" /><circle cx="19" cy="12" r="1" /><circle cx="5" cy="12" r="1" /></svg>
                                    </button>
                                    {dropdownOpen[row._id] && (
                                        <div ref={dropdownRef} className="origin-top-left absolute right-0 mr-10 w-40 divide-y divide-gray-200 bg-white shadow-2xl rounded-lg">
                                            <div className="first:pt-0 last:pb-0">
                                                <a onClick={() => {
                                                    onEditUserClick(row._id);
                                                    toggleDropdown(row._id);
                                                }} className="flex items-center gap-x-3 py-2 px-3 text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300" href="#">
                                                    Éditer
                                                </a>
                                                <a onClick={() => {
                                                    onUserRightsClick(row._id);
                                                    toggleDropdown(row._id);
                                                }} className="flex items-center gap-x-3 py-2 px-3 text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300" href="#">
                                                    Gérer les droits
                                                </a>
                                                <a onClick={() => {
                                                    onResetUserAccessClick(row._id);
                                                    toggleDropdown(row._id);
                                                }} className="flex items-center gap-x-3 py-2 px-3 text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300" href="#">
                                                    Réinitialiser
                                                </a>
                                                <a onClick={() => {
                                                    onAccountActionClick(row._id, !row.isValidAccount);
                                                    toggleDropdown(row._id);
                                                }}
                                                    className="flex items-center gap-x-3 py-2 px-3 text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300" href="#">
                                                    {row.isValidAccount ? 'Désactiver' : 'Activer'}
                                                </a>
                                            </div>
                                            <div className="first:pt-0 last:pb-0">
                                                <a onClick={() => {
                                                    onDeleteUserActionClick(row._id);
                                                    toggleDropdown(row._id);
                                                }} className="flex items-center gap-x-3 py-2 px-3 text-sm text-red-600 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-red-500 dark:hover:bg-neutral-700" href="#">
                                                    Supprimer
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {
                initialRows.length === 0 && (
                    <div className="flex justify-center items-center p-2">
                        <div className="mt-4 mb-4">
                            <h3 className="text-sm font-regular italic text-gray-400">
                                Aucune donnée disponible
                            </h3>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default DataTable;