import React from 'react';
import { DocRequestData } from './../../types/docRequestModel';
// Utils imports
import { getTheRightRequestType } from './../../utils/AppointementRequestDecode';
import { getFormalTimeWithHoursAndMinutes, getFormalDateWithDayNumberMonthAndYear } from './../../utils/IAHelperForDate';
// Icons imports
import emptySearch from './../../images/empty_search.png';

interface BottomDialogViewProps {
    selectedDay: Date;
    data: DocRequestData[];
    toggle: boolean;
    toggleBoolean: () => void;
}

const BottomDialogView: React.FC<BottomDialogViewProps> = ({ selectedDay, data, toggle, toggleBoolean }: BottomDialogViewProps) => {

    return (
        <div
            className={`fixed bottom-0 left-0 right-0 lg:pl-72 bg-white transition-all ${toggle ? 'h-full duration-300 p-2' : 'h-0 duration-300 sm:h-0 duration-300 md:h-0 duration-300 lg:h-0 duration-300'
                }`}
        >
            <p
                onClick={toggleBoolean}
                className="text-sm font-regular mb-2 flex items-center absolute top-0 right-2 py-1.5 px-1.5 rounded"
                style={{ cursor: 'pointer' }}
            >
                <button type="button" className="py-1.5 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gray-100 text-gray-500 hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none m-2">
                    <span>
                        {toggle ?
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        }
                    </span>
                    {toggle ? 'Fermer' : 'Ouvrir'}
                </button>
            </p>

            <div className="h-full overflow-y-auto space-y-4 my-4">
                {/* <!-- FAQ --> */}
                <div className="p-10 mt-8">
                    {/* <!-- Grid --> */}
                    <div className="grid md:grid-cols-5 gap-10">
                        <div className="md:col-span-2">
                            <div className="max-w-xs">
                                <h2 className="text-sky-600 text-2xl font-bold md:text-4xl md:leading-tight text-start">Planning</h2>
                                <p className="text-sky-700/60 mt-1 md:block text-gray-600 text-start">{getFormalDateWithDayNumberMonthAndYear(selectedDay.toDateString())}</p>
                            </div>
                        </div>
                        {/* <!-- End Col --> */}
                        <div className="md:col-span-3">
                            {/* <!-- Accordion --> */}
                            <div className="hs-accordion-group">
                                {Array.isArray(data) && data.length > 0 ? (
                                    data.map((item, index) => (
                                        <div key={index}>
                                            <div className="flex gap-x-3">
                                                <div className="w-16 text-end">
                                                    <span className="text-sm text-white rounded-lg bg-[#12CBC4]/90 p-2">
                                                        {item.meetingDateTime ? getFormalTimeWithHoursAndMinutes(item.meetingDateTime.toLocaleString()) : "--/--"}
                                                    </span>
                                                </div>

                                                <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                                                    <div className="relative z-10 w-7 h-7 flex justify-center items-center">
                                                        <div className="w-2 h-2 rounded-full bg-[#12CBC4]/90"></div>
                                                    </div>
                                                </div>

                                                <div className="grow pt-0.5 pb-20">
                                                    <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                                                        <svg className="flex-shrink-0 w-4 h-4 mt-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" /><polyline points="14 2 14 8 20 8" /><line x1="16" x2="8" y1="13" y2="13" /><line x1="16" x2="8" y1="17" y2="17" /><line x1="10" x2="8" y1="9" y2="9" /></svg>
                                                        {`${item.firstname} ${item.lastname}`}
                                                    </h3>
                                                    <p className="mt-1 text-sm text-gray-600 text-start">
                                                        {getTheRightRequestType(item.requestType)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="mt-2 text-center overflow-y-auto">
                                        <span className="mb-4 inline-flex justify-center items-center w-[62px] h-[62px] rounded-full bg-yellow-50">
                                            <img
                                                src={emptySearch}
                                                alt="Logo de l'application"
                                                className="h-12 rounded-md"
                                            />
                                        </span>

                                        <h3 className="text-sm font-regular text-gray-400">
                                            Aucun rendez-vous n'est prévu ce jour
                                        </h3>
                                    </div>
                                )}
                            </div>
                            {/* <!-- End Accordion --> */}
                        </div>
                        {/* <!-- End Col --> */}
                    </div>
                    {/* <!-- End Grid --> */}
                </div>
                {/* <!-- End FAQ --> */}
            </div>
        </div>
    )
}

export default BottomDialogView;