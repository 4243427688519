import React, { useEffect, useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import thinkAboutImage from '../../images/think_about.png';
import AssistantRoundedIcon from '@mui/icons-material/AssistantRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import FloatingLabelInput from "../layouts/FloatingLabelInput"
// Utils imports
import { dateformatter } from './../../utils/IAHelperForDate';
import GenericDateInputField from '../Inputs/GenericDateInputField';
import FloatingLabelInputField from '../Inputs/FloatingLabelInputField';

interface AcceptAppointementViewProps {
    onCloseClick: () => void;
    schedulePreviousForm: () => void;
    scheduleAppointement: () => Promise<void>;
    setDateTimeValue: (value: string) => void;
    selectSuggestedDate: (value: Date) => void;
    handlePhoneInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleEmailInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleAdresseInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCommuneInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCityInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleZipcodeInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    dateTimeValue: string;
    datesSuggestions: Date[];
    isActionsDisabled: boolean;
    dataLoading: boolean;
    schedulingForm: Number;
    phoneValue: string | null;
    emailValue: string | null;
    cityValue: string | null;
    communeValue: string | null;
    zipcodeValue: string | null;
    adresseValue: string | null;
}

const AcceptAppointementView: React.FC<AcceptAppointementViewProps> = ({ onCloseClick, schedulePreviousForm, handleCommuneInputChange, handleZipcodeInputChange, scheduleAppointement, setDateTimeValue, selectSuggestedDate, handleCityInputChange, handlePhoneInputChange, handleEmailInputChange, handleAdresseInputChange, dateTimeValue, datesSuggestions, isActionsDisabled, dataLoading, schedulingForm, phoneValue, emailValue, cityValue, communeValue, zipcodeValue, adresseValue }) => {

    const renderView = () => {
        switch (schedulingForm) {
            case 0:
                return <>
                    <div className="p-4 sm:p-7 overflow-y-auto">
                        <div className="text-center">
                            <h3 className="text-base font-semibold text-gray-800 dark:text-gray-200">
                                Programmez un rendez-vous
                            </h3>
                        </div>
                        <div className="pt-4">
                            <h2 className="text-xs text-gray-500 flex items-center">
                                <AssistantRoundedIcon fontSize="medium" className="pr-2" />
                                Suggestions de dates possibles
                            </h2>
                            {Array.isArray(datesSuggestions) && datesSuggestions.length > 0 ? (
                                datesSuggestions.map((item) => (
                                    <span
                                        key={item.getTime()}
                                        className={`flex items-center w-full sm:w-7/12 lg:w-7/12 md:w-7/12 xl::w-7/12 2xl::w-7/12 p-2 mt-2 sm:m-2 lg:m-2 md:m-2 xl:m-2 2xl:m-2 cursor-pointer rounded-lg text-xs font-medium bg-blue-100/60 text-[#4834d4]/80`}
                                        onClick={() => selectSuggestedDate(item)}>
                                        <EventAvailableOutlinedIcon fontSize="small" className="mr-2" />
                                        {item ? dateformatter(item.toLocaleString()) : "N/D"}
                                    </span>
                                ))
                            ) : (
                                <div className="w-full">
                                    <span className="mb-1 inline-flex justify-center items-center">
                                        <img
                                            src={thinkAboutImage}
                                            alt="Logo de l'application"
                                            className="h-28 rounded-md opacity-80"
                                        />
                                    </span>

                                    <h3 className="text-sm font-normal italic text-gray-400">
                                        Veuillez indiquer votre disponibilité
                                    </h3>
                                </div>
                            )}
                        </div>
                        <div id="form1">
                            <div className="mt-4">
                                <div className="border text-gray-800">
                                    <div className="relative">
                                        <input
                                            type="datetime-local"
                                            id="appointementDate"
                                            name="appointementDate"
                                            className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
                                                        focus:pt-6
                                                        focus:pb-2
                                                        [&:not(:placeholder-shown)]:pt-6
                                                        [&:not(:placeholder-shown)]:pb-2
                                                        autofill:pt-6
                                                        autofill:pb-2"
                                            placeholder="__ / __ / ____"
                                            value={dateTimeValue}
                                            onChange={(e) => setDateTimeValue(e.target.value)}
                                        />
                                        <label
                                            id="appointementDate"
                                            className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                                                        peer-focus:text-xs
                                                        peer-focus:-translate-y-1.5
                                                        peer-focus:text-gray-500
                                                        peer-[:not(:placeholder-shown)]:text-xs
                                                        peer-[:not(:placeholder-shown)]:-translate-y-1.5
                                                        peer-[:not(:placeholder-shown)]:text-gray-500"
                                        >
                                            Sélectionnez une date
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 mb-4 flex justify-end items-center gap-x-2">
                            <p onClick={() => {
                                if (!isActionsDisabled) {
                                    onCloseClick();
                                }
                            }}
                                className={`py-2 px-3 inline-flex justify-center items-center cursor-pointer gap-2 rounded-lg border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm ${isActionsDisabled ? 'opacity-50 pointer-events-none' : ''}`}>
                                <CloseRoundedIcon fontSize="medium" />
                                Annuler
                            </p>
                            <p onClick={() => {
                                if (!isActionsDisabled) {
                                    scheduleAppointement();
                                }
                            }}
                                className={`py-2 px-3 inline-flex items-center gap-x-2 text-sm cursor-pointer font-semibold rounded-lg bg-[#12CBC4] border border-transparent text-white ${isActionsDisabled ? 'opacity-50 pointer-events-none' : ''}`}>
                                {isActionsDisabled ? (
                                    <span className="animate-spin inline-block w-9 h-9 border-[5px] border-current border-t-transparent text-white rounded-full mr-2" role="status" aria-label="loading">
                                        <span className="sr-only">Loading...</span>
                                    </span>
                                ) : (
                                    <>
                                        <NavigateNextRoundedIcon fontSize="medium" />
                                        Valider
                                    </>
                                )}
                            </p>
                        </div>

                        <div className="mt-5 sm:mt-10">
                            <p className="text-xs text-gray-500">Vous pouvez modifier <u>le rendez-vous</u> à tout moment depuis l'onglet <b>rendez-vous</b>.</p>
                        </div>
                    </div>
                </>;
            case 1:
                return <>
                    <div className="p-4 sm:p-7 overflow-y-auto">
                        <div className="text-center">
                            <h3 className="text-base font-semibold text-gray-800 dark:text-gray-200">
                                Confirmer le lieu de la prise en charge
                            </h3>
                        </div>

                        <div id="form2" className="mt-7 space-y-4">
                            <div className="mt-4">
                                <div className="border text-gray-800">
                                    <FloatingLabelInputField
                                        id="readAdresseField"
                                        type="text"
                                        name="adresseField"
                                        placeholder="Adresse"
                                        value={adresseValue ?? ""}
                                        onChange={handleAdresseInputChange}
                                        label="Adresse"
                                        disabled={dataLoading}
                                    />
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="border text-gray-800">
                                    <FloatingLabelInputField
                                        id="readCityField"
                                        type="text"
                                        name="cityField"
                                        placeholder="City"
                                        value={cityValue ?? ""}
                                        onChange={handleCityInputChange}
                                        label="Ville"
                                        disabled={dataLoading}
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <div className="border text-gray-800">
                                    <FloatingLabelInputField
                                        id="readCommuneField"
                                        type="text"
                                        name="communeField"
                                        placeholder="Commune"
                                        value={communeValue ?? ""}
                                        onChange={handleCommuneInputChange}
                                        label="Commune"
                                        disabled={dataLoading}
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <div className="border text-gray-800">
                                    <FloatingLabelInputField
                                        id="readZipcodeField"
                                        type="text"
                                        name="zipcodeField"
                                        placeholder="Code Postal"
                                        value={zipcodeValue ?? ""}
                                        onChange={handleZipcodeInputChange}
                                        label="Code Postal"
                                        disabled={dataLoading}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 mb-4 flex justify-end items-center gap-x-2">
                            <p onClick={() => {
                                if (!isActionsDisabled) {
                                    schedulePreviousForm();
                                }
                            }}
                                className={`py-2 px-3 inline-flex justify-center cursor-pointer items-center gap-2 rounded-lg border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm ${isActionsDisabled ? 'opacity-50 pointer-events-none' : ''}`}>
                                <CloseRoundedIcon fontSize="medium" />
                                Annuler
                            </p>
                            <p onClick={() => {
                                if (!isActionsDisabled) {
                                    scheduleAppointement();
                                }
                            }}
                                className={`py-2 px-3 inline-flex items-center cursor-pointer gap-x-2 text-sm font-semibold rounded-lg bg-[#12CBC4] border border-transparent text-white ${isActionsDisabled ? 'opacity-50 pointer-events-none' : ''}`}>
                                {isActionsDisabled ? (
                                    <span className="animate-spin inline-block w-9 h-9 border-[5px] border-current border-t-transparent text-white rounded-full mr-2" role="status" aria-label="loading">
                                        <span className="sr-only">Loading...</span>
                                    </span>
                                ) : (
                                    <>
                                        <NavigateNextRoundedIcon fontSize="medium" />
                                        Valider
                                    </>
                                )}
                            </p>
                        </div>

                        <div className="mt-5 sm:mt-10">
                            <p className="text-xs text-gray-500">Vous pouvez modifier <u>le rendez-vous</u> à tout moment depuis l'onglet <b>rendez-vous</b>.</p>
                        </div>
                    </div>
                </>;
            case 2:
                return <>
                    <div className="p-4 sm:p-7 overflow-y-auto">
                        <div className="text-center">
                            <h3 className="text-base font-semibold text-gray-800 dark:text-gray-200">
                                Confirmer vos coordonnées de contact
                            </h3>
                        </div>
                        <div>
                            <div id="for333">
                                <div className="mt-4 space-y-4">
                                    <div className="sm:col-span-3 border text-gray-800">
                                        <FloatingLabelInputField
                                            id="readFirstnameField"
                                            type="text"
                                            name="firstnameField"
                                            placeholder="Prénom"
                                            value={phoneValue ?? ""}
                                            onChange={handlePhoneInputChange}
                                            label="Téléphone"
                                            disabled={dataLoading}
                                        />
                                    </div>
                                    <div className="sm:col-span-3 border text-gray-800">
                                        <FloatingLabelInputField
                                            id="readFirstnameField"
                                            type="text"
                                            name="firstnameField"
                                            placeholder="Prénom"
                                            value={emailValue ?? ""}
                                            onChange={handleEmailInputChange}
                                            label="Adresse email"
                                            disabled={dataLoading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 mb-4 flex justify-end items-center gap-x-2">
                            <p onClick={() => {
                                if (!dataLoading) {
                                    schedulePreviousForm();
                                }
                            }}
                                className={`py-2 px-3 inline-flex justify-center cursor-pointer items-center gap-2 rounded-lg border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm ${dataLoading ? 'opacity-50 pointer-events-none' : ''}`}>
                                <CloseRoundedIcon fontSize="medium" />
                                Annuler
                            </p>
                            {!dataLoading ? (
                                <p
                                    onClick={() => {
                                        if (!dataLoading) {
                                            scheduleAppointement();
                                        }
                                    }}
                                    className={`py-2 px-4 flex justify-center items-center cursor-pointer gap-x-2 text-sm font-semibold rounded-lg bg-[#12CBC4] border border-transparent text-white ${dataLoading ? 'opacity-50 pointer-events-none' : ''}`}>
                                    <CheckRoundedIcon fontSize="medium" />
                                    Valider
                                </p>
                            ) : (
                                <p
                                    className={`py-2 px-4 flex justify-center items-center cursor-pointer gap-x-2 text-sm font-semibold rounded-lg bg-[#12CBC4] border border-transparent text-white ${dataLoading ? 'opacity-50 pointer-events-none' : ''}`}>
                                    <span className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                                        <span className="sr-only">Loading...</span>
                                    </span>
                                </p>
                            )}
                        </div>
                        <div className="mt-5 sm:mt-10">
                            <p className="text-xs text-gray-500">Vous pouvez modifier <u>le rendez-vous</u> à tout moment depuis l'onglet <b>rendez-vous</b>.</p>
                        </div>
                    </div>
                </>;
            case 3:
                return <>
                    <div className="p-4 sm:p-7 overflow-y-auto">
                        <div className="text-center">
                            <h3 className="text-base font-normal text-gray-800 dark:text-gray-200">
                                Excellent ! Le rendez-vous a bien été enregistrée.
                            </h3>
                        </div>

                        {/* <!-- Button --> */}
                        <button
                            type="button"
                            className="mt-10 bg-[#12CBC4] text-gray-100 p-4 w-full rounded-lg tracking-wide
                                        font-semibold font-display focus:outline-none focus:shadow-outline
                                        shadow-lg disabled:opacity-50 disabled:pointer-events-none"
                            onClick={() => {
                                if (!isActionsDisabled) {
                                    onCloseClick();
                                }
                            }}
                        >
                            <p className="text-white">
                                D'accord
                            </p>
                        </button>
                        {/* <!-- End Buttons --> */}

                        <div className="mt-2 sm:mt-5">
                            <p className="text-xs text-gray-500">Vous pouvez modifier <u>le rendez-vous</u> à tout moment depuis l'onglet <b>rendez-vous</b>.</p>
                        </div>
                    </div>
                </>;
            default:
                return <>Erreur</>;
        }
    };


    return (
        <div className="relative flex flex-col bg-white shadow-lg rounded-xl pointer-events-auto dark:bg-gray-800 z-[70]">
            <div className="relative overflow-hidden min-h-[8rem] bg-[#12CBC4] text-center rounded-t-xl p-2 pt-4 pl-4 pr-4">
                <div className="flex justify-between items-center">
                    <h3 className="flex items-center gap-x-2 font-bold text-white">
                        <CalendarMonthRoundedIcon fontSize="medium" />
                        Gestion des rendez-vous
                    </h3>
                    <button
                        onClick={() => {
                            if (!dataLoading) {
                                onCloseClick()
                            }
                        }}
                        type="button"
                        className={`flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-white ${dataLoading ? 'disabled:opacity-50 disabled:pointer-events-none' : ''}`}
                        disabled={isActionsDisabled}>
                        <span className="sr-only">Close</span>
                        <CancelRoundedIcon fontSize="medium" />
                    </button>
                </div>

                <figure className="absolute inset-x-0 bottom-0 -mb-px">
                    <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                        <path fill="currentColor" className="fill-white dark:fill-gray-800" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
                    </svg>
                </figure>

            </div>

            <div className="relative z-10 -mt-12">
                <span className="mx-auto flex justify-center items-center w-[62px] h-[62px] rounded-full border border-gray-200 bg-white text-[#12CBC4] shadow-sm">
                    <DescriptionRoundedIcon fontSize="medium" />
                </span>
            </div>


            {renderView()}


        </div>
    );
};

export default AcceptAppointementView;