import React from 'react';

interface SelectOption {
    value: string;
    label: string;
}

interface FloatingLabelSelectInputFieldProps {
    id: string;
    label: string;
    options: SelectOption[];
    disabled?: boolean;
    className?: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    value: string;
}

const FloatingLabelSelectInputField: React.FC<FloatingLabelSelectInputFieldProps> = ({
    id,
    label,
    options,
    disabled = false,
    className,
    onChange,
    value
}) => {
    return (
        <div className={`relative ${className}`}>
            <select
                id={id}
                className={`appearance-none peer p-3 pe-9 block w-full rounded border-r-8 text-sm disabled:opacity-80 disabled:pointer-events-none
                focus:pt-6
                focus:pb-2
                [&:not(:placeholder-shown)]:pt-6
                [&:not(:placeholder-shown)]:pb-2
                autofill:pt-6
                autofill:pb-2 border-transparent bg-no-repeat bg-right bg-contain arrow-icon`}
                disabled={disabled}
                onChange={onChange}
                value={value}
            >
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <label
                htmlFor={id}
                className="absolute top-0 start-0 pt-3 pb-4 pl-3 h-full truncate pointer-events-none transition ease-in-out duration-100 border border-transparent dark:text-white peer-disabled:opacity-80 peer-disabled:pointer-events-none
                peer-focus:text-xs
                peer-focus:-translate-y-1.5
                peer-focus:text-gray-500
                peer-[:not(:placeholder-shown)]:text-xs
                peer-[:not(:placeholder-shown)]:-translate-y-1.5
                peer-[:not(:placeholder-shown)]:text-gray-500"
            >
                {label}
            </label>
            <svg
                className="absolute top-0 right-0 p-4 h-full pointer-events-none text-gray-500" 
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path d="M19 9l-7 7-7-7"></path>
            </svg>
        </div>
    );
};

export default FloatingLabelSelectInputField;
