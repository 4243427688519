// AppointementDashboardViewModel.ts

import { GeneralAppointementResponse } from '../../../types/GeneralAppointementResponse';
import ApiService from './../../../services/ApiService';
import AppointementDashboardModel from './AppointementDashboardModel';

class AppointementDashboardViewModel {
    private userId: string;
    public appointementDashboardModel: AppointementDashboardModel;
    private apiService: ApiService;

    constructor(userId: string, apiService: ApiService) {
        this.userId = userId;
        this.apiService = apiService;
        this.appointementDashboardModel = new AppointementDashboardModel(userId, apiService);
    }

    async loadAppointementsRequests(filters: any): Promise<GeneralAppointementResponse> {
        try {
            const response = await this.appointementDashboardModel.fetchAppointementsRequests(filters);
            return response;
        } catch (error) {
            console.error('Error loading user profile', error);
            throw new Error('Error loading user profile');
        }
    }

    async assignAppointementsRequests(dataToSend: any): Promise<GeneralAppointementResponse> {
        try {
            const response = await this.appointementDashboardModel.AssignAppointementsRequests(dataToSend);
            return response;
        } catch (error) {
            console.error('Error assign request', error);
            throw new Error('Error assign request');
        }
    }

    /**
     * Updates the user profile data both locally and on the backend API.
     * @param updatedData - The updated data for the user profile.
     * @returns A Promise that resolves when the user profile is successfully updated.
     * @throws An error if the API request fails.
     */
    async updateAppointement(appointementId: string, updatedData: any): Promise<GeneralAppointementResponse> {
        try {
            // Call the updateUserProfile method in UserProfileModel
            const response = await this.appointementDashboardModel.updateAppointementsRequest(appointementId, updatedData);
            return response;
        } catch (error) {
            console.error('Error updating user profile', error);
            throw new Error('Error updating user profile');
        }
    }

     /**
     * Delete the appointement data both locally and on the backend API.
     * @returns A Promise that resolves when the user profile is successfully deleted.
     * @throws An error if the API request fails.
     */
     async deleteAppointement(appointementId: string): Promise<GeneralAppointementResponse> {
        try {
            // Call the deleteAppointement method
            const response = await this.appointementDashboardModel.deleteAppointementsRequest(appointementId);
            return response;
        } catch (error) {
            console.error('Error deleting appointement', error);
            throw new Error('Error deleting appointement');
        }
    }
}

export default AppointementDashboardViewModel;
