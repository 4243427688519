import { Link, Route, Routes, useNavigate, Navigate, useLocation } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper";
import { nav } from "./navigation";


export const RenderRoutes = () => {
     const location = useLocation()

     const { user, logout } = AuthData();
     const navigate = useNavigate();

     const doLogout = async () => {

          await logout()
          navigate("/login")

     }

     return (
          <Routes>
               {/* Redirige l'utilisateur vers le tableau de bord s'il est authentifié et tente d'accéder à /signin */}
               {localStorage.getItem("token") != null && <Route path="/signin" element={<Navigate replace to="/mydashboard" />} />}

               {/* Redirige l'utilisateur vers le tableau de bord s'il est authentifié et tente d'accéder à /register */}
               {localStorage.getItem("token") != null && <Route path="/register" element={<Navigate replace to="/mydashboard" />} />}

               {/* Redirige l'utilisateur vers le tableau de bord s'il est authentifié et tente d'accéder à /forgotPassword */}
               {localStorage.getItem("token") != null && <Route path="/forgotPassword" element={<Navigate replace to="/mydashboard" />} />}

               {nav.map((r, i) => (
                    <Route
                         key={i}
                         path={r.path}
                         element={
                              r.isPrivate ? (
                                   localStorage.getItem("token") != null ? (
                                        r.element
                                   ) : (
                                        <Navigate replace to="/signin" />
                                   )
                              ) : (
                                   r.element
                              )
                         }
                    />
               ))}
               {/* Route pour la gestion des pages introuvables */}
               <Route path="*" element={<Navigate replace to="/notfound" />} />
          </Routes>
     )
}

export const RenderMenu = () => {

     const { user, logout } = AuthData()

     const MenuItem = ({ r }) => {
          return (
               <div className="menuItem"><Link to={r.path}>{r.name}</Link></div>
          )
     }
     return (
          <div className="menu">
               {nav.map((r, i) => {

                    if (!r.isPrivate && r.isMenu) {
                         return (
                              <MenuItem key={i} r={r} />
                         )
                    } else if (user.isAuthenticated && r.isMenu) {
                         return (
                              <MenuItem key={i} r={r} />
                         )
                    } else return false
               })}

               {user.isAuthenticated ?
                    <div className="menuItem"><Link to={'#'} onClick={logout}>Log out</Link></div>
                    :
                    <div className="menuItem"><Link to={'login'}>Log in</Link></div>}
          </div>
     )
}