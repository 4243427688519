// AuthenticationViewModel.ts

import ApiService from '../../services/ApiService';
import { GeneralAppointementResponse } from '../../types/GeneralAppointementResponse';
import AuthenticationModel from './AuthenticationModel';

class AuthenticationViewModel {
    public authenticationModel: AuthenticationModel;
    private apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
        this.authenticationModel = new AuthenticationModel(apiService);
    }

    async forgotPasswordQuery(updatedData: any): Promise<any> {
        try {
            const response = await this.authenticationModel.forgotPasswordsRequest(updatedData);
            return response;
        } catch (error) {
            console.error('Error recover password', error);
            throw new Error('Error recover password');
        }
    }

    /**
     * Updates the user profile data both locally and on the backend API.
     * @param updatedData - The updated data for the user profile.
     * @returns A Promise that resolves when the user profile is successfully updated.
     * @throws An error if the API request fails.
     */
    async otpCheckerQuery(updatedData: any): Promise<any> {
        try {
            // Call the updateUserProfile method in UserProfileModel
            const response = await this.authenticationModel.otpCheckerRequest(updatedData);
            return response;
        } catch (error) {
            console.error('Error trying to checking otp', error);
            throw new Error('Error trying to checking otp');
        }
    }

     /**
     * Updates the user profile data both locally and on the backend API.
     * @param updatedData - The updated data for the user profile.
     * @returns A Promise that resolves when the user profile is successfully updated.
     * @throws An error if the API request fails.
     */
     async passwordResetQuery(updatedData: any): Promise<any> {
        try {
            // Call the updateUserProfile method in UserProfileModel
            const response = await this.authenticationModel.resetPasswordRequest(updatedData);
            return response;
        } catch (error) {
            console.error('Error trying to rest password', error);
            throw new Error('Error trying to reset password');
        }
    }
}

export default AuthenticationViewModel;
