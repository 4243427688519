import React, { useState, useEffect } from 'react';
import { LayoutsEnum } from './../../types/layoutsEnum';
import Header from "../layouts/Header";
// import SystmeBanner from "./../components/SystemBanner";
import Sidebar from "./../layouts/Sidebar";
import TeamSupportLayout from "./../layouts/TeamSupportLayout";
import PatientRequestLayout from "./../layouts/PatientRequestLayout";
import AppointementDashboardView from "../appointement/appointement-dashboard/AppointementDashboardView";
import AppointementsLayout from "./../layouts/AppointementsLayout";
import AdminBoardLayout from "./../layouts/AdminBoardLayout";
import Modal from "./../layouts/Modal"
import { useNavigate } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper"
// Icons imports
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import UnderMaintenanceIcon from './../../images/app_under_maintenance.png';
import UserProfileView from '../userProfile/UserProfileView';
import ApiService from '../../services/ApiService';
import UserProfileViewModel from '../userProfile/UserProfileViewModel';
import AppointementDashboardViewModel from '../appointement/appointement-dashboard/AppointementDashboardViewModel';
import UnderMaintenance from '../Unavailable/UnderMaintenance';
import strings from '../../assets/strings';
import { decodeJwtToken } from './../../auth/AuthService';

export const MyDashboard = () => {
    const apiUrl = "https://api.doctraitant.fr/api";

    const apiService = new ApiService(apiUrl);
    // ViewModels
    const userProfileViewModel = new UserProfileViewModel(localStorage.getItem("userId") ?? "", apiService);
    const appointementDashboardViewModel = new AppointementDashboardViewModel(localStorage.getItem("userId") ?? "", apiService);
    const [toggle, setToggle] = useState(true);
    const [selectedView, setSelectedView] = useState("");
    const [currentViewType, setCurrentViewType] = useState<LayoutsEnum>(LayoutsEnum.AppointementsRequest);
    const [open, setOpen] = useState(false)
    const { user, logout } = AuthData();
    const [userPermissions, setUserPermissions] = useState<string | null>(localStorage.getItem("userRights"));
    const navigate = useNavigate();

    const doLogout = async () => {
        try {
            await logout()
            localStorage.clear()
            if (userPermissions?.includes("Patient")) {
                navigate("/connectme")
            } else {
                navigate("/signin")
            }
        } catch {
            console.log("logout from account")
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const { expired } = decodeJwtToken(token);
            if (expired) {
                doLogout(); 
            }
        }
    }, [])

    const toggleAccountView = () => {
        setSelectedView("user-account");
        setToggle(!toggle);
    };

    const toggleBoolean = () => {
        setToggle(!toggle);
    };

    const toggleBooleanFromSidebar = () => {
        setToggle(!toggle);
    };

    const modalLogoutStateChanger = () => {
        setOpen(true);
    };

    const handlePageChange = (layoutsEnum: LayoutsEnum) => {
        setCurrentViewType(layoutsEnum)
        setSelectedView("");
    };

    const viewShouldBeDisplayed = () => {
        if (currentViewType === LayoutsEnum.AppointementsRequest) {
            return <AppointementDashboardView userId={0} appointementDashboardViewModel={appointementDashboardViewModel} />
        } else if (currentViewType === LayoutsEnum.Account) {
            return <UserProfileView userId={0} userProfileViewModel={userProfileViewModel} />
        } else if (currentViewType === LayoutsEnum.Appointements) {
            return <AppointementsLayout />
        } else if (currentViewType === LayoutsEnum.TeamSupport) {
            return <TeamSupportLayout />
        } else if (currentViewType === LayoutsEnum.PatientRequest) {
            return <PatientRequestLayout />
        } else if (currentViewType === LayoutsEnum.AdminBoard) {
            return <AdminBoardLayout />
        } else {
            return <UnderMaintenance title={"Section en maintenance"} message={"Nous nous efforçons d'améliorer votre expérience utilisateur. Restez connectés pour découvrir les nouveautés !"} image={UnderMaintenanceIcon} sectionTabName={strings.control_center_title_tab} />
        }
    }

    return (
        <>
            <div className="flex flex-col h-screen">
                <Header toggle={toggle} toggleBoolean={toggleBoolean} toggleAccountView={toggleAccountView} modalLogoutStateChanger={modalLogoutStateChanger} />
                {/* This banner will be displayed when the account is disabled */}
                {/* <SystmeBanner description={"Votre compte est actuellement désactivé, veuillez vous rendre dans les paramètres du compte pour le réactiver."} /> */}

                <Sidebar onPageChange={handlePageChange} toggle={toggle} selectedView={selectedView} toggleBoolean={toggleBooleanFromSidebar} />
                <main className="px-4 sm:px-6 md:px-8 lg:pl-72 bg-slate-50 flex-1 overflow-y-auto">
                    {viewShouldBeDisplayed()}
                </main>
            </div>
            {/* Ask user for logout with modal */}
            <Modal
                open={open}
                title=""
                onClose={() => setOpen(false)} data-modal-backdrop="static">
                <div className="w-full shadow-lg rounded-xl bg-white p-2 pt-4 pl-4 pr-4">
                    <div className="flex justify-between items-center">
                        <h3 className="flex items-center gap-x-2 font-bold text-[#12CBC4]">
                            <LogoutRoundedIcon fontSize="medium" />
                            Déconnexion
                        </h3>
                        <button onClick={() => setOpen(false)} type="button" className="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" data-hs-overlay="#hs-basic-modal">
                            <span className="sr-only">Close</span>
                            <CancelRoundedIcon htmlColor="#a5b1c2" fontSize="medium" />
                        </button>
                    </div>

                    <div className="bg-white grid grid-cols-1 gap-4 lg:col-span-3 ">
                        <div className="p-2">
                            <div className="relative">
                                <div className="mx-auto mt-4 w-48">
                                    <p className="text-sm font-normal text-gray-700">
                                        Êtes-vous sûr(e) de vouloir vous déconnecter ?
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 mb-4 flex justify-end gap-x-2">
                        <p onClick={() => setOpen(false)} className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-lg border border-[#ff4757]/50 font-medium bg-white text-[#ff4757]/50 shadow-sm align-middle transition-all text-sm cursor-pointer">
                            <CloseRoundedIcon fontSize="medium" />
                            Annuler
                        </p>
                        <p onClick={doLogout} className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg bg-[#ff4757] border border-transparent text-white disabled:opacity-50 disabled:pointer-events-none cursor-pointer">
                            <LogoutRoundedIcon fontSize="medium" />
                            Confirmer
                        </p>
                    </div>
                </div>
            </Modal>
        </>
    )
}