import React, { useState } from 'react';
import { DocRequestData } from './../../types/docRequestModel';
import CustomPatientModalWithScroll from "./CustomPatientModalWithScroll";
import Modal from "./Modal";
import PriorityTag from './PriorityTag';
// Utils imports
import { getTheRightRequestType } from './../../utils/AppointementRequestDecode';
// Icons imports
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import SnippetFolderRoundedIcon from '@mui/icons-material/SnippetFolderRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const getFormattedTime = (isoString: string | number | Date) => {
    const date = new Date(isoString);
    const hours = String(date.getUTCHours()).padStart(2, '0'); // Prend les heures UTC
    const minutes = String(date.getUTCMinutes()).padStart(2, '0'); // Prend les minutes UTC
    return `${hours}:${minutes}`;
  };

function determineTitle(gender: string) {
    return gender === 'F' ? 'Mme.' : 'M.';
}

function formatHeureMinutes(dateInput: string | Date) {
    // Vérifier si l'entrée est déjà un objet Date
    console.log("dqssqdqs date", dateInput)
    const date = dateInput instanceof Date ? dateInput : new Date(dateInput);

    // Vérifier que la date est valide
    if (isNaN(date.getTime())) {
        console.error("Date invalide :", dateInput);
        return "--:--";
    }

    const heures = date.getHours(); // Prend en compte le fuseau local
    const minutes = date.getMinutes();

    return `${heures}h${minutes < 10 ? '0' : ''}${minutes}`;
}


function formaterDateAvecJour(dateString: string) {
    const date = new Date(dateString);
    const joursSemaine = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    const mois = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];

    const jourSemaine = joursSemaine[date.getDay()];
    const jour = date.getDate();
    const moisAnnee = mois[date.getMonth()];
    const annee = date.getFullYear();
    const heures = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${jourSemaine} ${jour} ${moisAnnee} ${annee} à ${heures}h${minutes}`;
}

interface EventItemProps {
    data: DocRequestData;
    itemToogle: boolean;
    itemToggleBoolean: (data: DocRequestData) => void;
    modalTrigger: (value: boolean) => void;
}

const EventItem: React.FC<EventItemProps> = ({ data, itemToogle, itemToggleBoolean, modalTrigger }: EventItemProps) => {
    const [isPatientModalOpen, setIsPatientModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const handleModalTrigger = () => {
        modalTrigger(true);
    };

    return (
        <>
            <div onClick={() => handleModalTrigger()} className="inline-block px-1">
                <div
                    className="w-60 h-20 overflow-hidden rounded-md shadow-sm bg-white transition-shadow duration-300 ease-in-out"
                >
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-start flex items-center text-xs gap-x-1 font-medium text-teal-700 p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-teal-700">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" />
                                </svg>
                                {getTheRightRequestType(data.requestType)}
                            </p>
                        </div>
                        <div className="p-2">
                            <p className="text-start flex items-center text-xs gap-x-1 font-medium text-teal-700 bg-[#12CBC4]/20 rounded-md p-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                                {data.meetingDateTime ? getFormattedTime(data.meetingDateTime) : "--/--"}
                            </p>
                        </div>
                    </div>
                    <p className="text-gray-500 text-xs font-normal text-start pt-2 pl-3">{`${data.firstname} ${data.lastname}`}</p>
                </div>
            </div>
        </>
    )
}

export default EventItem;