// UserProfileModel.ts

import ApiService from '../../services/ApiService';
import { ApiResponseModel } from '../../types/ApiResponseModel';
import { UserProfile } from '../../types/userProfile';

class UserProfileModel {
    private userProfile: UserProfile | null = null;
    private apiService: ApiService;

    constructor(private userId: string, apiService: ApiService) {
        this.apiService = apiService;
    }

    async fetchUserProfile(): Promise<void> {
        try {
            console.log("Load user profile...")
            // const userData = await this.apiService.get(`user/${this.userId}`);
            // this.userProfile = userData;
        } catch (error) {
            console.error('Error loading user profile', error);
            throw new Error('Error loading user profile');
        }
    }

    getUserProfile(): UserProfile | null {
        return this.userProfile;
    }

    /**
     * Updates the user profile data locally and sends the update to the backend API.
     * @param updatedData - The updated data for the user profile.
     */
    async updateUserProfile(updatedData: any): Promise<void> {
        try {
            // Update the local UserProfileModel
            if (this.userProfile) {
                Object.assign(this.userProfile, updatedData);
            }
            // Update the backend API
            await this.apiService.put(`user/update-user-request/${this.userId}`, updatedData);
        } catch (error) {
            console.error('Error updating user profile', error);
            throw new Error('Error updating user profile');
        }
    }

     /**
     * Updates the user profile data locally and sends the update to the backend API.
     * @param updatedData - The updated data for the user profile.
     */
     async updateUserPassword(updatedData: any): Promise<void> {
        try {
            // Update the local UserProfileModel
            if (this.userProfile) {
                Object.assign(this.userProfile, updatedData);
            }
            // Update the backend API
            await this.apiService.put(`user/update-user-security/${this.userId}`, updatedData);
        } catch (error) {
            console.error('Error updating user profile', error);
            throw new Error('Error updating user profile');
        }
    }

    /**
     * Updates the notification subscription data locally and sends the update to the backend API.
     * @param value - The value for the notification subscription.
     */
    async notificationSubscription(updatedData: any): Promise<void> {
        try {
            // Update the backend API
            await this.apiService.put(`user/update-notifications-subscription/${this.userId}`, updatedData);
        } catch (error) {
            console.error('Error updating notification subscription', error);
            throw new Error('Error updating notification subscription');
        }
    }
    
    /**
     * Updates the assignments supports data locally and sends the update to the backend API.
     * @param value - The value for the assignments supports.
     */
    async supportsAssignments(updatedData: any): Promise<void> {
        try {
            // Update the backend API
            await this.apiService.put(`user/update-supports-assignments/${this.userId}`, updatedData);
        } catch (error) {
            console.error('Error updating assignments supports', error);
            throw new Error('Error updating assignments supports');
        }
    }

     /**
     * Delete the user profile from backend.
     * @param userId - The id for the user to delete.
     */
     async deleteUserProfile(userId: string): Promise<ApiResponseModel> {
        try {
            // Delete user from backend
            const response = await this.apiService.delete(`user/delete-user-request/${this.userId}`);
            return response;
        } catch (error) {
            console.error('Error when we tryied to delete user profile', error);
            throw new Error('Error deleting user profile');
        }
    }
}

export default UserProfileModel;