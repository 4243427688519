const strings = {
    //SideNavbar
    requests_label: "Demandes",
    manage_account_label: "Gestion du compte",
    appointements_planning_label: "Mes rendez-vous",
    support_label: "Support",
    logout_label: "Déconnexion",
    //Views header
    requests_header_label: "Vue d'ensemble",
    requests_header_description_label: "Demandes en cours",
    manage_account_header_label: "Compte et sécurité",
    manage_account_header_description_label: "Consulter et ajuster vos paramètres",
    appointements_planning_header_label: "Mes rendez-vous",
    appointements_planning_header_description_label: "Gérer votre planning médical",
    support_header_label: "Assistance utilisateurs",
    support_header_description_label: "Contacter le service de support utilisateurs",
    //Policies conditions
    legal_mentions_title_label: "Mentions légales et conditions générales d'utilisation",
    data_protection_title_label: "Protection des données personnelles",
    cookies_popup_title_label: "Respect de votre vie privée",
    cookies_popup_description_label: "Doctraitant utilise des cookies pour améliorer votre expérience sur notre application.\n Ces cookies sont essentiels pour le bon fonctionnement de certaines fonctionnalités.\n Vous avez la possibilité de les accepter ou de les refuser.\nPour plus d'informations sur notre utilisation des cookies et sur vos options de gestion, veuillez consulter notre politique de confidentialité.",
    //Landing page Main, Footer and Header
    legal_mentions_label: "Mentions légales et CGU",
    data_protection_label: "Protection des données personnelles",
    cookies_policies_label: "Gestion des cookies",
    contactus_label: "Contact",
    welcome_label: "Accueil",
    about_label: "À Propos",
    why_doctor_label: "Pourquoi",
    feature_label: "Atouts",
    how_it_work_label: "Comment ça marche",
    help_label: "Aide",
    im_doctor_label: "Je suis médecin",
    get_my_doctor_label: "Je trouve mon médecin traitant",
    app_store_label: "l’App Store",
    google_play_label: "Google Play",
    download_from_label: "Télécharger dans",
    available_on_label: "DISPONIBLE SUR",
    download_the_app_label: "Téléchargez l'application",
    download_the_app_description_label: "Prenez soin de votre santé dès maintenant!",
    main_header_find_label: "Trouvez votre",
    main_header_doctor_label: "Médecin traitant!",
    main_header_description_label: "Plus besoin de passer des heures à chercher ou à attendre au téléphone, DocTraitant vous propose une interface conviviale et des fonctionnalités intuitives afin de trouver un médecin traitant à proximité de chez vous, pour vous et vos proches.",
    main_header_explore_the_app_label: "Explorez et réinventez votre santé",
    main_header_explore_the_app_ui_label: "Navigation Intuitive",
    main_header_explore_the_app_ui_description_label: "Explorez notre interface utilisateur intuitive pour une expérience fluide et sans effort.",
    main_header_explore_the_app_healthcare_label: "Soins Simplifiés",
    main_header_explore_the_app_healthcare_description_label: "Accédez à des soins de qualité pour vous et vos proches.",
    main_header_explore_the_app_planning_label: "Planification Instantanée",
    main_header_explore_the_app_planning_description_label: "Prenez rendez-vous en toute simplicité et soyez notifié instantanément dès que votre prise en charge est confirmée.",
    main_header_explore_the_app_privacy_label: "Sécurité et Confidentialité",
    main_header_explore_the_app_privacy_description_label: "Vos données sont sécurisées et traitées avec le plus grand soin, vous permettant une utilisation en toute confiance.",
    //Register
    create_account_label: "Création d'un compte",
    create_account_description_label: "Créer votre compte en tant que professionnel de santé et commencez à prendre en charge vos patients en quelques étapes simples.",
    create_user_request_label: "Votre demande en ligne",
    create_user_request_description_label: "Faites votre demande en ligne pour trouver un médecin traitant en toute simplicité et rapidité. Suivez l’évolution de votre démarche et réalisez des demandes pour vos proches — parents, enfants ou conjoint — directement depuis l’application mobile, et profitez d’outils avancés pour un suivi optimal.",
    have_an_account_label: "Vous avez déja un compte?",
    please_connect_label: "Connectez-vous",
    please_connect_to_the_app_label: "Accédez à votre compte",
    personal_informations_section_label: "Informations personnelles",
    personal_informations_section_description_label: "Partagez vos détails personnels pour personnaliser votre expérience et nous permettre de mieux vous connaître.",
    profesional_informations_section_label: "Informations professionnelles & coordonnées",
    profesional_informations_section_description_label: "Fournissez des informations professionnelles et des coordonnées pour créer une connexion plus étroite et faciliter la communication avec vous.",
    security_informations_section_label: "Confidentialité et Sécurité",
    security_informations_section_description_label: "Établissez votre première ligne de défense en créant un mot de passe ou un code d'accès unique et mémorable.",
    create_account_button_label: "Créer mon compte",
    //Create online request
    create_online_request_button_label: "Transmettre ma demande",
    //Forgot Password
    forgot_password_account_label: "Réinitialisation",
    forgot_password_account_description_label: "Restaurez votre accès en recevant un code OTP par email et suivez les étapes simples pour réinitialiser votre mot de passe.",
    forgot_password_account_back_to_login_label: "Revenir à l'écran de connexion",
    forgot_password_account_success_label: "Réinitialisation du code confidentiel réussie",
    forgot_password_account_success_description_label: "Votre code confidentiel a été réinitialisé avec succès. Vous pouvez maintenant vous reconnecter à votre espace.",
    //Login
    authentification_label: "Authentification",
    username_label: "Identifiant",
    username_placeholder_label: "Saisissez votre identifiant",
    password_label: "Mot de passe",
    password_placeholder_label: "Saisissez votre mot de passe",
    not_account_yet_label: "Pas de commpte encore?",
    //Not Found
    not_found_first_description_label: "Oups, quelque chose s'est mal passé.",
    not_found_second_description_label: "Désolé, le contenu que vous recherchez n'existe pas.",
    please_connect_to_your_account_label: "Connectez-vous à votre compte",
    contact_admin_label: "Contactez un administrateur",
    //Dashboard
    //Manage Account
    update_user_informations_label: "Mettre à jour mes informations personnelles",
    update_user_informations_description_label: "Procédez à la mise à jour de vos informations personnelles en quelques clics dans cette section.",
    update_user_pro_informations_label: "Mettre à jour mes informations professionnelles",
    update_user_pro_informations_description_label: "Actualisez vos informations professionnelles en toute simplicité dans cette section.",
    update_user_password_label: "Modifier le mot de passe du compte",
    update_user_password_description_label: "Rendez votre compte plus sécurisé en modifiant facilement votre mot de passe dans cette section.",
    manage_user_settings_label: "Gérer les paramètres du compte",
    manage_user_settings_description_label: "",
    disable_user_account_label: "Désactiver mon compte",
    disable_user_account_description_label: "Supprimer définitivement votre compte DocTraitant Pro.",
    contact_admin_support_description_label: "Veuillez contacter votre administrateur pour obtenir de l'aide.",
    //Planning
    //Support
    support_view_description_label: "Besoin d'aide ? Notre équipe est là pour vous assister.",
    //AssignRequestView
    assign_request_modal_label: "Transfert de la demande",
    assign_request_modal_description_label: "La demande sera directement envoyée au praticien(s) sélectionné(s)pour action",
    back_to_dashboard: "Retour au tableau de bord",
    //ErrorView
    error_modal_label: "Échec de l'opération",
    error_modal_description_label: "Nous avons rencontré un problème lors du traitement de votre demande. Veuillez réessayer plus tard.",
    //AdminBoard
    //Actions
    close_label: "Fermer",
    cancel_label: "Annuler",
    confirm_label: "Confirmer",
    ok_label: "Ok",
    accept_label: "Accepter",
    send_label: "Envoyer",
    refuse_label: "Refuser",
    edit_label: "Modifier",
    disable_label: "Désactiver",
    enable_label: "Activer",
    manage_label: "Gérer",
    save_label: "Enregistrer",
    change_label: "Changer",
    agree_label: "D'accord",
    previous_label: "Précédent",
    next_label: "Annuler",
    login_label: "Se connecter",
    register_label: "Inscription",
    plan_meeting_label: "Planifier un rendez-vous",
    more_details_label: "Plus de détails",
    forgot_password_label: "mot de passe oublié?",
    delete_label: "Supprimer",
    synchronize_label: "Synchroniser",
    //Loading and Errors
    loading_label: "Chargement en cours...",
    error_operation_label: "Échec de l'opération",
    //Inputs and Forms
    username_or_email_label: "Identifiant ou email",
    username_or_email_placeholder_label: "Votre identifiant / Email",
    otp_label: "Un code de vérification vous sera envoyé par email. Veuillez saisir ce code dans le champ ci-dessous pour confirmer votre identité",
    password_reset_label: "Créez un nouveau code",
    password_reset_placeholder_label: "Votre nouveau code",
    password_confirmation_reset_label: "Confirmez votre code",
    //Views Titles
    new_account_title_tab: "Création de compte - DocTraitant",
    login_title_tab: "Authentification - DocTraitant",
    account_title_tab: "Gestion du compte - DocTraitant",
    reset_account_title_tab: "Réinitialisation du compte - DocTraitant",
    welcome_title_tab: "Bienvenue sur DocTraitant",
    dashboard_title_tab: "Tableau de bord - DocTraitant Pro",
    planning_title_tab: "Plannification - DocTraitant Pro",
    support_title_tab: "Support technique - DocTraitant Pro",
    administration_title_tab: "Administration - DocTraitant Pro",
    control_center_title_tab: "Centre de contrôle - DocTraitant Pro",
};

export default strings;