import React, { useRef, useState, useEffect } from 'react';
import UserSearchBar, { UserSearchBarHandle } from './MultiselectView'; 
import strings from '../../assets/strings';
import ArrowOutwardSharpIcon from '@mui/icons-material/ArrowOutwardSharp';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';

interface AssignRequestViewProps {
    onCloseClick: () => void;
    onConfirmClick: () => void;
    handleEmailsSelectionChange: (emails: string[] | undefined) => void;
    isActionsDisabled: boolean;
    emails: string[] | undefined;
}

const AssignRequestView: React.FC<AssignRequestViewProps> = ({ onCloseClick, onConfirmClick, handleEmailsSelectionChange, isActionsDisabled, emails }) => {
    
    const [selectedItems, setSelectedItems] = useState<string[] | undefined>([]);

    const handleItemClick = (item: string) => {
        if (item.length > 0) {
            if (selectedItems && selectedItems.length < 20 && !selectedItems.includes(item)) {
                setSelectedItems([...(selectedItems || []), item]);
            }
            if (!selectedItems) {
                setSelectedItems([...(selectedItems || []), item]);
            }
        }
    };

    const handleDeleteItemClick = (item: string) => {
        if (selectedItems) {
            const updatedItems = selectedItems.filter((selectedItem) => selectedItem !== item);
            if (updatedItems.length == 0) {
                setSelectedItems(undefined);
            } else {
                setSelectedItems(updatedItems);
            }
        }
    };

    const handleClearItemsClick = () => {
        if (selectedItems) {
            setSelectedItems([]);
        }
    };

    useEffect(() => {
        clearSearchBar();
        handleEmailsSelectionChange(selectedItems);
    }, [selectedItems]);

    const searchBarRef = useRef<UserSearchBarHandle>(null);

    const clearSearchBar = () => {
        searchBarRef.current?.clearSearch();
    };

    return (
        <div onClick={clearSearchBar} className="w-full shadow-lg rounded-xl bg-white p-2 pt-4 pl-4 pr-4">
            <div className="flex justify-between items-center">
                <h3 className="flex items-center gap-x-2 font-bold text-[#12CBC4]">
                    <ArrowOutwardSharpIcon htmlColor="#12CBC4" fontSize="medium" />
                    {strings.assign_request_modal_label}
                </h3>
                <button
                    onClick={() => {
                        if (!isActionsDisabled) {
                            onCloseClick();
                        }
                    }}
                    type="button"
                    className={`flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 disabled:opacity-50 disabled:pointer-events-none ${isActionsDisabled ? 'disabled:opacity-50 disabled:pointer-events-none' : ''}`}
                    disabled={isActionsDisabled}>
                    <span className="sr-only">Close</span>
                    <CancelRoundedIcon htmlColor="#a5b1c2" fontSize="medium" />
                </button>
            </div>


            <div className="p-2 mt-1 text-left overflow-y-auto">
                <p className="text-gray-400 text-sm mb-1">
                    {strings.assign_request_modal_description_label}
                </p>

                <UserSearchBar ref={searchBarRef} placeholder={"Chercher un praticien"} selectedItem={emails} onSelectedItemsChange={handleItemClick} onClearItemsChange={handleClearItemsClick} />

                <div className="max-w-2xl mx-auto divide-y divide-gray-200 dark:divide-gray-700 mt-4 mb-4">
                    <div className="py-8 first:pt-0 last:pb-0">
                        <div className="flex gap-x-2">
                            <PersonPinCircleOutlinedIcon htmlColor="#a5b1c2" fontSize="medium" />
                            <div className="flex-1">
                                <h3 className="text-base font-semibold text-gray-400 dark:text-gray-200">
                                    Destinataire(s)
                                </h3>
                                <div className="mt-2 max-h-48 overflow-auto">
                                    {Array.isArray(selectedItems) && selectedItems.length > 0 ? (
                                        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-4 gap-y-2">
                                            {selectedItems.map((item) => (
                                                <span key={item} className="max-w-40 truncate whitespace-nowrap inline-block rounded-lg text-xs font-medium bg-blue-100 text-blue-800 border border-blue-400 pr-1">
                                                    <CloseRoundedIcon onClick={() => handleDeleteItemClick(item)} fontSize="small" className="m-1 m-1 cursor-pointer" />
                                                    {item}
                                                </span>

                                            ))}
                                        </div>
                                    ) : (
                                        <p className="mt-4 text-sm font-light italic text-gray-400">
                                            0 destinataires sélectionné(s)
                                        </p>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-6 mb-4 flex justify-end items-center gap-x-2">
                {isActionsDisabled && (
                    <div className="animate-spin inline-block w-9 h-9 border-[5px] border-current border-t-transparent text-[#12CBC4] rounded-full mr-2" role="status" aria-label="loading">
                        <span className="sr-only">Loading...</span>
                    </div>
                )}
                <p onClick={() => onCloseClick()} className={`py-2 px-3 inline-flex justify-center items-center gap-2 rounded-lg border cursor-pointer font-medium bg-white text-gray-700 shadow-sm align-middle transition-all text-sm ${isActionsDisabled ? 'opacity-50 pointer-events-none' : ''}`}>
                    <CloseRoundedIcon fontSize="medium" />
                    Annuler
                </p>
                <p onClick={() => onConfirmClick()} className={`py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg bg-[#12CBC4] border border-transparent text-white cursor-pointer ${isActionsDisabled ? 'opacity-50 pointer-events-none' : ''}`}>
                    <CheckRoundedIcon fontSize="medium" />
                    Valider
                </p>
            </div>
        </div>
    );
};

export default AssignRequestView;