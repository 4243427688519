import React, { useRef, useState, useEffect } from 'react';
import UserSearchBar, { UserSearchBarHandle } from './MultiselectView';
import strings from '../../assets/strings';
import ArrowOutwardSharpIcon from '@mui/icons-material/ArrowOutwardSharp';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';

interface CookiesUserRequestViewProps {
    onCloseClick: () => void;
    onConfirmClick: () => void;
    handleEmailsSelectionChange: (emails: string[] | undefined) => void;
    isActionsDisabled: boolean;
    emails: string[] | undefined;
}

const CookiesUserRequestView: React.FC<CookiesUserRequestViewProps> = ({ onCloseClick, onConfirmClick, handleEmailsSelectionChange, isActionsDisabled, emails }) => {

    const [selectedItems, setSelectedItems] = useState<string[] | undefined>([]);

    const handleItemClick = (item: string) => {
        if (item.length > 0) {
            if (selectedItems && selectedItems.length < 20 && !selectedItems.includes(item)) {
                setSelectedItems([...(selectedItems || []), item]);
            }
            if (!selectedItems) {
                setSelectedItems([...(selectedItems || []), item]);
            }
        }
    };

    const handleDeleteItemClick = (item: string) => {
        if (selectedItems) {
            const updatedItems = selectedItems.filter((selectedItem) => selectedItem !== item);
            if (updatedItems.length == 0) {
                setSelectedItems(undefined);
            } else {
                setSelectedItems(updatedItems);
            }
        }
    };

    const handleClearItemsClick = () => {
        if (selectedItems) {
            setSelectedItems([]);
        }
    };

    useEffect(() => {
        clearSearchBar();
        handleEmailsSelectionChange(selectedItems);
    }, [selectedItems]);

    const searchBarRef = useRef<UserSearchBarHandle>(null);

    const clearSearchBar = () => {
        searchBarRef.current?.clearSearch();
    };

    return (
        <div className="relative flex flex-col bg-white shadow-lg rounded-xl dark:bg-gray-800 w-9/12 p-4">
            <div className="flex justify-start items-center mb-4">
                <h3 className="flex items-center gap-x-2 font-bold text-[#12CBC4]">
                    {strings.cookies_popup_title_label}
                </h3>
            </div>
            <div className="text-center overflow-y-auto">
                <p className="text-gray-500 text-sm text-justify">
                    {strings.cookies_popup_description_label}
                </p>
               
                <div className="mt-2 flex justify-center gap-x-4">
                    <button
                        type="button"
                        className="mt-4 p-2 w-full tracking-wide gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-500 shadow-sm disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400"
                        onClick={() => onCloseClick()}
                    >
                        <p>
                            {strings.refuse_label}
                        </p>
                    </button>
                    <button
                        type="button"
                        className="mt-4 bg-[#12CBC4] text-gray-100 p-2 w-full rounded-lg tracking-wide
                                                    font-medium font-display focus:outline-none focus:shadow-outline
                                                    shadow-lg text-sm disabled:opacity-50 disabled:pointer-events-none"
                        onClick={() => onConfirmClick()}
                    >
                        <p className="text-white">
                            {strings.accept_label}
                        </p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CookiesUserRequestView;