import { createContext, useContext, useState } from "react";
import { RenderRoutes } from "../components/structure/RenderNavigation";
import { CookieConsent } from "../components/layouts/CookieConsent";
import { useLocation } from "react-router-dom";

const AuthContext = createContext();

export const AuthData = () => useContext(AuthContext);

async function loginUser(credentials, userType) {
  const endpoint =
    userType === "patient"
      ? "https://api.doctraitant.fr/api/user/authenticate-patient-signin"
      : "https://api.doctraitant.fr/api/user/authenticate-user-signin";

  return fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export const AuthWrapper = () => {
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    birthdate: "",
    userRights: "",
    city: "",
    zipcode: "",
    phone: "",
    email: "",
    token: "",
    isAuthenticated: false,
  });

  const location = useLocation();

  const pagesToShowCookieBanner = ["/", "/getMyDoctor", "/signin", "/register", "/connectme"];

  const login = async (email, password, userType) => {
    const response = await loginUser({ email, password }, userType);

    return new Promise((resolve, reject) => {
      if (response.code === 200) {
        localStorage.setItem("userId", response.results.data["_id"]);
        localStorage.setItem("userUUID", response.results.data["uuid"]);
        localStorage.setItem("firstname", response.results.data["firstname"]);
        localStorage.setItem("lastname", response.results.data["lastname"]);
        localStorage.setItem("userRights", response.results.data["userRights"]);
        localStorage.setItem("address", response.results.data["address"]);
        localStorage.setItem("city", response.results.data["city"]);
        localStorage.setItem("township", response.results.data["township"]);
        localStorage.setItem("zipcode", response.results.data["zipcode"]);
        localStorage.setItem("phone", response.results.data["phone"]);
        localStorage.setItem("email", response.results.data["email"]);
        localStorage.setItem(
          "supportsAssignments",
          response.results.data["supportsAssignments"]
        );
        localStorage.setItem(
          "isNotificationsEnabled",
          response.results.data["isNotificationsEnabled"]
        );
        localStorage.setItem("token", response.results.token);

        setUser({
          firstname: response.results.data["firstname"],
          lastname: response.results.data["lastname"],
          birthdate: response.results.data["birthdate"],
          userRights: response.results.data["userRights"],
          city: response.results.data["city"],
          zipcode: response.results.data["zipcode"],
          phone: response.results.data["phone"],
          email: response.results.data["email"],
          supportsAssignments: response.results.data["supportsAssignments"],
          isNotificationsEnabled: response.results.data["isNotificationsEnabled"],
          token: response.results["token"],
          isAuthenticated: localStorage.getItem("token").length >= 4,
        });

        resolve("success");
      } else {
        reject("Échec de l'authentification");
      }
    });
  };

  const logout = () => {
    localStorage.clear();
    setUser({ ...user, isAuthenticated: false });
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      <>
        <RenderRoutes />
        {pagesToShowCookieBanner.includes(location.pathname) ? <CookieConsent /> : null}
      </>
    </AuthContext.Provider>
  );
};
