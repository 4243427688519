import React, { useState, useEffect } from 'react';
import { DocRequestData } from './../../types/docRequestModel';
import leafImage from './../../images/leaf_logo.png';
import MeetingItem from './MeetingItem';
import { set } from 'date-fns';
// Icons imports
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import CalendarViewDayRoundedIcon from '@mui/icons-material/CalendarViewDayRounded';

interface SimpleCalendarProps {
    data: DocRequestData[];
    modalTrigger: (value: boolean) => void;
    onDataTrigged: (value: DocRequestData) => void;
    onDateTrigged: (value: Date) => void;
}

const daysInMonth = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate();
};

const generateCalendar = (year: number, month: number) => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const days = [];

    for (let i = 0; i < firstDay.getDay(); i++) {
        days.push(null);
    }

    for (let day = 1; day <= lastDay.getDate(); day++) {
        const date = new Date(year, month, day);
        days.push(date);
    }

    return days;
};

const SimpleCalendar: React.FC<SimpleCalendarProps> = ({ data, modalTrigger, onDataTrigged, onDateTrigged }) => {
    const [date, setDate] = useState(new Date());
    const [selectedDay, setSelectedDay] = useState<number | null>(null);
    const [currentDate, setCurrentDate] = useState<Date | null>(null);
    const [filtredDataByDate, setfiltredDataByDate] = useState<DocRequestData[]>([]);

    const [meetingToggle, setMettingToggle] = useState(false);
    const [dataToDisplay, setDataToDisplay] = useState<DocRequestData>();

    const closePatientModal = () => {
        setMettingToggle(!meetingToggle);
    };

    const meetingToggleBoolean = (item: DocRequestData) => {
        setMettingToggle(!meetingToggle);
        setDataToDisplay(item);
    };

    const handleModalTrigger = () => {
        modalTrigger(true);
    };

    const handleModal = () => {
        handleModalTrigger()
    };

    useEffect(() => {
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth();
        const year = today.getFullYear();

        setCurrentDate(today);
        setSelectedDay(day);
        handleDayClick(today);

        today.setHours(0, 0, 0, 0);

        const ye = today.getFullYear();
        const mo = (today.getMonth() + 1).toString().padStart(2, '0');
        const da = today.getDate().toString().padStart(2, '0');

        const targetDate = `${ye}-${mo}-${da}`;

        const filteredData = data.filter((item) => {
            if (item.meetingDateTime) {
                const meetingDate = new Date(item.meetingDateTime);
                const formattedDate = `${meetingDate.getFullYear()}-${(meetingDate.getMonth() + 1)
                    .toString()
                    .padStart(2, '0')}-${meetingDate.getDate().toString().padStart(2, '0')}`;
                return formattedDate === targetDate;
            }
            return false;
        });

        setfiltredDataByDate(filteredData);
        handleDayClick(today)
    }, [data]);

    const nextMonth = () => {
        setDate(new Date(date.getFullYear(), date.getMonth() + 1));
    };

    const prevMonth = () => {
        setDate(new Date(date.getFullYear(), date.getMonth() - 1));
    };

    const days = generateCalendar(date.getFullYear(), date.getMonth());

    const handleDayClick = (day: Date) => {
        onDateTrigged(day);
        const dayToBeSelect = day.getDate();
        setSelectedDay(dayToBeSelect);
        day.setHours(0, 0, 0, 0);

        const year = day.getFullYear();
        const month = (day.getMonth() + 1).toString().padStart(2, '0');
        const doo = day.getDate().toString().padStart(2, '0');

        const targetDate = `${year}-${month}-${doo}`;

        const filteredData = data.filter((item) => {
            if (item.meetingDateTime) {
                const meetingDate = new Date(item.meetingDateTime);
                const formattedDate = `${meetingDate.getFullYear()}-${(meetingDate.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}-${meetingDate.getDate().toString().padStart(2, "0")}`;
                return formattedDate === targetDate;
            }
            return false;
        });
        setfiltredDataByDate(filteredData);
    };

    const countAppointmentsForDate = (day: Date) => {
        if (day === null) {
            return 0;
        }

        day.setHours(0, 0, 0, 0);

        const year = day.getFullYear();
        const month = (day.getMonth() + 1).toString().padStart(2, '0');
        const doo = day.getDate().toString().padStart(2, '0');

        const targetDate = `${year}-${month}-${doo}`;

        const appointmentsForDate = data.filter((item) => {
            if (item.meetingDateTime) {
                const meetingDate = new Date(item.meetingDateTime);
                const formattedMeetingDate = meetingDate.toISOString().slice(0, 10);
                return formattedMeetingDate === targetDate;
            }
            return false;
        });

        return appointmentsForDate.length;
    };

    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    return (
        <>
            <div className="md:col-span-3">
                <div className="flex flex-col items-center">
                    <div className="flex justify-between w-full p-3">
                        <h2 className="font-semibold text-[#172554]/70">
                            {date.toLocaleString('default', { month: 'long' }).charAt(0).toUpperCase() +
                                date.toLocaleString('default', { month: 'long' }).slice(1)}{' '}
                            {date.getFullYear()}
                        </h2>
                        <div className="space-x-4 text-sky-700/90">
                            <button onClick={prevMonth}><ArrowCircleLeftRoundedIcon fontSize="large" /></button>
                            <button onClick={nextMonth}><ArrowCircleRightRoundedIcon fontSize="large" /></button>
                        </div>
                    </div>
                    <div className="grid grid-cols-7 gap-2 gap-x-2 sm:gap-x-10 lg:gap-x-10">
                        {['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'].map((day) => (
                            <div key={day} className="text-center">
                                {day}
                            </div>
                        ))}
                        {days.map((day, index) => (
                            <div
                                key={day ? day.toString() : `empty-${index}`}
                                className={`text-center cursor-pointer ${day !== null && selectedDay === day.getDate()
                                    ? 'text-white bg-sky-700 rounded-lg'
                                    : ''
                                    }
                                ${day !== null && countAppointmentsForDate(day) == 0
                                        ? ''
                                        : 'text-black font-semibold'
                                    }`
                                }
                                onClick={() => day !== null && handleDayClick(day)}
                            >
                                {day ? (
                                    countAppointmentsForDate(day) === 0 ? (
                                        <div className="relative">
                                            <div className="w-9 h-9 rounded-lg relative flex items-center justify-center">
                                                <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 w-3 h-3 rounded-full"></div>
                                                <span className="">{day.getDate()}</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="relative">
                                            <div className="w-9 h-9 rounded-lg bg-[#12CBC4]/20 relative flex items-center justify-center">
                                                <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-[#12CBC4]/70 w-3 h-3 rounded-full"></div>
                                                <span className="text-black">{day.getDate()}</span>
                                            </div>
                                        </div>
                                    )
                                ) : null}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SimpleCalendar;