import React, { useState, useEffect } from 'react';
import { BrowserRouter, useNavigate } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper"
// Icons imports
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import appLogo from './../../images/app_logo.png';

interface HeaderProps {
    toggle: boolean;
    toggleBoolean: () => void;
    toggleAccountView: () => void;
    modalLogoutStateChanger: () => void;
}

const Header: React.FC<HeaderProps> = ({ toggle, toggleAccountView, toggleBoolean, modalLogoutStateChanger }: HeaderProps) => {

    return (
        <nav className="navbar lg:pl-72 bg-white border-b border-gray-200">
            <div className="mx-auto">
                <div className="relative flex h-16 items-center justify-between">

                    {/* -- Bouton burger (mobile) -- */}
                    <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                        <button
                            type="button"
                            className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                            onClick={toggleBoolean}
                        >
                            <MenuRoundedIcon htmlColor="#22a6b3" fontSize="large" />
                        </button>
                    </div>

                    {/* -- Conteneur central : on place ici le logo, visible seulement en mobile -- */}
                    <div className="flex flex-1 items-center justify-center">
                        <div className="block lg:hidden">
                            <img
                                src={appLogo}
                                alt="Logo de l'application"
                                className="h-4"
                            />
                        </div>
                    </div>

                    {/* -- Boutons/éléments à droite -- */}
                    <div className="absolute inset-y-0 right-0 flex items-center">
                        <div onClick={modalLogoutStateChanger} className="block sm:hidden cursor-pointer">
                            <svg viewBox="0 0 24 24" fill="none" className="w-7 h-7 mr-2" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Iconly/Curved/Logout"> <g id="Logout"> <path id="Stroke 1" d="M21.791 12.1208H9.75" stroke="#FC5C65" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path id="Stroke 3" d="M18.8643 9.20483L21.7923 12.1208L18.8643 15.0368" stroke="#FC5C65" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path id="Stroke 4" d="M16.3597 7.63C16.0297 4.05 14.6897 2.75 9.35974 2.75C2.25874 2.75 2.25874 5.06 2.25874 12C2.25874 18.94 2.25874 21.25 9.35974 21.25C14.6897 21.25 16.0297 19.95 16.3597 16.37" stroke="#FC5C65" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g> </g> </g></svg>
                        </div>

                        <button
                            type="button"
                            className="py-1 px-2 inline-flex items-center gap-x-2 mr-2 text-sm font-semibold 
                     rounded-full border border-transparent bg-red-100 text-red-800 
                     disabled:opacity-50 disabled:pointer-events-none hidden sm:block"
                            onClick={modalLogoutStateChanger}
                        >
                            <p className="font-regular flex items-center text-sm text-red-500">
                                Déconnexion
                            </p>
                        </button>

                        <button
                            type="button"
                            className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                            onClick={toggleAccountView}
                        >
                            <AccountCircleIcon htmlColor="#22a6b3" fontSize="large" />
                        </button>
                    </div>

                </div>
            </div>
        </nav>

        // <nav className="navbar lg:pl-72 bg-white border-b border-gray-200">
        //     <div className="mx-auto">

        //         <div className="relative flex h-16 items-center justify-between">
        //             <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
        //                 <button type="button" className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400" aria-controls="mobile-menu"
        //                     aria-expanded="false"
        //                     onClick={toggleBoolean}>
        //                     <MenuRoundedIcon htmlColor="#22a6b3" fontSize="large" />
        //                 </button>
        //             </div>
        //             <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
        //                 <div className="hidden sm:ml-6 sm:block">
        //                     <div className="flex space-x-4">
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className="absolute inset-y-0 right-0 flex items-center">
        //                 <div onClick={modalLogoutStateChanger} className="block sm:hidden cursor-pointer">
        //                     <svg viewBox="0 0 24 24" fill="none" className="w-7 h-7 mr-2" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Iconly/Curved/Logout"> <g id="Logout"> <path id="Stroke 1" d="M21.791 12.1208H9.75" stroke="#FC5C65" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path id="Stroke 3" d="M18.8643 9.20483L21.7923 12.1208L18.8643 15.0368" stroke="#FC5C65" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path id="Stroke 4" d="M16.3597 7.63C16.0297 4.05 14.6897 2.75 9.35974 2.75C2.25874 2.75 2.25874 5.06 2.25874 12C2.25874 18.94 2.25874 21.25 9.35974 21.25C14.6897 21.25 16.0297 19.95 16.3597 16.37" stroke="#FC5C65" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g> </g> </g></svg>
        //                 </div>
        //                 <button
        //                     type="button"
        //                     className="py-1 px-2 inline-flex items-center gap-x-2 mr-2 text-sm font-semibold rounded-full border border-transparent bg-red-100 text-red-800 disabled:opacity-50 disabled:pointer-events-none hidden sm:block"
        //                     onClick={modalLogoutStateChanger}
        //                 >
        //                     <p className="font-regular flex items-center text-sm text-red-500">
        //                         Déconnexion
        //                     </p>
        //                 </button>
        //                 <button type="button" className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400" aria-controls="mobile-menu"
        //                     aria-expanded="false"
        //                     onClick={toggleAccountView}>
        //                     <AccountCircleIcon htmlColor="#22a6b3" fontSize="large" />
        //                 </button>
        //             </div>
        //         </div>
        //     </div>
        // </nav>
    )
}

export default Header