import React, { useState, useEffect } from 'react';
import { BrowserRouter, useNavigate } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper"
import FloatingLabelInput from "./FloatingLabelInput"
import FloatingLabelTextarea from "./FloatingLabelTextarea"
import strings from '../../assets/strings';
// Icons imports
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import attachedDocumentIcon from './../../images/attached_document_icon.png';
import LayoutHeader from '../Headers/LayoutHeader';

const TeamSupportLayout: React.FC = () => {

    const [supportTitleRequest, setSupportTitleRequest] = useState<string>('');
    const [isActionsDisabled, setIsActionsDisabled] = useState(false);

    const handleSupportTitleRequestChange = (value: string) => {
        setSupportTitleRequest(value);
    };

    useEffect(() => {
        document.title = strings.support_title_tab;
    }, []);

    return (
        <>
            <LayoutHeader
                icon={
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 text-[#22a6b3]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.712 4.33a9.027 9.027 0 0 1 1.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 0 0-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 0 1 0 9.424m-4.138-5.976a3.736 3.736 0 0 0-.88-1.388 3.737 3.737 0 0 0-1.388-.88m2.268 2.268a3.765 3.765 0 0 1 0 2.528m-2.268-4.796a3.765 3.765 0 0 0-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 0 1-1.388.88m2.268-2.268 4.138 3.448m0 0a9.027 9.027 0 0 1-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0-3.448-4.138m3.448 4.138a9.014 9.014 0 0 1-9.424 0m5.976-4.138a3.765 3.765 0 0 1-2.528 0m0 0a3.736 3.736 0 0 1-1.388-.88 3.737 3.737 0 0 1-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 0 1-1.652-1.306 9.027 9.027 0 0 1-1.306-1.652m0 0 4.138-3.448M4.33 16.712a9.014 9.014 0 0 1 0-9.424m4.138 5.976a3.765 3.765 0 0 1 0-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 0 1 1.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 0 0-1.652 1.306A9.025 9.025 0 0 0 4.33 7.288" />
                    </svg>
                }
                title="Assistance utilisateurs"
                subtitle="Contacter le service de support utilisateurs"
            />

            <form>
                <div className="space-y-4">
                    <div className="">
                        <p className="mt-6 text-sm font-normal leading-6 text-gray-500 text-start">Besoin d'aide ? Notre équipe est là pour vous assister.</p>
                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                            <div className="col-span-full">

                                <FloatingLabelInput id="request-title" name="request-title" type="text" placeholder="Objet de la demande" label="Objet de la demande" isDisabled={isActionsDisabled} onValueChange={handleSupportTitleRequestChange} initialValue={""} />
                            </div>

                            <div className="col-span-full">
                                <FloatingLabelTextarea id="request-body" name="request-body" placeholder="Décrivez votre demande..." label="Décrivez votre demande..." />
                            </div>

                            <div className="col-span-full">
                                <div className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <img className="mx-auto h-12 w-12 text-gray-300" src={attachedDocumentIcon} alt="Image Description" />
                                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                            <label className="relative cursor-pointer rounded-md bg-white font-semibold cursor-pointer text-[#12CBC4]">
                                                <span>Téléverser un fichier</span>
                                                <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                                            </label>
                                            <p className="pl-1">ou glisser-déposer</p>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PDF, PNG, JPG jusqu'à 10Mb</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Button --> */}
                <div className="mt-6 mb-4 flex justify-end items-center gap-x-2">
                    {isActionsDisabled && (
                        <div className="animate-spin inline-block w-9 h-9 border-[5px] border-current border-t-transparent text-[#12CBC4] rounded-full mr-2" role="status" aria-label="loading">
                            <span className="sr-only">Loading...</span>
                        </div>
                    )}
                    <p className={`py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg bg-[#12CBC4] border border-transparent text-white ${isActionsDisabled ? 'opacity-50 pointer-events-none' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                        </svg>
                        Envoyer
                    </p>
                </div>
                {/* <!-- End Buttons --> */}
            </form>
        </>
    )
}

export default TeamSupportLayout;