import React, { ReactNode } from 'react';

interface CustomContentDisplayerProps {
  children: ReactNode;
}

const CustomContentDisplayer: React.FC<CustomContentDisplayerProps> = ({ children }) => {
    return (
        <div className="text-gray-600 body-font">
            {children}
        </div>
    );
};

export default CustomContentDisplayer;