import React from 'react';
import strings from '../../assets/strings';
import { useNavigate } from "react-router-dom";
// Icons imports
import appLogo from './../../images/app_logo.png';
import notFoundImage from './../../images/not_found_image.png';

export const NotFound = () => {
    const navigate = useNavigate();

    function handleNavigationToSignin() {
        // Handle navigation to signin from recover page
        // Then trigger navigation.
        navigate("/connectme");
    }

    return (
        <html className="h-full">
            <body className="flex h-full">
                <div className="max-w-[50rem] flex flex-col items-center mx-auto w-full h-full">
                    <header className="mb-auto flex justify-center z-50 w-full py-4">
                        <nav className="px-4 sm:px-6 lg:px-8" aria-label="Global">
                            <img
                                src={appLogo}
                                alt="Logo de l'application"
                                className="h-10 rounded-md"
                            />
                        </nav>
                    </header>
                    <img
                        src={notFoundImage}
                        alt="Logo de l'application"
                        className="h-60 rounded-md opacity-80"
                    />
                    <div className="text-center py-2 px-4">
                        <h1 className="block text-6xl font-bold text-[#22a6b3] sm:text-6xl dark:text-white">404</h1>
                        <h1 className="block text-2xl font-bold text-white"></h1>
                        <p className="mt-3 text-gray-600 dark:text-gray-400">{strings.not_found_first_description_label}</p>
                        <p className="text-gray-600 dark:text-gray-400">{strings.not_found_second_description_label}</p>
                        <div className="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3">
                            <a onClick={handleNavigationToSignin} className="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-[#22a6b3] text-white disabled:opacity-50 disabled:pointer-events-none cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M12 3.75a6.715 6.715 0 0 0-3.722 1.118.75.75 0 1 1-.828-1.25 8.25 8.25 0 0 1 12.8 6.883c0 3.014-.574 5.897-1.62 8.543a.75.75 0 0 1-1.395-.551A21.69 21.69 0 0 0 18.75 10.5 6.75 6.75 0 0 0 12 3.75ZM6.157 5.739a.75.75 0 0 1 .21 1.04A6.715 6.715 0 0 0 5.25 10.5c0 1.613-.463 3.12-1.265 4.393a.75.75 0 0 1-1.27-.8A6.715 6.715 0 0 0 3.75 10.5c0-1.68.503-3.246 1.367-4.55a.75.75 0 0 1 1.04-.211ZM12 7.5a3 3 0 0 0-3 3c0 3.1-1.176 5.927-3.105 8.056a.75.75 0 1 1-1.112-1.008A10.459 10.459 0 0 0 7.5 10.5a4.5 4.5 0 1 1 9 0c0 .547-.022 1.09-.067 1.626a.75.75 0 0 1-1.495-.123c.041-.495.062-.996.062-1.503a3 3 0 0 0-3-3Zm0 2.25a.75.75 0 0 1 .75.75c0 3.908-1.424 7.485-3.781 10.238a.75.75 0 0 1-1.14-.975A14.19 14.19 0 0 0 11.25 10.5a.75.75 0 0 1 .75-.75Zm3.239 5.183a.75.75 0 0 1 .515.927 19.417 19.417 0 0 1-2.585 5.544.75.75 0 0 1-1.243-.84 17.915 17.915 0 0 0 2.386-5.116.75.75 0 0 1 .927-.515Z" clipRule="evenodd" />
                                </svg>
                                {strings.please_connect_to_your_account_label}
                            </a>
                            <a className="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-[#22a6b3] disabled:opacity-50 disabled:pointer-events-none cursor-pointer">
                                {strings.contact_admin_label}
                            </a>
                        </div>
                    </div>
                </div>
            </body>
        </html>
    )
}