import React from 'react';

interface ModalProps {
  open: boolean;
  title: string;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ open, title, onClose, children }: ModalProps) => {
  return (
    <div
      className={`
        fixed inset-0 -top-10 -bottom-10 flex justify-center items-center transition-colors opacity-100 z-[60]
        ${open ? "visible bg-black/20" : "invisible"}
      `}
    >
      {/* modal */}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`
        p-2 transition-all flex justify-center items-center
        ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
        `}
      >
        {children}
      </div>
    </div>
  )
}

export default Modal