import React, { useState, useEffect, useRef } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import FilterCheck from './FilterCheck';
import { BooleanFiltersData } from '../../types/booleanFilters';
import SeatCounter from './SeatCounter';

interface FilterRequestViewProps {
    onCloseClick: () => void;
    selectOrResetFilters: () => void;
    handleTypeSelectionChange: (newSelection: BooleanFiltersData[]) => void;
    handleStateSelectionChange: (newSelection: BooleanFiltersData[]) => void;
    handleSituationSelectionChange: (newSelection: BooleanFiltersData[]) => void;
    isActionsDisabled: boolean;
    isResetOrSelectStatusFilters: boolean;
    selectedTypeItems: BooleanFiltersData[];
    selectedStateItems: BooleanFiltersData[];
    selectedSituationItems: BooleanFiltersData[];
}

const FilterRequestView: React.FC<FilterRequestViewProps> = ({ onCloseClick, selectOrResetFilters, handleTypeSelectionChange, handleStateSelectionChange, handleSituationSelectionChange, selectedTypeItems, selectedStateItems, selectedSituationItems, isActionsDisabled, isResetOrSelectStatusFilters }) => {
    
    const [minValue, setMinValuet] = useState<number>(17);
    const [maxValue, setMaxValuet] = useState<number>(100);

    const handleMinChange = (newCount: number) => {
        setMinValuet(newCount);
    };
    
    const handleMaxChange = (newCount: number) => {
        setMaxValuet(newCount);
    };
    
    return (
        <div className="w-full shadow-lg rounded-xl bg-white p-2 pt-4 pl-4 pr-4">
            <div className="flex justify-between items-center">
                <h3 className="flex items-center gap-x-2 font-bold text-[#12CBC4]">
                    <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M3 6h18" /><path d="M7 12h10" /><path d="M10 18h4" /></svg>
                    Filtrer les demandes
                </h3>
                <button
                    onClick={() => {
                        if (!isActionsDisabled) {
                            onCloseClick();
                        }
                    }}
                    type="button"
                    className={`flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 disabled:opacity-50 disabled:pointer-events-none ${isActionsDisabled ? 'disabled:opacity-50 disabled:pointer-events-none' : ''}`}
                    disabled={isActionsDisabled}>
                    <span className="sr-only">Close</span>
                    <CancelRoundedIcon htmlColor="#a5b1c2" fontSize="medium" />
                </button>
            </div>

            <div className="bg-white grid grid-cols-1 gap-4 lg:col-span-3 ">
                <div className="p-2">
                    <div className="relative">
                        <div className="relative flex items-center justify-between mt-5 pr-4">
                            <div className="absolute inset-y-0 right-0 flex items-center">
                                <div className="relative flex flex-row">

                                    <p
                                        onClick={() => {
                                            selectOrResetFilters()
                                        }}
                                        className="flex items-center gap-x-1 text-xs font-normal text-slate-500 cursor-pointer">
                                        {isResetOrSelectStatusFilters ? (
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-slate-500">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                </svg>
                                                Réinitialiser
                                            </>
                                        ) : (
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-slate-500">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                </svg>
                                                Sélectionner tout
                                            </>
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="relative sm:overflow-hidden">
                            <div>
                                <div className="mt-6">
                                    <FilterCheck filterLabel={'Par Demandes'} dataOptions={selectedTypeItems} onSelectionChange={handleTypeSelectionChange} />
                                </div>

                                <div className="mt-4">
                                    <FilterCheck filterLabel={'Par Criticité'} dataOptions={selectedStateItems} onSelectionChange={handleStateSelectionChange} />
                                </div>

                                <div className="mt-4">
                                    <FilterCheck filterLabel={'Par Situations'} dataOptions={selectedSituationItems} onSelectionChange={handleSituationSelectionChange} />
                                </div>
                                <div className="mt-4">
                                    <div className="block w-full flex space-x-6 overflow-x-auto">
                                        <div>
                                            <span className="flex items-center text-sm text-gray-500">
                                                Par Âge
                                            </span>
                                            <div className="flex items-center text-sm text-gray-600 dark:text-gray-400 mt-3 mb-3">
                                                <div className="grid sm:flex gap-3 items-center">
                                                    <SeatCounter label={"Âge minimal"} value={minValue} onChange={handleMinChange} />
                                                    <SeatCounter label={"Âge maximal"} value={maxValue} onChange={handleMaxChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-6 mb-4 flex justify-end items-center gap-x-2">
                {isActionsDisabled && (
                    <div className="animate-spin inline-block w-9 h-9 border-[5px] border-current border-t-transparent text-[#12CBC4] rounded-full mr-2" role="status" aria-label="loading">
                        <span className="sr-only">Loading...</span>
                    </div>
                )}
                <p onClick={() => onCloseClick()} className={`py-2 px-3 inline-flex justify-center items-center gap-2 rounded-lg border cursor-pointer font-medium bg-white text-gray-700 shadow-sm align-middle transition-all text-sm ${isActionsDisabled ? 'opacity-50 pointer-events-none' : ''}`}>
                    <CloseRoundedIcon fontSize="medium" />
                    Annuler
                </p>
                <p onClick={() => onCloseClick()} className={`py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg bg-[#12CBC4] border border-transparent text-white cursor-pointer ${isActionsDisabled ? 'opacity-50 pointer-events-none' : ''}`}>
                    <CheckRoundedIcon fontSize="medium" />
                    Valider
                </p>
            </div>
        </div>
    );
};

export default FilterRequestView;