import React from 'react';
// Imports icons
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

interface ToastProps {
  type: string;
  message: string;
}

const Toast: React.FC<ToastProps> = ({ type, message }) => {
  const getTitleType = () => {
    switch (type) {
      case 'success':
        return "Réussi";
      case 'warning':
        return "Attention";
      case 'error':
        return "Oups";
      default:
        return null;
    }
  };
  const getBorderColor = () => {
    switch (type) {
      case 'success':
        return "border-t-[#2ed573]";
      case 'warning':
        return "border-t-[#ff7f50]";
      case 'error':
        return "border-t-[#ff7979]";
      default:
        return null;
    }
  };
  const getIcon = () => {
    switch (type) {
      case 'success':
        return <span className="text-[#2ed573]"><CheckCircleOutlinedIcon fontSize="large" /></span>;
      case 'warning':
        return <span className="text-[#ff7f50]"><WarningRoundedIcon fontSize="large" /></span>;
      case 'error':
        return <span className="text-[#ff7979]"><ErrorRoundedIcon fontSize="large" /></span>;
      default:
        return null;
    }
  };
  return (
    <div className={`fixed top-10 right-4 p-2 pr-6 rounded-sm shadow-lg bg-white border-t-4 ${getBorderColor()}`}>
      <div className="flex items-center gap-x-2">
        {getIcon()}
        <div className="text-center text-start">
          <p className="inline-flex items-center text-base text-black tracking-wider font-bold">
            <span>{getTitleType()}</span>
          </p>
          <p className="mt-1 text-[#22a6b3]/[.6] text-sm font-normal text-gray-500">
            {message}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Toast