import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FloatingLabelInput from "./../layouts/FloatingLabelInput";
import FloatingDateInput from "./../layouts/FloatingDateInput";
import FloatingSelect from "./../layouts/FloatingSelect";
import Modal from './../layouts/Modal';
import Toast from './../layouts/Toast';
import strings from '../../assets/strings';

// Icons imports
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import TaskIcon from '@mui/icons-material/Task';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { arePinFieldsValid, isValidEmail } from "../../utils/DataChecker";
import FloatingLabelSelectInputField from "../Inputs/FloatingLabelSelectInputField";
import FloatingLabelInputField from "../Inputs/FloatingLabelInputField";

export const Register = () => {
    const [isActionsDisabled, setIsActionsDisabled] = useState(false);
    const [isFormNotReady, setIsFormNotReady] = useState(true);
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const navigate = useNavigate();

    const [firstnameValue, setFirstnameValue] = useState<string>('');
    const [lastnameValue, setLastnameValue] = useState<string>('');
    const [birthdateValue, setBirthdateValue] = useState<string>('');
    const [genderValue, setGenderValue] = useState<string>('');
    const [emailValue, setEmailValue] = useState<string>('');
    const [phoneValue, setPhoneValue] = useState<string>('');
    const [adresseValue, setAdresseValue] = useState<string>('');
    const [cityValue, setCityValue] = useState<string>('');
    const [townshipValue, setTownshipValue] = useState<string>('');
    const [communeValue, setCommuneValue] = useState<string>('');
    const [zipcodeValue, setZipcodeValue] = useState<string>('');
    const [pinAuthValue, setPinAuthValue] = useState<string>('');
    const [pinAuthConfirmationValue, setPinAuthConfirmationValue] = useState<string>('');

    const handleFirstnameChange = (value: string) => {
        setFirstnameValue(value);
    };
    const handleFirstnameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstnameValue(event.target.value);
    };

    const handleLastnameChange = (value: string) => {
        setLastnameValue(value);
    };
    const handleLastnameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastnameValue(event.target.value);
    };

    const handleBirthdateChange = (value: string) => {
        setBirthdateValue(value);
    };

    const handleEmailChange = (value: string) => {
        setEmailValue(value);
    };
    const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailValue(event.target.value);
    };

    const handlePhoneChange = (value: string) => {
        setPhoneValue(value);
    };
    const handlePhoneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneValue(event.target.value);
    };

    const handleAdresseChange = (value: string) => {
        setAdresseValue(value);
    };
    const handleAdresseInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdresseValue(event.target.value);
    };

    const handleCityChange = (value: string) => {
        setCityValue(value);
    };
    const handleCityInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCityValue(event.target.value);
    };

    const handleTownshipChange = (value: string) => {
        setTownshipValue(value);
    };
    const handleTownshipInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTownshipValue(event.target.value);
    };

    const handleCommuneChange = (value: string) => {
        setCommuneValue(value);
    };

    const handleZipcodeChange = (value: string) => {
        setZipcodeValue(value);
    };
    const handleZipcodeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setZipcodeValue(event.target.value);
    };

    const handlePinAuthChange = (value: string) => {
        setPinAuthValue(value);
    };
    const handlePinAuthInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPinAuthValue(event.target.value);
    };

    const handlePinAuthConfirmationChange = (value: string) => {
        setPinAuthConfirmationValue(value);
    };
    const handlePinAuthConfirmationInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPinAuthConfirmationValue(event.target.value);
    };

    const handleGenderChange = (value: string) => {
        setGenderValue(value);
    };
    const handleGenderInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setGenderValue(event.target.value);
    };

    const options = [
        { value: '', label: 'Sélectionnez votre sexe' },
        { value: 'F', label: 'Féminin' },
        { value: 'M', label: 'Masculin' },
        { value: 'O', label: 'Autre' },
    ];

    function handleNavigationToSignin() {
        // Handle navigation to signin from registration page
        // Then trigger navigation.
        navigate("/signin", { replace: true });
    }

    // Toast
    const [toastType, setToastType] = useState<string>('');
    const [toastMessage, setToastMessage] = useState<string>('');
    const [showToast, setShowToast] = useState(false);
    const handleShowToast = (type: 'success' | 'warning' | 'error', message: string) => {
        setToastType(type);
        setToastMessage(message);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
            setToastType('');
            setToastMessage('');
            setIsActionsDisabled(false);
        }, 2000);
    };

    useEffect(() => {
        document.title = strings.new_account_title_tab;
    }, []);

    useEffect(() => {
        const requestBody = {
            firstname: firstnameValue,
            lastname: lastnameValue,
            gender: genderValue,
            address: adresseValue,
            zipcode: zipcodeValue,
            phone: phoneValue,
            email: emailValue,
            birthdate: birthdateValue,
            city: cityValue,
            township: townshipValue,
            password: pinAuthValue
        };

        // Check if there is an empty field
        const isAnyFieldEmpty = Object.values(requestBody).some(value => value === '' || value === null || value === undefined);

        // if there is an empty field or pins are differents, block register button
        if (isAnyFieldEmpty || !arePinFieldsValid(requestBody.password, pinAuthConfirmationValue) || !isValidEmail(requestBody.email)) {
            setIsFormNotReady(true)
        } else {
            setIsFormNotReady(false)
        }
    }, [firstnameValue, lastnameValue, birthdateValue, genderValue, phoneValue, emailValue, adresseValue, cityValue, townshipValue, communeValue, zipcodeValue, pinAuthValue, pinAuthConfirmationValue])

    const registerUser = async () => {
        setIsActionsDisabled(true)
        setIsFormNotReady(true)
        try {
            const requestBody = {
                firstname: firstnameValue,
                lastname: lastnameValue,
                userRights: "Medecin",
                gender: genderValue,
                isUserFromMobile: true,
                address: adresseValue,
                zipcode: zipcodeValue,
                phone: phoneValue,
                email: emailValue,
                birthdate: birthdateValue,
                city: cityValue,
                township: townshipValue,
                password: pinAuthValue
            };

            const response = await fetch(
                `https://api.doctraitant.fr/api/user/create-user-request`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (response.ok) {
                console.log('Inscription réussie');
                setModalOpen(true)
            } else {
                console.error('Échec de linscription');
                setIsActionsDisabled(false)
                setIsFormNotReady(false)
                handleShowToast("error", "Oups ! erreur lors l'inscription")
            }
        } catch (error) {
            console.log('Erreur lors de linscription :', error);
            throw error;
        }
    };

    return (
        <div className="pt-10 bg-slate-50 flex-1 overflow-y-auto p-5">
            <form>
                <div className="space-y-4">
                    <div className="">
                        <div>
                            <label className="block text-[#22a6b3] text-sm font-semibold mb-1">
                                <h6 className="text-2xl text-[#22a6b3] font-display font-semibold text-left
                xl:text-bold flex items-center">
                                    <span>
                                        <DriveFileRenameOutlineRoundedIcon htmlColor="#22a6b3" fontSize="large" />
                                    </span>
                                    {strings.create_account_label}
                                </h6>
                            </label>
                        </div>
                        <p className="mt-4 ml-1 text-sm leading-6 text-gray-500">{strings.create_account_description_label}</p>
                        <div className="mt-4 ml-1 text-sm font-display font-semibold text-gray-700">
                            Vous avez déja un compte? <a className="cursor-pointer text-[#12CBC4]"
                                onClick={handleNavigationToSignin}
                            > {strings.please_connect_label}</a>
                        </div>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-8">
                                <h3 className="flex items-center gap-x-2 font-medium text-base text-[#2f3542]/90">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg>
                                    Informations personnelles
                                </h3>
                                <p className="mt-2 text-gray-600 text-sm font-light text-start">
                                    Partagez vos détails personnels pour personnaliser votre expérience et nous permettre de mieux vous connaître.
                                </p>
                            </div>
                            <div className="sm:col-span-3">
                                <FloatingLabelInputField
                                    id="createFirstnameField"
                                    type="text"
                                    name="firstnameField"
                                    placeholder="Prénom"
                                    value={firstnameValue}
                                    onChange={handleFirstnameInputChange}
                                    label="Prénom"
                                    disabled={isActionsDisabled}
                                />
                            </div>
                            <div className="sm:col-span-3">
                                <FloatingLabelInputField
                                    id="readLastnameField"
                                    type="text"
                                    name="lastnameField"
                                    placeholder="Nom de famille"
                                    value={lastnameValue}
                                    onChange={handleLastnameInputChange}
                                    label="Nom de famille"
                                    disabled={isActionsDisabled}
                                />
                            </div>
                            <div className="sm:col-span-3">
                                <FloatingLabelSelectInputField
                                    id="createGenderField"
                                    label="Genre"
                                    options={options}
                                    onChange={handleGenderInputChange}
                                    value={genderValue}
                                    disabled={isActionsDisabled}
                                    className="border-red-600"
                                />
                            </div>
                            <div className="sm:col-span-3 bg-white rounded">
                                <FloatingDateInput id="birthdate" name="birthdate" type="date" placeholder="__ / __ / ____" label="Date de naissance" onValueChange={handleBirthdateChange} isDisabled={isActionsDisabled} initialValue={""} />
                            </div>
                            <div className="sm:col-span-8">
                                <h3 className="flex items-center gap-x-2 font-medium text-base text-[#2f3542]/90">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                                    </svg>
                                    Informations professionnelles & coordonnées
                                </h3>
                                <p className="mt-2 text-gray-600 text-sm font-light text-start">
                                    Fournissez des informations professionnelles et des coordonnées pour créer une connexion plus étroite et faciliter la communication avec vous.
                                </p>
                            </div>
                            <div className="sm:col-span-3">
                                <FloatingLabelInputField
                                    id="createEmailField"
                                    type="email"
                                    name="emailField"
                                    placeholder="Adresse email professionnelle"
                                    value={emailValue}
                                    onChange={handleEmailInputChange}
                                    label="Adresse email professionnelle"
                                    disabled={isActionsDisabled}
                                />
                            </div>
                            <div className="sm:col-span-3">
                                <FloatingLabelInputField
                                    id="createPhoneField"
                                    type="number"
                                    name="phoneField"
                                    placeholder="N° de téléphone professionnel"
                                    value={phoneValue}
                                    onChange={handlePhoneInputChange}
                                    label="N° de téléphone professionnel"
                                    disabled={isActionsDisabled}
                                />
                            </div>
                            <div className="col-span-full">
                                <FloatingLabelInputField
                                    id="createAdresseField"
                                    type="text"
                                    name="adresseField"
                                    placeholder="Adresse"
                                    value={adresseValue}
                                    onChange={handleAdresseInputChange}
                                    label="Adresse"
                                    disabled={isActionsDisabled}
                                />
                            </div>
                            <div className="sm:col-span-2 sm:col-start-1">
                                <FloatingLabelInputField
                                    id="createCityField"
                                    type="text"
                                    name="cityField"
                                    placeholder="Ville"
                                    value={cityValue}
                                    onChange={handleCityInputChange}
                                    label="Ville"
                                    disabled={isActionsDisabled}
                                />
                            </div>
                            <div className="sm:col-span-2">
                                <FloatingLabelInputField
                                    id="createTownshipField"
                                    type="text"
                                    name="TownshipField"
                                    placeholder="Commune"
                                    value={townshipValue}
                                    onChange={handleTownshipInputChange}
                                    label="Commune"
                                    disabled={isActionsDisabled}
                                />
                            </div>
                            <div className="sm:col-span-2">
                                <FloatingLabelInputField
                                    id="createZipcodeField"
                                    type="text"
                                    name="zipcodeField"
                                    placeholder="Code Postal"
                                    value={zipcodeValue}
                                    onChange={handleZipcodeInputChange}
                                    label="Code Postal"
                                    disabled={isActionsDisabled}
                                />
                            </div>
                            <div className="sm:col-span-8">
                                <h3 className="flex items-center gap-x-2 font-medium text-base text-[#2f3542]/90">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
                                    </svg>
                                    Confidentialité et Sécurité
                                </h3>
                                <p className="mt-2 text-gray-600 text-sm font-light text-start">
                                    Établissez votre première ligne de défense en créant un mot de passe ou un code d'accès unique et mémorable.
                                </p>
                            </div>
                            <div className="sm:col-span-3">
                                <FloatingLabelInputField
                                    id="createPinField"
                                    type="password"
                                    name="pinField"
                                    placeholder="Créer un code confidentiel"
                                    value={pinAuthValue}
                                    onChange={handlePinAuthInputChange}
                                    label="Créer un code confidentiel"
                                    disabled={isActionsDisabled}
                                />
                            </div>
                            <div className="sm:col-span-3">
                                <FloatingLabelInputField
                                    id="createPinConfirmationField"
                                    type="password"
                                    name="pinConfirmationField"
                                    placeholder="Confirmer le code confidentiel"
                                    value={pinAuthConfirmationValue}
                                    onChange={handlePinAuthConfirmationInputChange}
                                    label="Confirmer le code confidentiel"
                                    disabled={isActionsDisabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    <button
                        type="button"
                        className="bg-[#12CBC4]/90 text-gray-100 p-4 w-full rounded-lg tracking-wide
                                    font-semibold font-display hover:bg-[#12CBC4] focus:outline-none focus:shadow-outline
                                    shadow-lg disabled:opacity-50 disabled:pointer-events-none"
                        disabled={isActionsDisabled || isFormNotReady}
                        onClick={registerUser}
                    >
                        {strings.create_account_button_label}
                    </button>
                </div>
            </form>
            <Modal
                open={modalOpen}
                title="Filtres"
                onClose={() => setModalOpen(false)} data-modal-backdrop="static">

                <div className="relative flex flex-col bg-white shadow-lg rounded-xl pointer-events-auto dark:bg-gray-800">
                    <div className="relative overflow-hidden min-h-[8rem] bg-[#12CBC4] text-center rounded-t-xl p-2 pt-4 pl-4 pr-4">
                        {/* <!-- Header --> */}
                        <div className="flex justify-between items-center">
                            <h3 className="flex items-center gap-x-2 font-bold text-white">
                                <HowToRegIcon fontSize="medium" />
                                Confirmation
                            </h3>
                        </div>
                        {/* <!-- End Header --> */}

                        {/* <!-- SVG Background Element --> */}
                        <figure className="absolute inset-x-0 bottom-0 -mb-px">
                            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                                <path fill="currentColor" className="fill-white dark:fill-gray-800" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
                            </svg>
                        </figure>
                        {/* <!-- End SVG Background Element --> */}
                    </div>

                    <div className="relative z-10 -mt-12">
                        {/* <!-- Icon --> */}
                        <span className="mx-auto flex justify-center items-center w-[62px] h-[62px] rounded-full border border-gray-200 bg-white text-[#12CBC4] shadow-sm">
                            <TaskIcon fontSize="large" />
                        </span>
                        {/* <!-- End Icon --> */}
                    </div>

                    {/* <!-- Body --> */}
                    <div className="p-4 sm:p-7 overflow-y-auto">
                        <div className="text-center">
                            <h3 className="text-base font-normal text-gray-800 dark:text-gray-200">
                                Félicitations ! Votre inscription a été réalisée avec succès.
                            </h3>
                        </div>

                        <div className="mt-2 sm:mt-5">
                            <p className="text-sm font-normal text-gray-500">Veuillez valider votre <u>inscription</u> en cliquant sur le lien que vous allez recevoir dans votre <b>boite mail</b>.</p>
                        </div>

                        {/* <!-- Button --> */}
                        <button
                            type="button"
                            className="mt-10 bg-[#12CBC4] text-gray-100 p-4 w-full rounded-lg tracking-wide
                                    font-semibold font-display focus:outline-none focus:shadow-outline
                                    shadow-lg disabled:opacity-50 disabled:pointer-events-none"
                            onClick={() => handleNavigationToSignin()}
                        >
                            <p className="text-white">
                                {strings.agree_label}
                            </p>
                        </button>
                        {/* <!-- End Buttons --> */}
                    </div>
                    {/* <!-- End Body --> */}
                </div>
            </Modal>
            {showToast && (
                <Toast type={toastType} message={toastMessage} />
            )}
        </div>
    )
}