// ApiService.ts

class ApiService {
    private apiUrl: string;

    /**
     * Constructor of the ApiService class.
     * @param apiUrl - The base URL of the API.
    */
    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }

    /**
     * Handles the HTTP response and returns JSON data if the response is successful.
     * Otherwise, throws an error with the API's message.
     * @param response - The HTTP response to be processed.
     * @returns The JSON data from the response.
     * @throws An error if the response is not successful.
    */
    // private async handleResponse(response: Response) {
    //     if (!response.ok) {
    //         throw new Error(`Erreur de l'API: ${response.statusText}`);
    //     }

    //     return response.json();
    // }
    
    private async handleResponse(response: Response) {
        if (!response.ok) {
            const contentType = response.headers.get('Content-Type');
            if (contentType?.includes('application/json')) {
                const errorResponse = await response.json();
                throw new Error(errorResponse.message || `API Error: ${response.statusText}`);
            } else {
                throw new Error(`API Error: Received non-JSON response (status code ${response.status})`);
            }
        }
        return response.json();
    }

    /**
     * Performs a GET request to the API.
     * @param endpoint - The request's endpoint path.
     * @returns The JSON data from the response.
     * @throws An error in case the request fails.
    */
    // async get(endpoint: string) {
    //     const url = `${this.apiUrl}/${endpoint}`;

    //     try {
    //         const response = await fetch(url);
    //         return this.handleResponse(response);
    //     } catch (error) {
    //         console.error(`Erreur lors de la requête GET vers ${url}`, error);
    //         throw new Error(`Erreur lors de la requête GET vers ${url}`);
    //     }
    // }

    /**
 * Performs a GET request to the API, including an authorization header if a token is provided.
 * @param endpoint - The request's endpoint path.
 * @param token - Optional. The JWT token for authentication.
 * @returns The JSON data from the response.
 * @throws An error in case the request fails.
 */
    async get(endpoint: string, token?: string) {
        const url = `${this.apiUrl}/${endpoint}`;

        // Préparer les en-têtes de la requête. Inclure le token seulement s'il est fourni.
        const headers = new Headers({
            'Content-Type': 'application/json'
        });
        
        if (token) {
            headers.append('Authorization', `Bearer ${token}`);
        }

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: headers
            });
            return this.handleResponse(response);
        } catch (error) {
            console.error(`Erreur lors de la requête GET vers ${url}`, error);
            throw new Error(`Erreur lors de la requête GET vers ${url}`);
        }
    }

    /**
     * Performs a POST request to the API.
     * @param endpoint - The request's endpoint path.
     * @param data - The data to include in the request body.
     * @returns The JSON data from the response.
     * @throws An error in case the request fails.
    */
    async post(endpoint: string, data: any, token?: string) {
        const url = `${this.apiUrl}/${endpoint}`;

         // Préparer les en-têtes de la requête. Inclure le token seulement s'il est fourni.
         const headers = new Headers({
            'Content-Type': 'application/json'
        });
        
        if (token) {
            headers.append('Authorization', `Bearer ${token}`);
        }

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data),
            });

            return this.handleResponse(response);
        } catch (error) {
            console.error(`Erreur lors de la requête POST vers ${url}`, error);
            throw new Error(`Erreur lors de la requête POST vers ${url}`);
        }
    }

    /**
     * Performs a PUT request to the API.
     * @param endpoint - The request's endpoint path.
     * @param data - The data to include in the request body.
     * @returns The JSON data from the response.
     * @throws An error in case the request fails.
    */
    async put(endpoint: string, data: any) {
        const url = `${this.apiUrl}/${endpoint}`;

        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            return this.handleResponse(response);
        } catch (error) {
            console.error(`Erreur lors de la requête PUT vers ${url}`, error);
            throw new Error(`Erreur lors de la requête PUT vers ${url}`);
        }
    }

    /**
     * Performs a DELETE request to the API.
     * @param endpoint - The request's endpoint path.
     * @param data - The data to include in the request body.
     * @returns The JSON data from the response.
     * @throws An error in case the request fails.
    */
    async delete(endpoint: string) {
        const url = `${this.apiUrl}/${endpoint}`;

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return this.handleResponse(response);
        } catch (error) {
            console.error(`Erreur lors de la requête DELETE vers ${url}`, error);
            throw new Error(`Erreur lors de la requête DELETE vers ${url}`);
        }
    }
}

export default ApiService;