import React, { useState, useEffect } from 'react';

interface FloatingDateInputProps {
  id: string;
  name: string;
  type: string;
  placeholder: string;
  label: string;
  initialValue: string;
  isDisabled: boolean;
  onValueChange: (value: string) => void;
}

const FloatingDateInput: React.FC<FloatingDateInputProps> = ({ id, name, type, placeholder, label, initialValue, isDisabled, onValueChange }: FloatingDateInputProps) => {

  const [inputValue, setInputValue] = useState<string>(initialValue);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    onValueChange(newValue);
  };

  useEffect(() => {

    const inputEmail = document.getElementById(id) as HTMLInputElement;

    const handleInput = () => {
      const hasValue = inputEmail.value.length > 0;
    };

    inputEmail.addEventListener('input', handleInput);

    return () => {
      inputEmail.removeEventListener('input', handleInput);
    };
  }, []);

  return (
    <div className="relative bg-white rounded w-full pl-4 pr-4">
      <div>
        <p className="text-start mt-2 text-xs text-gray-500">
          {label}
        </p>
      </div>
      <input
        type="date"
        id={id}
        name={name}
        className="appearance-none w-full text-start bg-white rounded text-sm"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        disabled={isDisabled}
      />
    </div>
  );
};

export default FloatingDateInput;