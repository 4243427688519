import React from 'react';

interface DateInputProps {
    id: string;
    name: string;
    label: string;
    placeholder?: string;
    inputValue: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

const GenericDateInputField: React.FC<DateInputProps> = ({
    id,
    name,
    label,
    placeholder,
    inputValue,
    onChange,
    disabled = false
}) => {
    return (
        <div className="relative rounded-lg w-full pl-4 pr-4">
            <div>
                <p className="text-start mt-2 text-xs text-gray-500">
                    {label}
                </p>
            </div>
            <input
                type="date"
                id={id}
                name={name}
                className="appearance-none w-full text-start rounded-lg text-sm disabled:opacity-90 disabled:pointer-events-none"
                placeholder={placeholder}
                value={inputValue}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    );
};

export default GenericDateInputField;
