// AppointementModel.ts

import ApiService from '../../services/ApiService';
import { AppointementResponse } from '../../types/AppointementResponse';
import { GeneralAppointementResponse } from '../../types/GeneralAppointementResponse';
import { DocRequestData } from '../../types/docRequestModel';

class AppointementModel {
    private appointementAskRequest: DocRequestData | null = null;
    private apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    async fetchAppointement(id: any, token: any): Promise<AppointementResponse> {
        try {
            console.log("Load appointement request...")
            const appointement = await this.apiService.get(`appointment/${id}`, token);
            return appointement;
        } catch (error) {
            console.error('Error loading appointement', error);
            throw new Error('Error loading appointement');
        }
    }

    async AssignAppointementsRequests(dataToSend: any): Promise<GeneralAppointementResponse> {
        try {
            const appointementsRequests = await this.apiService.post(`appointment/assignrequest`, dataToSend);
            return appointementsRequests;
        } catch (error) {
            console.error('Error loading appointements assign requests', error);
            throw new Error('Error loading appointements assign requests');
        }
    }

    // getUserProfile(): UserProfile | null {
    //     return this.userProfile;
    // }

    /**
     * Updates the Appointement Request data locally and sends the update to the backend API.
     * @param updatedData - The updated data for the Appointement.
     */
    async updateAppointementsRequest(appointementId: string, updatedData: any): Promise<GeneralAppointementResponse> {
        try {
            // Update the local UserProfileModel
            // if (this.userProfile) {
            //     Object.assign(this.userProfile, updatedData);
            // }
            // Update the backend API
            // `https://api.doctraitant.fr/api//${data._id}`
            const response = await this.apiService.put(`appointment/update-appointment-request/${appointementId}`, updatedData);
            return response;
        } catch (error) {
            console.error('Error updating user profile', error);
            throw new Error('Error updating user profile');
        }
    }

    /**
     * Delete the Appointement Request data locally and sends the update to the backend API.
     * @param updatedData - The deleted data for the Appointement.
     */
    async deleteAppointementsRequest(appointementId: string): Promise<GeneralAppointementResponse> {
        try {
            // Delete the backend API
            const response = await this.apiService.delete(`appointment/delete-appointment-request/${appointementId}`);
            return response;
        } catch (error) {
            console.error('Error when trying to delete appointement', error);
            throw new Error('Error when trying to delete appointement');
        }
    }
}

export default AppointementModel;