// UserProfileModel.ts

import ApiService from '../../services/ApiService';
import { GeneralAppointementResponse } from '../../types/GeneralAppointementResponse';
import { DocRequestData } from '../../types/docRequestModel';

class AuthenticationModel {
    private appointementAskRequest: DocRequestData | null = null;
    private apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    // return httpClient.post("https://api.doctraitant.fr/api/myaccountrecovery/forgotpassword"){
        
    async forgotPasswordsRequest(updatedData: any): Promise<any> {
        try {
            const data = await this.apiService.post(`myaccountrecovery/forgotpassword `, updatedData);
            return data;
        } catch (error) {
            console.error('Error recover password', error);
            throw new Error('Error recover password');
        }
    }

    /**
     * Updates the Appointement Request data locally and sends the update to the backend API.
     * @param updatedData - The updated data for the Appointement.
     */

    // email, otp
    async otpCheckerRequest(updatedData: any): Promise<any> {
        try {
            // Update the local UserProfileModel
            // if (this.userProfile) {
            //     Object.assign(this.userProfile, updatedData);
            // }
            // Update the backend API
            // `https://api.doctraitant.fr/api//${data._id}`
            const response = await this.apiService.post(`myaccountrecovery/checker`, updatedData);
            return response;
        } catch (error) {
            console.error('Error validating otp', error);
            throw new Error('Error validating otp');
        }
    }

    /**
     * Updates the Appointement Request data locally and sends the update to the backend API.
     * @param updatedData - The updated data for the Appointement.
     */

    // email, otp
    async resetPasswordRequest(updatedData: any): Promise<any> {
        try {
            // Update the local UserProfileModel
            // if (this.userProfile) {
            //     Object.assign(this.userProfile, updatedData);
            // }
            // Update the backend API
            // `https://api.doctraitant.fr/api//${data._id}`
            const response = await this.apiService.post(`myaccountrecovery/forgotpassword/reset`, updatedData);
            return response;
        } catch (error) {
            console.error('Error when trying to reset user password', error);
            throw new Error('Error when trying to reset user password');
        }
    }
}

export default AuthenticationModel;