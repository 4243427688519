import { Link, Route, Routes, useNavigate, Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper";
import { nav } from "./navigation";
import { NotFound } from "../pages/NotFound"
import { RequestTrackView } from "../pages/RequestTrackView"
export const RenderRoutes = () => {
     const location = useLocation();
     const { user, logout } = AuthData();

     const ProtectedRoute = () => {
          return localStorage.getItem("token") == null ? (
               <Navigate replace to="/connectme" />
          ) : (
               <Outlet />
          );
     };

     return (
          <Routes>
               {/* Redirections si l'utilisateur est déjà connecté */}
               {localStorage.getItem("token") != null && <Route path="/signin" element={<Navigate replace to="/mydashboard" />} />}
               {localStorage.getItem("token") != null && <Route path="/register" element={<Navigate replace to="/mydashboard" />} />}
               {localStorage.getItem("token") != null && <Route path="/forgotPassword" element={<Navigate replace to="/mydashboard" />} />}

               {/* Redirection vers /connectme si pas de token et accès à /doctor-request */}
               {/* <Route
                    path="/myrequest"
                    element={
                         localStorage.getItem("token") == null ? (
                              <Navigate replace to="/connectme" />
                         ) : (
                              <Navigate replace to="/myrequest" />
                         )
                    }
               /> */}


               <Route element={<ProtectedRoute />}>
                    <Route path="/myrequest" element={<RequestTrackView />} />
               </Route>

               {/* Si l'utilisateur est connecté, redirection entre connectme et doctor-request */}
               {localStorage.getItem("token") != null && <Route path="/connectme" element={<Navigate replace to="/mydashboard" />} />}

               {/* Gestion des routes privées et publiques */}
               {nav.map((r, i) => (
                    <Route
                         key={i}
                         path={r.path}
                         element={
                              r.isPrivate ? (
                                   localStorage.getItem("token") != null ? (
                                        r.element
                                   ) : (
                                        <Navigate replace to="/signin" />
                                   )
                              ) : (
                                   r.element
                              )
                         }
                    />
               ))}

               {/* Si aucune route ne correspond, redirection vers NotFound */}
               <Route path="*" element={<NotFound />} />
          </Routes>
     );
};


export const RenderMenu = () => {

     const { user, logout } = AuthData()

     const MenuItem = ({ r }) => {
          return (
               <div className="menuItem"><Link to={r.path}>{r.name}</Link></div>
          )
     }
     return (
          <div className="menu">
               {nav.map((r, i) => {

                    if (!r.isPrivate && r.isMenu) {
                         return (
                              <MenuItem key={i} r={r} />
                         )
                    } else if (user.isAuthenticated && r.isMenu) {
                         return (
                              <MenuItem key={i} r={r} />
                         )
                    } else return false
               })}

               {user.isAuthenticated ?
                    <div className="menuItem"><Link to={'#'} onClick={logout}>Log out</Link></div>
                    :
                    <div className="menuItem"><Link to={'login'}>Log in</Link></div>}
          </div>
     )
}