import React, { useState, useEffect } from 'react';
import { BrowserRouter, useNavigate } from "react-router-dom";
import { AccountManageEnum } from '../../types/accountManageEnum';
import { AuthData } from "../../auth/AuthWrapper"
import strings from '../../assets/strings';
import FloatingLabelInput from "../layouts/FloatingLabelInput"
import UserProfileViewModel from './UserProfileViewModel';
import Toast from '../layouts/Toast';
// Icons imports
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import { arePinFieldsValid, isLettersNotEmpty, isValidEmail } from '../../utils/DataChecker';
import Modal from '../layouts/Modal';
import LayoutHeader from '../Headers/LayoutHeader';

interface UserProfileViewProps {
    userId: number;
    userProfileViewModel: UserProfileViewModel;
}

const UserProfileView: React.FC<UserProfileViewProps> = ({ userId, userProfileViewModel }) => {
    const [activeItem, setActiveItem] = useState<string | null>(null);
    const [isActionsDisabled, setIsActionsDisabled] = useState(false);
    const [isPersonalDataFormNotReady, setIsPersonalDataFormNotReady] = useState(true);
    const [isProDataFormNotReady, setIsProDataFormNotReady] = useState(true);
    const [isPasswordFormNotReady, setIsPasswordFormNotReady] = useState(true);
    const [firstnameValue, setFirstnameValue] = useState<string>(localStorage.getItem("firstname") ?? "N/A");
    const toggleFirstname = (value: string) => {
        setFirstnameValue(value);
        localStorage.setItem("firstname", value);
    };
    const [userPermissions, setUserPermissions] = useState<string | null>(localStorage.getItem("userRights"));
    const [lastnameValue, setLastnameValue] = useState<string>(localStorage.getItem("lastname") ?? "N/A");
    const toggleLastname = (value: string) => {
        setLastnameValue(value);
        localStorage.setItem("lastname", value);
    };
    const [emailValue, setEmailValue] = useState<string>(localStorage.getItem("email") ?? "N/A");
    const toggleEmail = (value: string) => {
        setEmailValue(value);
        localStorage.setItem("email", value);
    };
    const [phoneValue, setPhoneValue] = useState<string>(localStorage.getItem("phone") ?? "N/A");
    const togglePhone = (value: string) => {
        setPhoneValue(value);
        localStorage.setItem("phone", value);
    };
    const [adresseValue, setAdresseValue] = useState<string>(localStorage.getItem("address") ?? "N/A");
    const toggleAddress = (value: string) => {
        setAdresseValue(value);
        localStorage.setItem("address", value);
    };
    const [cityValue, setCityValue] = useState<string>(localStorage.getItem("city") ?? "N/A");
    const toggleCity = (value: string) => {
        setCityValue(value);
        localStorage.setItem("city", value);
    };
    const [isNotificationsChecked, setNotificationsCheck] = useState<boolean>(
        () => {
            const storedValue = localStorage.getItem("isNotificationsEnabled");
            return storedValue === "true";
        }
    );
    const toggleNotifications = (value: boolean) => {
        setNotificationsCheck(value);
        localStorage.setItem("isNotificationsEnabled", String(value));
        console.log("Notifications : " + localStorage.setItem("isNotificationsEnabled", String(value)))
    };
    const [isAssignmentsChecked, setAssignmentsCheck] = useState<boolean>(
        () => {
            const storedValue = localStorage.getItem("supportsAssignments");
            return storedValue === "true";
        }
    );
    const toggleSupportsAssignments = (value: boolean) => {
        setAssignmentsCheck(value);
        localStorage.setItem("supportsAssignments", String(value));
        console.log("Assignment : " + localStorage.setItem("supportsAssignments", String(value)))
    };
    const [communeValue, setCommuneValue] = useState<string>(localStorage.getItem("township") ?? "N/A");
    const toggleTownship = (value: string) => {
        setCommuneValue(value);
        localStorage.setItem("township", value);
    };
    const [zipcodeValue, setZipcodeValue] = useState<string>(localStorage.getItem("zipcode") ?? "N/A");
    const toggleZipcode = (value: string) => {
        setZipcodeValue(value);
        localStorage.setItem("zipcode", value);
    };
    const [pinAuthValue, setPinAuthValue] = useState<string>('');
    const [pinAuthConfirmationValue, setPinAuthConfirmationValue] = useState<string>('');

    const navigate = useNavigate();

    // Asking for actions modal
    const [confirmationModalOpened, setConfirmationModalOpened] = useState(false);

    // Toast
    const [toastType, setToastType] = useState<string>('');
    const [toastMessage, setToastMessage] = useState<string>('');
    const [showToast, setShowToast] = useState(false);
    const handleShowToast = (type: 'success' | 'warning' | 'error', message: string) => {
        setToastType(type);
        setToastMessage(message);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
            setToastType('');
            setToastMessage('');
        }, 2000);
    };

    const handleFirstnameChange = (value: string) => {
        setFirstnameValue(value);
    };

    const handleLastnameChange = (value: string) => {
        setLastnameValue(value);
    };

    const handleEmailChange = (value: string) => {
        setEmailValue(value);
    };

    const handlePhoneChange = (value: string) => {
        setPhoneValue(value);
    };

    const handleAdresseChange = (value: string) => {
        setAdresseValue(value);
    };

    const handleCityChange = (value: string) => {
        setCityValue(value);
    };

    const handleCommuneChange = (value: string) => {
        setCommuneValue(value);
    };

    const handleZipcodeChange = (value: string) => {
        setZipcodeValue(value);
    };

    const handlePinAuthChange = (value: string) => {
        setPinAuthValue(value);
    };

    const handlePinAuthConfirmationChange = (value: string) => {
        setPinAuthConfirmationValue(value);
    };

    // useEffect(() => {
    //     userProfileViewModel.loadUserProfile().then(() => {
    //         // const userProfile = userProfileViewModel.userProfileModel.getUserProfile();
    //         // if (userProfile) {
    //         //     setEmailValue(userProfile.email);
    //         //     setPhoneValue(userProfile.phone);
    //         // }
    //     });
    // }, [userId, userProfileViewModel]);

    useEffect(() => {
        document.title = strings.account_title_tab;
    }, []);

    useEffect(() => {
        if (!isLettersNotEmpty(firstnameValue) || !isLettersNotEmpty(lastnameValue)) {
            setIsPersonalDataFormNotReady(true);
        } else {
            setIsPersonalDataFormNotReady(false);
        }

        if (!cityValue || !communeValue || !adresseValue || !phoneValue || !zipcodeValue || !emailValue || !isValidEmail(emailValue ?? "")) {
            setIsProDataFormNotReady(true);
        } else {
            setIsProDataFormNotReady(false);
        }

        if (!arePinFieldsValid(pinAuthValue, pinAuthConfirmationValue) || !pinAuthValue || !pinAuthConfirmationValue) {
            setIsPasswordFormNotReady(true);
        } else {
            setIsPasswordFormNotReady(false);
        }
    }, [firstnameValue, lastnameValue, phoneValue, emailValue, adresseValue, cityValue, communeValue, zipcodeValue, pinAuthValue, pinAuthConfirmationValue])

    const handleUpdateProfile = async (updatedData: any) => {
        setIsActionsDisabled(true);
        try {
            await userProfileViewModel.updateUserProfile(updatedData);
            toggleFirstname(firstnameValue);
            toggleLastname(lastnameValue);
            toggleEmail(emailValue);
            togglePhone(phoneValue);
            toggleAddress(adresseValue);
            toggleTownship(communeValue);
            toggleCity(cityValue);
            toggleZipcode(zipcodeValue);
            setIsActionsDisabled(false);
            handleShowToast("success", "Profil utilisateur mis à jour avec succès");
        } catch (error) {
            console.error('Error updating user profile in view', error);
            setIsActionsDisabled(false);
            handleShowToast("error", "Erreur lors de la mise à jour du profil utilisateur");
        }
    };

    const handleUpdatePassword = async (updatedData: any) => {
        setIsActionsDisabled(true);
        try {
            await userProfileViewModel.updateUserPassword(updatedData);
            setIsActionsDisabled(false);
            handleShowToast("success", "Profil utilisateur mis à jour avec succès");
        } catch (error) {
            console.error('Error updating user profile in view', error);
            setIsActionsDisabled(false);
            handleShowToast("error", "Erreur lors de la mise à jour du profil utilisateur");
        }
    };

    const handleUpdateNotificationSubscription = async (isNotificationsEnabled: any) => {
        setIsActionsDisabled(true);
        try {
            await userProfileViewModel.updateNotificationSubscription({ isNotificationsEnabled: isNotificationsEnabled });
            toggleNotifications(isNotificationsEnabled);
            setIsActionsDisabled(false);
            handleShowToast("success", "Profil utilisateur mis à jour avec succès");
        } catch (error) {
            console.error('Error updating user profile in view', error);
            setIsActionsDisabled(false);
            handleShowToast("error", "Erreur lors de la mise à jour du profil utilisateur");
        }
    };

    const handleUpdateSupportsAssignments = async (supportsAssignments: any) => {
        setIsActionsDisabled(true);
        try {
            await userProfileViewModel.updateSupportsAssignments({ supportsAssignments: supportsAssignments });
            toggleSupportsAssignments(supportsAssignments);
            setIsActionsDisabled(false);
            handleShowToast("success", "Profil utilisateur mis à jour avec succès");
        } catch (error) {
            console.error('Error updating user profile in view', error);
            setIsActionsDisabled(false);
            handleShowToast("error", "Erreur lors de la mise à jour du profil utilisateur");
        }
    };

    const handleUpdatePersonalData = (updatedData: any) => {
        handleUpdateProfile(updatedData)
    };

    const handleUpdateSecurityData = (updatedData: any) => {
        handleUpdatePassword(updatedData)
    };

    const handleDeleteUser = async () => {
        setIsActionsDisabled(true);
        try {
            const response = await userProfileViewModel.deleteUserProfile(localStorage.getItem("userId") ?? "");
            localStorage.clear();
            setIsActionsDisabled(false);
            if (userPermissions?.includes("Patient")) {
                navigate("/connectme")
            } else {
                navigate("/signin")
            }
        } catch (error) {
            console.error('Error updating user profile in view', error);
            setIsActionsDisabled(false);
            handleShowToast("error", "Erreur lors de la mise à jour du profil utilisateur");
        }
    };

    const handleUpdateProData = (updatedData: any) => {
        handleUpdateProfile(updatedData)
    };

    const handleUserProfileSync = () => {
        handleShowToast("success", "Profil utilisateur mis à jour avec succès");
    };

    function enableAccordion(item: AccountManageEnum) {
        if (item === AccountManageEnum.UpdatePersoInfos) {
            setActiveItem('UpdatePersoInfos');
        } else if (item === AccountManageEnum.UpdateProInfos) {
            setActiveItem('UpdateProInfos');
        } else if (item === AccountManageEnum.UpadatePin) {
            setActiveItem('UpadatePin');
        } else if (item === AccountManageEnum.ManageSettings) {
            setActiveItem('ManageSettings');
        } else {
            setActiveItem(null);
        }
    };

    const handleNotificationsCheckboxChange = () => {
        handleUpdateNotificationSubscription(!isNotificationsChecked);
    }

    const handleAssignmentsCheckboxChange = () => {
        handleUpdateSupportsAssignments(!isAssignmentsChecked);
    }

    return (
        <header>
            <LayoutHeader
                icon={
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 text-[#22a6b3]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                }
                title="Compte et sécurité"
                subtitle="Consulter et ajuster vos paramètres"
            />

            <div className="flex flex-col space-y-2 w-full">
                <div className="md:col-span-3">
                    <div className="hs-accordion-group divide-y divide-gray-200 dark:divide-gray-700">
                        <div className="hs-accordion pb-3">
                            <div className="w-full rounded-md pt-4">
                                <div className="mt-1 text-gray-600 font-normal flex text-left text-xs justify-end items-center">
                                    <div className="inline-flex gap-x-2">
                                        <span onClick={handleUserProfileSync} className="py-2 px-3 inline-flex items-center text-sm font-normal rounded-xl border border-gray-200 bg-white text-sky-700 cursor-pointer shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none">
                                            <svg fill="#0369a1" className="w-4 h-4 mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M3.38 8A9.502 9.502 0 0112 2.5a9.502 9.502 0 019.215 7.182.75.75 0 101.456-.364C21.473 4.539 17.15 1 12 1a10.995 10.995 0 00-9.5 5.452V4.75a.75.75 0 00-1.5 0V8.5a1 1 0 001 1h3.75a.75.75 0 000-1.5H3.38zm-.595 6.318a.75.75 0 00-1.455.364C2.527 19.461 6.85 23 12 23c4.052 0 7.592-2.191 9.5-5.451v1.701a.75.75 0 001.5 0V15.5a1 1 0 00-1-1h-3.75a.75.75 0 000 1.5h2.37A9.502 9.502 0 0112 21.5c-4.446 0-8.181-3.055-9.215-7.182z"></path></g></svg>
                                            {strings.synchronize_label}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full rounded-md pt-4">
                                <div className="mt-1 text-gray-600 font-normal flex text-left text-xs justify-between items-center">
                                    <h3 className="flex items-center gap-x-2 font-medium text-base text-gray-600/90">
                                        Mettre à jour mes informations personnelles
                                    </h3>
                                    <div className="inline-flex gap-x-2">
                                        <p onClick={() => enableAccordion(AccountManageEnum.UpdatePersoInfos)} className="py-2 px-2 inline-flex items-center text-sm font-medium text-sky-700 cursor-pointer">
                                            Modifier
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={`hs-accordion-content w-full overflow-hidden transition-all ${activeItem === 'UpdatePersoInfos' ? 'max-h-[500px] duration-500' : 'max-h-[0px] duration-500'}`}>
                                <p className="mt-4 text-gray-600 dark:text-gray-400 text-sm font-light text-start">
                                    Procédez à la mise à jour de vos informations personnelles en quelques clics dans cette section.
                                </p>
                                <form>
                                    <div className="mt-5 ml-1 mr-1 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <FloatingLabelInput id="firstname" name="firstname" type="text" placeholder="Prénom" label="Prénom" onValueChange={handleFirstnameChange} isDisabled={isActionsDisabled} initialValue={firstnameValue ?? "N/A"} />
                                        </div>
                                        <div className="sm:col-span-3">
                                            <FloatingLabelInput id="lastname" name="lastname" type="text" placeholder="Nom de famille" label="Nom de famille" onValueChange={handleLastnameChange} isDisabled={isActionsDisabled} initialValue={lastnameValue ?? "N/A"} />
                                        </div>
                                    </div>
                                </form>
                                {/* <!-- Button --> */}
                                <div className="mt-6 mb-4 flex justify-end items-center gap-x-2">
                                    {isActionsDisabled && (
                                        <div className="animate-spin inline-block w-9 h-9 border-[5px] border-current border-t-transparent text-[#12CBC4] rounded-full mr-2" role="status" aria-label="loading">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    )}
                                    <p onClick={() => handleUpdatePersonalData({ firstname: firstnameValue, lastname: lastnameValue })} className={`py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg bg-[#12CBC4] border border-transparent text-white cursor-pointer ${isActionsDisabled || isPersonalDataFormNotReady ? 'opacity-50 pointer-events-none' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg>
                                        Mettre à jour
                                    </p>
                                </div>
                                {/* <!-- End Buttons --> */}
                            </div>
                        </div>
                        <div className="hs-accordion pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-three">
                            <div className="w-full rounded-md pt-4">
                                <div className="mt-1 text-gray-600 font-normal flex text-left text-xs justify-between items-center">
                                    <h3 className="flex items-center gap-x-2 font-medium text-base text-gray-600/90">
                                        Mettre à jour mes coordonnées
                                    </h3>
                                    <div className="inline-flex gap-x-2">
                                        <p onClick={() => enableAccordion(AccountManageEnum.UpdateProInfos)} className="py-2 px-2 inline-flex items-center text-sm font-medium text-sky-700 cursor-pointer">
                                            Modifier
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={`hs-accordion-content w-full transition-all overflow-auto ${activeItem === 'UpdateProInfos' ? 'max-h-[500px] duration-500' : 'max-h-[0px] duration-500'}`}>
                                <p className="mt-4 text-gray-600 dark:text-gray-400 text-sm font-light text-start">
                                    Actualisez vos coordonnées en toute simplicité dans cette section.
                                </p>
                                <form>
                                    <div className="mt-5 ml-1 mr-1 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <FloatingLabelInput id="email" name="email" type="email" placeholder="Adresse email professionnelle" label="Adresse email professionnelle" onValueChange={handleEmailChange} isDisabled={isActionsDisabled} initialValue={emailValue ?? "N/A"} />
                                        </div>
                                        <div className="sm:col-span-3">
                                            <FloatingLabelInput id="tel" name="tel" type="text" placeholder="N° de téléphone professionnel" label="N° de téléphone professionnel" onValueChange={handlePhoneChange} isDisabled={isActionsDisabled} initialValue={phoneValue ?? "N/A"} />
                                        </div>
                                        {userPermissions && !userPermissions.includes("Patient") && (
                                            <div className="col-span-full">
                                                <FloatingLabelInput id="adresse" name="adresse" type="text" placeholder="Adresse" label="Adresse" onValueChange={handleAdresseChange} isDisabled={isActionsDisabled} initialValue={adresseValue ?? "N/A"} />
                                            </div>
                                        )}
                                        <div className="sm:col-span-2 sm:col-start-1">
                                            <FloatingLabelInput id="ville" name="ville" type="text" placeholder="Ville" label="Ville" onValueChange={handleCityChange} isDisabled={isActionsDisabled} initialValue={cityValue ?? "N/A"} />
                                        </div>
                                        {userPermissions && !userPermissions.includes("Patient") && (
                                            <div className="sm:col-span-2">
                                                <FloatingLabelInput id="commune" name="commune" type="text" placeholder="Commune" label="Commune" onValueChange={handleCommuneChange} isDisabled={isActionsDisabled} initialValue={communeValue ?? "N/A"} />
                                            </div>
                                        )}
                                        <div className="sm:col-span-2">
                                            <FloatingLabelInput id="cp" name="cp" type="text" placeholder="Code Postal" label="Code Postal" onValueChange={handleZipcodeChange} isDisabled={isActionsDisabled} initialValue={zipcodeValue ?? "N/A"} />
                                        </div>
                                    </div>
                                </form>
                                {/* <!-- Button --> */}
                                <div className="mt-6 mb-4 flex justify-end items-center gap-x-2">
                                    {isActionsDisabled && (
                                        <div className="animate-spin inline-block w-9 h-9 border-[5px] border-current border-t-transparent text-[#12CBC4] rounded-full mr-2" role="status" aria-label="loading">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    )}
                                    <p onClick={() => handleUpdateProData({ email: emailValue, phone: phoneValue, address: adresseValue, township: communeValue, city: cityValue, zipcode: zipcodeValue })} className={`py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg bg-[#12CBC4] border border-transparent text-white cursor-pointer ${isActionsDisabled || isProDataFormNotReady ? 'opacity-50 pointer-events-none' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg>
                                        Mettre à jour
                                    </p>
                                </div>
                                {/* <!-- End Buttons --> */}
                            </div>
                        </div>

                        <div className="hs-accordion pb-3">
                            <div className="w-full rounded-md pt-4">
                                <div className="mt-1 text-gray-600 font-normal flex text-left text-xs justify-between items-center">
                                    <h3 className="flex items-center gap-x-2 font-medium text-base text-gray-600/90">
                                        Modifier le mot de passe du compte
                                    </h3>
                                    <div className="inline-flex gap-x-2">
                                        <p onClick={() => enableAccordion(AccountManageEnum.UpadatePin)} className="py-2 px-2 inline-flex items-center text-sm font-medium text-sky-700 cursor-pointer">
                                            Changer
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={`hs-accordion-content w-full overflow-hidden transition-all ${activeItem === 'UpadatePin' ? 'max-h-[500px] duration-500' : 'max-h-[0px] duration-500'}`}>
                                <p className="mt-4 text-gray-600 dark:text-gray-400 text-sm font-light text-start">
                                    Rendez votre compte plus sécurisé en modifiant facilement votre mot de passe dans cette section.
                                </p>
                                <form>
                                    <div className="mt-5 ml-1 mr-1 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <FloatingLabelInput id="new-pin-update" name="new-pin-update" type="password" placeholder="Créer un code confidentiel" label="Créer un code confidentiel" onValueChange={handlePinAuthChange} isDisabled={isActionsDisabled} initialValue={pinAuthValue} />
                                        </div>
                                        <div className="sm:col-span-3">
                                            <FloatingLabelInput id="new-pin-confirm-update" name="new-pin-confirm-update" type="password" placeholder="Créer un code confidentiel" label="Confirmer le code confidentiel" onValueChange={handlePinAuthConfirmationChange} isDisabled={isActionsDisabled} initialValue={pinAuthConfirmationValue} />
                                        </div>
                                    </div>
                                </form>
                                {/* <!-- Button --> */}
                                <div className="mt-6 mb-4 flex justify-end items-center gap-x-2">
                                    {isActionsDisabled && (
                                        <div className="animate-spin inline-block w-9 h-9 border-[5px] border-current border-t-transparent text-[#12CBC4] rounded-full mr-2" role="status" aria-label="loading">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    )}
                                    <p onClick={() => handleUpdateSecurityData({ email: emailValue, newPassword: pinAuthValue })} className={`py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg bg-[#12CBC4] border border-transparent text-white cursor-pointer ${isActionsDisabled || isPasswordFormNotReady ? 'opacity-50 pointer-events-none' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg>
                                        Mettre à jour
                                    </p>
                                </div>
                                {/* <!-- End Buttons --> */}
                            </div>
                        </div>

                        {userPermissions && !userPermissions.includes("Patient") && (
                            <div className="hs-accordion pb-3">
                                <div className="w-full rounded-md pt-4">
                                    <div className="mt-1 text-gray-600 font-normal flex text-left text-xs justify-between items-center">
                                        <h3 className="flex items-center gap-x-2 font-medium text-base text-gray-600/90">
                                            Gérer les paramètres du compte
                                        </h3>
                                        <div className="inline-flex gap-x-2">
                                            <p onClick={() => enableAccordion(AccountManageEnum.ManageSettings)} className="py-2 px-2 inline-flex items-center text-sm font-medium text-sky-700 cursor-pointer">
                                                Gérer
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className={`hs-accordion-content w-full overflow-hidden transition-all ${activeItem === 'ManageSettings' ? 'max-h-[500px] duration-500' : 'max-h-[0px] duration-500'}`}>
                                    {/* <p className="mt-2 text-gray-600 dark:text-gray-400 text-sm text-start">
                                    Veuillez contacter votre administrateur pour obtenir de l'aide.
                                </p> */}
                                    <div className="w-full rounded-md pt-4">
                                        <div className="mt-1 text-gray-600 font-normal flex text-left text-xs justify-between items-center">
                                            <p className="mt-2 mr-1 text-gray-600 dark:text-gray-400 text-xs text-start">
                                                Notifications par e-mail pour rester informé(e)
                                            </p>
                                            <div className="inline-flex gap-x-Z">
                                                <label className='themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1'>
                                                    <input
                                                        type='checkbox'
                                                        className='sr-only'
                                                        checked={isNotificationsChecked}
                                                        onChange={handleNotificationsCheckboxChange}
                                                    />
                                                    <span
                                                        className={`flex items-center space-x-[6px] rounded-md py-2 px-[8px] text-xs font-medium ${!isNotificationsChecked ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'
                                                            }`}
                                                    >
                                                        <CloseRoundedIcon htmlColor="#a5b1c2" fontSize="small" className="mr-1" />
                                                        <span className="hidden sm:inline">Désactivées</span>
                                                    </span>
                                                    <span
                                                        className={`flex items-center space-x-[6px] rounded-md py-2 px-[8px] text-xs font-medium ${isNotificationsChecked ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'
                                                            }`}
                                                    >
                                                        <CheckRoundedIcon htmlColor="#a5b1c2" fontSize="small" className="mr-1" />
                                                        <span className="hidden sm:inline">Activées</span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full rounded-md pt-4">
                                        <div className="mt-1 text-gray-600 font-normal flex text-left text-xs justify-between items-center gap-x-Z">
                                            <p className="mt-2 mr-1 text-gray-600 dark:text-gray-400 text-xs text-start">
                                                Recevoir des propositions d'affectation
                                            </p>
                                            <div className="inline-flex gap-x-Z">
                                                <label className='themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1'>
                                                    <input
                                                        type='checkbox'
                                                        className='sr-only'
                                                        checked={isAssignmentsChecked}
                                                        onChange={handleAssignmentsCheckboxChange}
                                                    />
                                                    <span
                                                        className={`flex items-center space-x-[6px] rounded py-2 px-[8px] text-xs font-medium ${!isAssignmentsChecked ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'
                                                            }`}
                                                    >
                                                        <CloseRoundedIcon htmlColor="#a5b1c2" fontSize="small" className="mr-1" />
                                                        <span className="hidden sm:inline">Désactivées</span>
                                                    </span>
                                                    <span
                                                        className={`flex items-center space-x-[6px] rounded py-2 px-[8px] text-xs font-medium ${isAssignmentsChecked ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'
                                                            }`}
                                                    >
                                                        <CheckRoundedIcon htmlColor="#a5b1c2" fontSize="small" className="mr-1" />
                                                        <span className="hidden sm:inline">Activées</span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="hs-accordion pb-3">
                            <div className="w-full rounded-md pt-4">
                                <h3 className="text-sm font-medium text-gray-600/90 text-left flex items-center">
                                    <span>{strings.disable_user_account_label}</span>
                                </h3>
                                <div className="mt-1 text-gray-600 font-normal flex text-left text-xs justify-between items-center">
                                    <h3 className="flex items-center gap-x-2 font-light text-xs text-black">
                                        {strings.disable_user_account_description_label}
                                    </h3>
                                    <div className="inline-flex gap-x-2">
                                        <p onClick={() => setConfirmationModalOpened(true)} className="py-2 px-2 inline-flex items-center text-sm font-medium text-red-400 cursor-pointer">
                                            {strings.delete_label}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} title={<span className="text-blue-500">Critères avancés</span>} indications={<p className="mt-2 text-slate-500 text-sm">Utilisez les critères avancés pour affiner votre recherche et trouver les données qui correspondent exactement à vos besoins.</p>}> */}
                {/* <Loading modalOpen={loadingModalOpen} setModalOpen={setLoadingModalOpen} indications={<p className="text-slate-500 text-center text-xl">Chargement en cours...</p>}>
                </Loading> */}
                {showToast && (
                    <Toast type={toastType} message={toastMessage} />
                )}
                {/* Ask user for logout with modal */}
                <Modal
                    open={confirmationModalOpened}
                    title=""
                    onClose={() => setConfirmationModalOpened(false)}>
                    <div className="w-full shadow-lg rounded-xl bg-white p-2 pt-4 pl-4 pr-4">
                        <div className="flex justify-between items-center">
                            <h3 className="flex items-center gap-x-2 pr-6 font-bold text-[#12CBC4]">
                                <FolderDeleteIcon fontSize="medium" />
                                Suppression
                            </h3>
                            <button onClick={() => setConfirmationModalOpened(false)} type="button" className="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" data-hs-overlay="#hs-basic-modal">
                                <span className="sr-only">Close</span>
                                <CancelRoundedIcon htmlColor="#a5b1c2" fontSize="medium" />
                            </button>
                        </div>

                        <div className="bg-white grid grid-cols-1 gap-4 lg:col-span-3 ">
                            <div className="p-2">
                                <div className="relative">
                                    <div className="mx-auto mt-4 w-48">
                                        <p className="text-sm font-normal text-gray-700">
                                            Êtes-vous sûr(e) de vouloir supprimer votre compte ?
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 mb-4 flex justify-end gap-x-2">
                            <p onClick={() => setConfirmationModalOpened(false)} className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-lg border border-[#ff4757]/50 font-medium bg-white text-[#ff4757]/50 shadow-sm align-middle transition-all text-sm cursor-pointer">
                                <CloseRoundedIcon fontSize="medium" />
                                Annuler
                            </p>
                            <p onClick={() => handleDeleteUser()} className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg bg-[#ff4757] border border-transparent text-white disabled:opacity-50 disabled:pointer-events-none cursor-pointer">
                                <CheckRoundedIcon fontSize="medium" />
                                Confirmer
                            </p>
                        </div>
                    </div>
                </Modal>
            </div>
        </header>
    )
}

export default UserProfileView