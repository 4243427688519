import React, { useState, useEffect } from 'react';

interface FloatingLabelInputProps {
  id: string;
  name: string;
  type: string;
  placeholder: string;
  label: string;
  initialValue: string;
  isDisabled: boolean;
  onValueChange: (value: string) => void;
}

const FloatingLabelInput: React.FC<FloatingLabelInputProps> = ({ id, name, type, placeholder, label, initialValue, isDisabled, onValueChange }: FloatingLabelInputProps) => {
  
  const [inputValue, setInputValue] = useState<string>(initialValue);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    onValueChange(newValue);
  };

  useEffect(() => {
    const inputEmail = document.getElementById(id) as HTMLInputElement;

    const handleInput = () => {
      const hasValue = inputEmail.value.length > 0;
      inputEmail.classList.toggle('pt-6', hasValue);
      inputEmail.classList.toggle('pb-2', hasValue);
    };

    inputEmail.addEventListener('input', handleInput);

    return () => {
      inputEmail.removeEventListener('input', handleInput);
    };
  }, []);

  return (
    <div className="relative">
      <input
        type={type}
        id={id}
        name={name}
        className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
        focus:pt-6
        focus:pb-2
        [&:not(:placeholder-shown)]:pt-6
        [&:not(:placeholder-shown)]:pb-2
        autofill:pt-6
        autofill:pb-2"
        placeholder={placeholder}
        value={inputValue}
        onChange={() => handleInputChange}
        disabled={isDisabled}
      />
      <label
        htmlFor={id}
        className="text-gray-600 absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-50 peer-disabled:pointer-events-none
        peer-focus:text-xs
        peer-focus:-translate-y-1.5
        peer-focus:text-gray-500
        peer-[:not(:placeholder-shown)]:text-xs
        peer-[:not(:placeholder-shown)]:-translate-y-1.5
        peer-[:not(:placeholder-shown)]:text-gray-500"
      >
        {label}
      </label>
    </div>
  );
};

export default FloatingLabelInput;