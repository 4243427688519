import React, { useState } from 'react';

interface SeatCounterProps {
  label: string;
  value: number;
  onChange: (newValue: number) => void;
}

const SeatCounter: React.FC<SeatCounterProps> = ({ label, value, onChange }) => {
  const [count, setCount] = useState<number>(value);

  const increment = () => {
    const newCount = count + 1;
    setCount(newCount);
    onChange(newCount);
  };

  const decrement = () => {
    if (count > 0) {
      const newCount = count - 1;
      setCount(newCount);
      onChange(newCount);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newCount = parseInt(value, 10);

    // Vérifier si la valeur convertie est un nombre
    if (!isNaN(newCount)) {
      setCount(newCount);
      onChange(newCount); // Appeler la fonction de rappel pour informer le composant parent
    } else if (value === '') {
      // Optionnellement gérer le cas où l'input est vide
      setCount(0);
      onChange(0);
    }
  };


  return (
    <div className="py-2 px-3 bg-white border border-gray-200 rounded-lg">
      <div className="w-full flex justify-between items-center gap-x-2">
        <div className="grow">
          <span className="block text-xs text-gray-500 text-start text-left">
            {label}
          </span>
          <input
            className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
            type="text"
            value={count}
            onChange={handleInputChange}
            data-hs-input-number-input=""
          />
        </div>
        <div className="flex justify-end items-center gap-x-1.5">
          <button onClick={decrement} type="button" className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
            <svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M5 12h14"></path>
            </svg>
          </button>
          <button onClick={increment} type="button" className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
            <svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5v14"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SeatCounter;
