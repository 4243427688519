import React, { useState, useEffect, useImperativeHandle, forwardRef, MutableRefObject } from 'react';

interface CustomPatientModalWithScrollProps {
  open: boolean;
  title: string;
  onClose: () => void;
  children: React.ReactNode;
  header: React.ReactNode;
  subHeader: React.ReactNode;
  informations: React.ReactNode;
  contact: React.ReactNode;
  history: React.ReactNode;
  actions: React.ReactNode;
  footer: React.ReactNode;
  initModal: boolean;
}

export interface CustomPatientModalWithScrollRef {
  resetActiveTab: () => void;
}

const CustomPatientModalWithScroll = forwardRef<CustomPatientModalWithScrollRef, CustomPatientModalWithScrollProps>(({
  open,
  title,
  onClose,
  children,
  header,
  subHeader,
  informations,
  contact,
  history,
  actions,
  footer,
  initModal
}, ref) => {
  
  const [activeTab, setActiveTab] = useState<string>('tab-1');

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  useImperativeHandle(ref, () => ({
    resetActiveTab: () => {
      setActiveTab('tab-1');
    }
  }));

  useEffect(() => {
    if (initModal) {
      setActiveTab("tab-1")
    }
  }, [initModal])

  useEffect(() => {
      setActiveTab("tab-1")
  }, [])

  return (
    <div
      className={`
      fixed inset-0 flex justify-center items-center transition-colors opacity-100 z-[60]
      ${open ? "visible bg-black/20" : "invisible"}
    `}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`
        w-screen p-2 transition-all
          ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
        `}
      >
        <div className="w-full bg-white overflow-y-auto shadow-sm rounded-xl">
          <div className="relative overflow-hidden h-[4rem] bg-[#12CBC4] border-b border-white text-center rounded-t-xl">
            {/* <!-- Header --> */}
            {header}
            {/* <!-- End Header --> */}
          </div>

          {/* {subHeader} */}

          <div className="bg-white grid grid-cols-1 gap-4 lg:col-span-3 ">
            <div className="p-2">
              <div className="relative">
                <div className="relative sm:overflow-hidden">
                  <div className="border-b border-gray-200">
                    
                      <React.Fragment key="reset-key">
                        <nav
                          className="pr-2 pl-2 flex space-x-6 overflow-x-auto"
                          aria-label="Tabs"
                        >
                          <button onClick={() => handleTabClick('tab-1')} type="button" className={`font-normal hs-tab-active:border-[#12CBC4] hs-tab-active:text-[#12CBC4] inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-[#12CBC4] focus:outline-none focus:text-[#12CBC4] disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-[#12CBC4] py-2 ${activeTab === 'tab-1' ? 'active' : ''
                            } `} id="tabs-with-icons-item-1" data-hs-tab="#tabs-with-icons-1" aria-controls="tabs-with-icons-1" role="tab">
                            Informations
                          </button>
                          <button onClick={() => handleTabClick('tab-2')} type="button" className={`font-normal hs-tab-active:border-[#12CBC4] hs-tab-active:text-[#12CBC4] inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-[#12CBC4] focus:outline-none focus:text-[#12CBC4] disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-[#12CBC4] py-2 ${activeTab === 'tab-2' ? 'active' : ''
                            } `} id="tabs-with-icons-item-2" data-hs-tab="#tabs-with-icons-2" aria-controls="tabs-with-icons-2" role="tab">
                            Coordonnées
                          </button>
                          <button onClick={() => handleTabClick('tab-3')} type="button" className={`font-normal hs-tab-active:border-[#12CBC4] hs-tab-active:text-[#12CBC4] inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-[#12CBC4] focus:outline-none focus:text-[#12CBC4] disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-[#12CBC4] py-2 ${activeTab === 'tab-3' ? 'active' : ''
                            } `} id="tabs-with-icons-item-3" data-hs-tab="#tabs-with-icons-3" aria-controls="tabs-with-icons-3" role="tab">
                            Historique
                          </button>
                          <button onClick={() => handleTabClick('tab-4')} type="button" className={`font-normal hs-tab-active:border-[#12CBC4] hs-tab-active:text-[#12CBC4] inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-[#12CBC4] focus:outline-none focus:text-[#12CBC4] disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-[#12CBC4] py-2 ${activeTab === 'tab-4' ? 'active' : ''
                            } `} id="tabs-with-icons-item-1" data-hs-tab="#tabs-with-icons-1" aria-controls="tabs-with-icons-1" role="tab">
                            Actions
                          </button>
                        </nav>
                      </React.Fragment>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="h-96 bg-white flex-auto overflow-y-auto relative pl-4 pr-4 pb-4">
            {activeTab === 'tab-1' && (
              informations
            )}
            {activeTab === 'tab-2' && (
              contact
            )}
            {activeTab === 'tab-3' && (
              history
            )}
            {activeTab === 'tab-4' && (
              actions
            )}
          </div>
          {footer}
        </div>
      </div>

    </div>
  )
})

export default CustomPatientModalWithScroll